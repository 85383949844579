<template>
  <f7-app :params="f7params" >

  <!-- Left panel with cover effect when hidden -->
  <f7-panel class="panelpegatop" left cover :visible-breakpoint="960">
    <f7-view>
      <f7-page class="backgoris">
        <f7-block class="avatar_panel text-align-center no-margin-vertical padding-vertical ">
          <img :src="$store.state.user.photoURL || '../static/img/avatar.jpg'" />
          <p class="no-margin-vertical bold text-color-white">{{$store.state.user.displayName || 'Set up your name'}}</p>
          <p class="no-margin-vertical text-color-primary">{{$store.state.user.email || 'No email'}}</p>
           <f7-badge v-if="dev" color="red">Development</f7-badge>
        </f7-block>
        <f7-list class="latmenu no-margin-top">
          <f7-list-item 
          v-for="menux in leMenu"
          :key="menux.urlx"
          link="#"
          :class="{active: menux.urlx==activeTab}"
          @click="gotoMenux(menux.urlx)"
          panel-close 
          :title="menux.title"
          :footer="menux.footer">
          <i slot="media" :class="`fas fa-lg fa-${menux.ico}`"></i>
          </f7-list-item>
        </f7-list>
        <f7-block class="version_latx no-margin-vertical">MaskCount {{$store.state.appVersion}}</f7-block>
        <f7-block class="no-margin-top"><f7-button @click="logmeout()" outline>{{$t('common.logout')}}</f7-button></f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Right panel with reveal effect-->
  <f7-panel right reveal theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Right Panel"></f7-navbar>
        <f7-block>Right panel content goes here</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/landing/"></f7-view>

<login class="loginz" :loginOpened="loginOpened" :dev="dev" :loading="loading"></login>
   <onboarding v-if="user.uid" :user="user"></onboarding>
  </f7-app>
</template>
<script>
  import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
  import cordovaApp from '../js/cordova-app.js';
  import routes from '../js/routes.js';
  import login from './login.vue';
  import onboarding from './onboarding.vue';
  import localforage from 'localforage';
  import { mapState } from 'vuex'
  export default {
    components:{
      login,
      onboarding
    },
    data() {
      return {
        // Framework7 Parameters $store.state.user.email || 
        f7params: {
          id: 'cl.hicapps.maskcount', // App bundle ID
          name: 'maskCount', // App name
          theme: 'md', // Automatic theme detection
          // App routes
          routes: routes,
          // Input settings
          input: {
            scrollIntoViewOnFocus: Device.cordova && !Device.electron,
            scrollIntoViewCentered: Device.cordova && !Device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
          },
        },
        loginOpened: true,
        loading: true,
      }
    },
    computed:{
      ...mapState(['activeTab','dev','user']),
      leMenu(){
        let menu =  [
          {
            urlx:'/profile/',
            title: this.$t('profile.navTitlex'),
            footer: this.$t('profile.subTitlex'),
            ico:'user-circle',
          },
 
          {
            urlx:'/',
            title:this.$t('common.neosession'),
            footer:this.$t('common.maskCountSub'),
            ico:'street-view',
          },
           {
            urlx:'/sessions/',
            title:this.$t('common.observations'),
            footer:this.$t('common.prevses'),
            ico:'list',
          },
           {
            urlx:'/aroundme/x/',
            title:this.$t('common.aroundme'),
            footer:this.$t('common.aroundmesub'),
            ico:'map-marked-alt',
          },
          {
            urlx:'/myteams/',
            title: this.$t('updt.myteams'),
            footer: this.$t('updt.myteamssub'),
            ico:'users',
          },
           {
            urlx:'/admin/',
            title:this.$t('common.admin'),
            footer:this.$t('common.adminsub'),
            ico:'users-cog',
            admin: true
          },
          {
            urlx:'/instructions/',
            title:this.$t('instructions.title'),
            footer:this.$t('instructions.sub'),
            ico:'book',
          },
          {
            urlx:'/about/',
            title: this.$t('common.about'),
            footer:this.$t('common.terms'),
            ico:'info-circle',
          }
        ]
        let elmenu = menu.filter(unmen=>{
          return !unmen.admin || (this.user.privx && this.user.privx.admin)
        });
        return elmenu;
      }
    },
    methods: {
      gotoMenux(urlx){
        this.$store.commit('setWhatTo',{what:'activeTab', to: urlx})
        this.$f7.views.main.router.navigate(urlx)
      },
      logmeout(){
         this.loading=false;
       return this.$firebase.auth().signOut().then(()=> {
          // Sign-out successful.
          console.log('Good bye')
        }).catch((error) => {
          // An error happened.
          console.log(error, 'error logging out')
          this.$f7.dialog.alert(error.message, error.code)
        });
      },
      wearUserandGo(user){
        let parts = location.hostname.split('.');
        let subdomain = parts.shift();
        let encheck = false;
        if(subdomain!='app'){
          if(subdomain=='localhost'){
            encheck='dev';
          }
          else{
             encheck=subdomain;
          }
        }
        //antes de cualquier cosa, ver si es un user de pass y no tiene email verificado
        let susproviders = user.providerData || [];
        if(!user.emailVerified && susproviders.length==1 && susproviders[0].providerId=='password'){
           return user.sendEmailVerification().then(()=> {
             this.$f7.dialog.close();
               this.$firebase.auth().signOut();
                // this.loginStep=1;
             return this.$f7.dialog.alert('Check your inbox at '+user.email+' to validate your account before being able to login.','Validate');
            }).catch((error)=> {
               this.$f7.dialog.close();
               console.log(error)
             //  this.loginStep=1;
              this.$f7.dialog.alert(error.message,error.code);
            });
        }

        //request data
       return this.$firebase.database().ref('privx/'+user.uid).once('value').then(snapshot=>{
          // User is signed in.
            let locluser = {
              displayName: user.displayName,
              email: user.email,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              phoneNumber: user.phoneNumber,
              isAnonymous: user.isAnonymous,
              uid: user.uid,
              providerData: user.providerData,
              privx: snapshot.val() || {}
            }
            console.log('user',user);
            //check if acces to beta or dev
            if(encheck){
              if(!locluser.privx[encheck]){
                 this.loading=false;
                 return this.$f7.dialog.alert('Your user is not authorized for this environment','Unauthorized',()=>{
                   console.log('post click')
                  if(!locluser.privx.iv){
                    console.log('checking')
                      //check invite info
                      return this.checkInviteslog(locluser.uid,true);
                    }
                    else{
                      console.log('going out')
                      return this.logmeout();
                    }
                })
              }
            }
            this.$store.commit('setObjTo',{what:'user',to:locluser});
            this.loginOpened = false;
              this.loading=false
            console.log('user',user);
            if(this.user.privx && this.user.privx.ua){
               localforage.getItem('backtoprofile').then((val)=>{
                  if(val){
                    this.gotoMenux('/profile/');
                  }
                  else{
                     this.gotoMenux('/');
                  }
                }).catch(error=>{
                  console.log('error getting back to profile');
                   this.gotoMenux('/');
                });
            }
            else if(!this.user.privx && !this.user.privx.iv){
              //check invite info
              this.checkInviteslog(this.user.uid);
            }
        }).catch(error=>{
          console.log(error);
          this.loading=false;
          this.$f7.dialog.alert(error.message, error.code)
        })
      },
      checkInviteslog(uid,andlogout=false){
         return localforage.getItem('iv').then((iv) => {
                if(iv){
                  console.log('tosave',iv,uid)
                  return this.$firebase.database().ref('privx/'+uid+'/iv').set(iv).then(()=>{
                    return localforage.setItem('iv', null);
                  }).then(()=>{
                    if(andlogout){
                      return this.logmeout();
                    }
                    else{
                      console.log('fin log iv')
                    }
                  }).catch(error=>{
                    if(andlogout){
                      return this.logmeout();
                    }
                    console.log('error setting invite log',error);
                  })
                }
                else{
                   if(andlogout){
                      return this.logmeout();
                    }
                }
              }).catch(error=>{
                console.log('error reading invite')
                if(andlogout){
                  return this.logmeout();
                }
              })
      },
      handleredirect(){
       return this.$firebase.auth().getRedirectResult().then((result)=> {
            console.log('redirect processed');
        }).catch((error)=> {
          console.log('error login',error)
          if (error.code === 'auth/account-exists-with-different-credential') {
          return this.$f7.dialog.alert('Your email has already been used with another auth provider. You will be redirected to that provider to link your accounts','email in use',()=>{
            this.$f7.dialog.preloader('Loading...');
            var pendingCred = error.credential;
            console.log(pendingCred);
           return localforage.setItem('pendingCred', error.credential).then(()=>{
              var email = error.email;
             return this.$firebase.auth().fetchSignInMethodsForEmail(email)
            }).then((methods)=> {
              console.log('methods', methods);
              let provider;
              if(methods[0]=='google.com'){
                provider = new this.$firebase.auth.GoogleAuthProvider();
                provider.setCustomParameters({
                  prompt: 'select_account'
                });
              }
              else if(methods[0]=='facebook.com'){
                provider = new this.$firebase.auth.FacebookAuthProvider();
              }
              else if(methods[0]=='twitter.com'){
                provider = new this.$firebase.auth.TwitterAuthProvider();
              }
              else if(methods[0]=='apple.com'){
                provider = new this.$firebase.auth.OAuthProvider('apple.com');
              }
              return  this.$firebase.auth().signInWithRedirect(provider);
            }).catch( (err) => {
              // we got an error //2020
              this.$f7.dialog.close();
               console.log(err);
              this.$f7.dialog.alert('Please contact support. Meanwhile, access using other auth provider','Error redirect',()=>{
                return this.logmeout();
              })
            });
          });
          }
          else{
            return this.$f7.dialog.alert(error.message, error.code);
          }
        });
      },
      mergeAccounter(user,pendingCred){
        console.log('pendingCred', pendingCred)
        let katoken, locusercred;
        if(pendingCred.signInMethod==='facebook.com'){
          katoken = this.$firebase.auth.FacebookAuthProvider.credential(pendingCred.accessToken);
        }
        else if(pendingCred.signInMethod==='google.com'){
          katoken = this.$firebase.auth.GoogleAuthProvider.credential(pendingCred.accessToken);
        }
        else if(pendingCred.signInMethod==='twitter.com'){
          katoken = this.$firebase.auth.TwitterAuthProvider.credential(pendingCred.accessToken);
        }
        else if(pendingCred.signInMethod==='apple.com'){
          katoken = this.$firebase.auth.OAuthProvider('apple.com').credential(pendingCred.accessToken);
        }
        return user.linkAndRetrieveDataWithCredential(katoken).then((usercred)=> {
          locusercred = usercred
          return localforage.setItem('pendingCred', null);
        }).then(()=>{
          this.wearUserandGo(locusercred);
        }).catch(err=>{
          this.loading=false
          console.log(err)
          this.$f7.dialog.alert('Please contact support. Meanwhile, access using other auth provider','Error merge',()=>{
                return this.logmeout();
              })
        })
      },
      checklocalex(){
        localforage.getItem('locale').then((lalocale) => {
          if(lalocale && lalocale!==this.$i18n.locale){
            this.$i18n.locale = lalocale
          }
        }).catch(error=>{
          console.log('error getting locale', error)
        })
      }
    },
    mounted() {
      this.$f7ready((f7) => {
        // Init cordova APIs (see cordova-app.js)
         if (this.$f7.device.cordova) {
          cordovaApp.init(f7);
        }
        this.checklocalex();
        // Call F7 APIs here
        this.$firebase.auth().onAuthStateChanged((user)=> {
            if (user) {
                localforage.getItem('pendingCred').then((pendingCred) => {
                  if(pendingCred){
                    this.mergeAccounter(user,pendingCred);
                  }
                  else{
                    this.wearUserandGo(user);
                  }
                }).catch( (err)=> {
                  // this.loading=false
                  // console.log(err, 'error reading localforage')
                  // this.$f7.dialog.alert('Please contact support. Meanwhile, access using other auth provider','Error localforage',()=>{
                  //   return this.logmeout();
                  // })
                   this.wearUserandGo(user);
                });
            } else {
              this.loginOpened = true;
              this.$store.commit('setObjTo',{what:'user',to:{}});
              console.log('no users logged in')
              //check if gotshared
              let url = new URL(window.location.href);
              let iv = url.searchParams.get('iv');
              if(iv){
                console.log('recibe invite');
                //set en localforage
                localforage.setItem('iv', iv);
                //eliminar
                window.history.replaceState({}, document.title, '/');
                 this.loading=false;
              }
              else if(url.searchParams.get('lk') && this.$firebase.auth().isSignInWithEmailLink(window.location.href) ){
                  // Additional state parameters can also be passed via URL.
                  // This can be used to continue the user's intended action before triggering
                  // the sign-in operation.
                  // Get the email if available. This should be available if the user completes
                  // the flow on the same device where they started it.
                  return localforage.getItem('emailForSignIn').then(email=>{
                    if (!email) {
                      // User opened the link on a different device. To prevent session fixation
                      // attacks, ask the user to provide the associated email again. For example:
                      email = window.prompt('Please provide your email for confirmation');
                    }
                    // The client SDK will parse the code from the link for you.
                    return this.$firebase.auth().signInWithEmailLink(email, window.location.href);
                  }).then((result)=>{
                     // Clear email from storage.
                      localforage.setItem('emailForSignIn', null);
                      // You can access the new user via result.user
                      // Additional user info profile not available via:
                      // result.additionalUserInfo.profile == null
                      // You can check if the user is new or existing:
                      // result.additionalUserInfo.isNewUser
                      window.history.replaceState({}, document.title, '/');
                    }).catch((error)=>{
                      console.log('error de passwrdlesslink',error);
                      window.history.replaceState({}, document.title, '/');
                        this.$f7.dialog.alert(error.message,error.code);
                         this.loading=false;
                      // Some error occurred, you can inspect the code: error.code
                      // Common errors could be invalid email and invalid or expired OTPs.
                    });
              }
              else{
                 this.loading=false
              }
              //end check got shared
              this.handleredirect()
            }
          });
      });
    }
  }
</script>
<style>
body {
  font-family: 'Open Sans', sans-serif;
}
.bold{
  font-weight: bold;
}
.avatar_panel, .backgoris{
  background: #001e27;
}
.avatar_panel img{
  width: 50%;
  border-radius: 50%;
  border: 4px solid #FFF;
  margin-top: 10px;
}
.latmenu .item-media svg {
  margin: 0 auto;
}
.latmenu li{
  background: #062d3a;
  color: #FFF;
}
.latmenu li .item-footer{
  color: #b9b9b9;
}
.latmenu li .item-media{
  color: #3fb0bd;
}
.latmenu li.active{
  background: #ffffff;
  color: #062d3a;
}
.latmenu li.active .item-title{
  font-weight: bold;
}
.latmenu li.active .item-media{
  color: #062d3a;
}
.latmenu li.active .item-footer{
  color: #062d3a;
}
.version_latx{
  color: #3fafbd;
}
.view-main .page-content, .popup .page-content{
  background:#f3f3f3!important;
}
</style>