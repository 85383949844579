export default {
  ar: "العربية",
  de: "Deutsch",
  en: "English",
  es: "Espanol",
  fr: "Francais",
  hi: "हिन्दी, हिंदी",
  he: "עברית",
  it: "Italiano",
  ja: "日本語 (にほんご)",
  ko: "한국어",
  nl: "Nederlands",
  pl: "język polski",
  pt: "Português",
  ru: "русский",
  zh: "中文"
}