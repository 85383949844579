
import HomePage from '../pages/home.vue';
import Session from '../pages/session.vue';
import Sessions from '../pages/sessions.vue';
import Admin from '../pages/admin.vue';
import Core from '../pages/core.vue';
import Teams from '../pages/teams.vue';
import team from '../pages/team.vue';
import Myteams from '../pages/myteams.vue';

import Team_codes from '../pages/team_codes.vue';
import Team_members from '../pages/team_members.vue';
import Team_map from '../pages/team_map.vue';

import Messager from '../pages/messager.vue';

import Exports from '../pages/exports.vue';
import About from '../pages/about.vue';
import Instructions from '../pages/instructions.vue';

import Selectusers from '../pages/selectusers.vue';



import Landing from '../pages/landing.vue';
import Profile from '../pages/profile.vue';
import Aroundme from '../pages/aroundme.vue';
import Users from '../pages/users.vue';
import User from '../pages/user.vue';

import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/admin/messenger/',
    component: Messager,
  },
  {
    path: '/admin/selectusers/',
    component: Selectusers,
  },
  {
    path: '/sessions/',
    component: Sessions,
  },
  {
    path: '/about/',
    component: About,
  },
  {
    path: '/instructions/',
    component: Instructions,
  },
  {
    path: '/admin/',
    component: Admin,
  },
  {
    path: '/admin/core/',
    component: Core,
  },
  {
    path: '/admin/exports/:teamid/',
    component: Exports,
  },
  {
    path: '/admin/users/',
    component: Users,
  },
  {
    path: '/admin/teams/',
    component: Teams,
  },
  {
    path: '/admin/team/',
    component: team,
  },
  {
    path: '/myteams/',
    component: Myteams,
  },
  {
    path: '/team/codes/:id/',
    component: Team_codes,
  },
  {
    path: '/team/members/:id/',
    component: Team_members,
  },
  {
    path: '/team/map/:id/',
    component: Team_map,
  },
  {
    path: '/admin/user/',
    component: User,
  },
  {
    path: '/session/:sessionid',
    component: Session,
  },
  {
    path: '/profile/',
    component: Profile,
  },
  {
    path: '/aroundme/:tid/',
    component: Aroundme,
  },
  {
    path: '/landing/',
    component: Landing,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
