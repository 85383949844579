<template>
  <f7-page @page:beforein="preLetsGo()" name="teams">
     <f7-navbar :title="$t('updt.leteam')" :subtitle="$t('updt.crmgn')" back-link="Back">
       <f7-nav-right>
          <f7-block class="text-align-center">
           <f7-button outline raised @click="crearNuevoTeam()">{{$t('updt.crenw')}}</f7-button>
          </f7-block>
       </f7-nav-right>
       <f7-subnavbar :inner="false">
        <f7-searchbar
          search-container=".virtual-list"
          search-item="li"
          search-in=".item-title"
          :placeholder="$t('updt.srch')"
          :disable-button="true"
        ></f7-searchbar>
      </f7-subnavbar>
     </f7-navbar>

     
   <f7-list class="searchbar-not-found no-margin-top">
      <f7-list-item :title="$t('admin.noRes')"></f7-list-item>
    </f7-list>
    <f7-list
      ref="myteamslist"
      class="searchbar-found no-margin-top userslistsx"
      media-list
      virtual-list
      :virtual-list-params="{ items, searchAll, renderExternal, height: 87}"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          :key="index"
          link="#"
          @click="gototeam(item)"
          :title="item.name"
          :subtitle="item.hr"
          :footer="item.id"
          :badge="item.exp?'PRO':null" 
          :badge-color="item.exp?'blue':null"
          :style="`top: ${vlData.topPosition}px`"
        >
        </f7-list-item>
      </ul>
    </f7-list>
    <f7-block v-if="loadingTeams" class="text-align-center">
      <p>{{$t('admin.loadUsers')}}</p>
       <f7-preloader></f7-preloader>
    </f7-block>
   
  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
export default {
  data() {
    return {
      loadingTeams: false,
      items: [],
      vlData: {
        items: [],
      },
    }
  },
  computed:{
    ...mapState(['user','dev']),
  },
   watch:{
    items(teams){
      console.log('hay nuevos teams',teams);
      this.$refs.myteamslist.f7VirtualList.replaceAllItems(teams);
    }
  },
  created(){
   
  },
  methods:{
    
    crearNuevoTeam(){
      this.$f7.dialog.prompt('Indicate the new Team name','Name', (name)=> {
        console.log('el equipo es ',name)
        let elnombre = name || '';
        let sinespacios = name.trim();
        if(!sinespacios){
          return this.$f7.dialog.alert('You must provide a name','Empty field');
        }
          this.$f7.dialog.preloader(this.$t('neotem.creating'));
          let createam = this.$firebase.functions().httpsCallable('createam');
          return createam({teamname: sinespacios, byad:true, dev: this.dev}).then(snw=>{
            let datax=snw.data;
            if(datax.error){
              throw new Error(datax.error)
            }
            console.log('datax',datax);
            let saverp={};
          saverp[datax.id] = datax;
          this.$store.commit('mergeObjTo',{what:'teams',to:  saverp});
          this.items = this.$store.getters.allTeams;
          this.$f7.dialog.close();
          }).catch(error=>{
            this.$f7.dialog.close();
            console.log(error);
            this.$f7.dialog.alert(error.message,error.code);
          });
      });
    },
    gototeam(team){
     return this.$f7router.navigate('/admin/team/', {
        props: team
      })
    },
    searchAll(query, items) {
        const found = [];
        let elkery = query.toLowerCase();
        for (let i = 0; i < items.length; i += 1) {
          if (
             query.trim() === ''
            || items[i].low.indexOf(elkery) >= 0
            ) found.push(i);
        }
        return found;
      },
      renderExternal(vl, vlData) {
        this.vlData = vlData;
      },
    preLetsGo(){
      if(!this.items.length){
        this.readAllTeams();
      }
      else{
        this.items = this.$store.getters.allTeams;
      }
    },
   readAllTeams(){
     this.loadingTeams = true;
      return this.$firebase.database().ref('teams').once('value').then(nspsh=>{
        let teams = nspsh.val() || {};
        this.$store.commit('setObjTo',{what:'teams',to: teams});
        this.items = this.$store.getters.allTeams;
         this.loadingTeams = false
      }).catch(error=>{
        console.log(error);
        this.loadingTeams = false
        this.$f7.dialog.alert(error.message,error.code);
      });
   }
  }
}
</script>
<style>

</style>