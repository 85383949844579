<template>
  <f7-swiper-slide>
          <f7-block class="medioalto">
          </f7-block>
          <f7-block class="text-align-center margin-top">
            <f7-block-title>{{$t('swiperOne.wel')}}MaskCount.com</f7-block-title>
            <p>MaskCount.com {{$t('swiperOne.isx')}}.</p><p>{{$t('swiperOne.howx')}}</p>
            <f7-link @click="nexter()"><font-awesome-icon  icon="hand-point-right" class="fa-3x" /></f7-link>
          </f7-block>
        </f7-swiper-slide>
</template>
<script>
//import //swiperone

export default {
  //props:[''],
    data() {
      return {
       
        }
    },
    methods:{
      nexter(){
        this.$emit('gonext');
      },
  }
}
</script>
<style>
.medioalto{
  height: 45%;
  text-align: center;
  margin-top: 0px!important;
  background-image: url('../static/img/slide1.jpg');
  background-repeat:no-repeat;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position:center;
  margin-bottom: 0px;
}
</style>