import Vuex from 'vuex'
import Vue from 'vue'
import moment from 'moment'
Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        appVersion: '1.8.8',
        activeTab:'/',
        loginAbierto: true,
        dev: process.env.NODE_ENV=== 'production'?false:true,
        user: {},
        alreadyAsked: false,
        apimaps: 'AIzaSyCXBEK9Im5wRvo4xkIJc2O3D6KgE2CVBuY',
        onboard: false,
        lastPos: null,
        sessions: {},
        preloadedObservations:[],
        selectedRecipients:[],
        sessionChanged: null,
        core_badges:{
            a: { iko: 'eye', name: 'Observer'},
            b: { iko: 'medal', name: 'Research Assistant'},
            c: { iko: 'atom', name: 'Undergrad Student'},
            d: { iko: 'sun', name: 'Graduate Student'},
            e: { iko: 'jedi', name: 'Medical Student'},
            f: { iko: 'award', name: 'MPH Student'},
            g: { iko: 'award', name: 'Physician'},
            h: { iko: 'award', name: 'Epidemiologist'},
            i: { iko: 'award', name: 'Junior Scientist'},
            j: { iko: 'award', name: 'Senior Scientist'},
            k: { iko: 'award', name: 'Mad Scientist'},
            l: { iko: 'award', name: 'Infectious Disease Fellow'},
            m: { iko: 'award', name: 'Epidemic Intelligence Officer'},
            n: { iko: 'award', name: 'Principal Investigator'},
            o: { iko: 'award', name: 'Professor'},
            p: { iko: 'award', name: 'Department Chair'},
            q: { iko: 'award', name: 'Dean'},
            r: { iko: 'award', name: 'CDC Director'},
            s: { iko: 'award', name: 'WHO Director'}
        },
        providers_dicc:{
            'google.com': {iko: 'google-plus-g', col: 'red', type: 'fab'},
            'apple.com': {iko: 'apple', col: 'black', type: 'fab'},
            'facebook.com':{iko: 'facebook-f', col: 'blue', type: 'fab'},
            'twitter.com': {iko: 'twitter', col: 'lightblue', type: 'fab'},
            'phone': {iko: 'mobile-alt', col: 'primary', type: 'fas'},
            'password': {iko: 'envelope', col: 'gray', type: 'fas'}
          },
        forceGetMyBadges: false,
        teams: {},
        items:[]
    },
    getters: {
        onesession: state => {
            return (id) => {
                return state.sessions[id] || {w:0,id:0};
            }
          },
          oneteam: state => {
            return (id) => {
                return state.teams[id] || {id:0};
            }
          },
          allTeams: state =>{
            let prearreglado= Object.values(state.teams) || [];
            let neoarreglado = prearreglado.map(unteam=>{
              unteam.low = unteam.name.toLowerCase();
              unteam.hr = moment(unteam.createdAt).format('LL')
              return unteam;
            });
              neoarreglado.sort((a, b) => {
                  if(a.low > b.low){
                      return 1
                  }
                  else if(a.low < b.low){
                      return -1
                  }
                  else{
                    return 0
                  }
              });
              return neoarreglado
          },
          myTeams: state =>{
            let prearreglado= Object.values(state.teams) || [];
            let myprivs = state.user.privx || {};
            let myteams = myprivs.teams || {};
            let neoarreglado = prearreglado.filter(leteam=>{
              return myteams[leteam.id];
            }).map(unteam=> {
                unteam.low = unteam.name.toLowerCase();
                unteam.hr = moment(unteam.createdAt).format('LLL')
                return unteam;
            });
              neoarreglado.sort((a, b) => {
                  if(a.low > b.low){
                      return 1
                  }
                  else if(a.low < b.low){
                      return -1
                  }
                  else{
                    return 0
                  }
              });
              return neoarreglado
          }
    },
    mutations: {
        createOrUpdateSession:(state,session)=>{
            if(state.sessions[session.id]){
                state.sessions[session.id] = Object.assign({},state.sessions[session.id],session);
            }
            else{
                let lobjs={};
                lobjs[session.id] = session;
                state.sessions = Object.assign({},state.sessions,lobjs);
            }
            state.sessionChanged = session.id
        },
        updateAvatar:(state,photoURL)=>{
            state.user.photoURL = photoURL;
        },
        setUserProp:(state,payload)=>{
            state.user[payload.prop] = payload.val;
        },
        setBadges:(state,mybadges)=>{
            state.user.privx = Object.assign({},state.user.privx,{badges: mybadges});
        },
        delTeamfromPriv:(state,teamid)=>{
            let prevprivs = state.user.privx || {};
            let teams = Object.assign({}, (prevprivs.teams || {}));
            delete teams[teamid];
            state.user.privx = Object.assign({},prevprivs,{teams: teams});
        },
        setTeamStatus:(state,neoval)=>{
            let prevprivs = state.user.privx || {};
            let teams = Object.assign({}, (prevprivs.teams || {}), neoval);
            state.user.privx = Object.assign({},prevprivs,{teams: teams});
        },
        setSignaUA:(state,neoval)=>{
            let prevprivs = state.user.privx;
            state.user.privx = Object.assign({},prevprivs,{ua: neoval});
        },
        setprivxPM:(state,neoval)=>{
            let prevprivs = state.user.privx;
            state.user.privx = Object.assign({},prevprivs,{pm: neoval});
        },
        setWhatTo: (state,payload)=>{
            state[payload.what] = payload.to;
        },
        pushToArray: (state,payload) => {
          state[payload.array].push(payload.value);
      },
      removeFromArray: (state,payload)=>{
        let indiexist = state[payload.array].findIndex(unitem => unitem[payload.attr]==payload.value);
        if(indiexist!=-1){
            state[payload.array].splice(indiexist,1);
        }
    },
       mergeObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},state[payload.what],payload.to);
        },
        // setTeamInfo:(state,payload)=>{
        //     state.teams[payload.id]=Object.assign()
        // },
        setObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},payload.to);
        },
        removeFromSimpleArray(state, payload){
            let index = state[payload.array].findIndex((item) =>item === payload.value);
            if(index !== -1){
                state[payload.array].splice(index, 1);
            }
        },
    },
    actions:{

    }
})