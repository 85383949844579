<template>
  <f7-page  name="team_map">
     <f7-navbar title="Sessions" back-link="Back">
        
  
     </f7-navbar>
      
      
  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
export default {
    props:['id'],
  data() {
    return {
     
    }
  },
  computed:{
    ...mapState(['user','dev']),
  
  },
  methods:{
  
  }
}
</script>
<style>
.al100{
  height: 100%!important;
}
.maskmapactv{
  text-align: center;
  background: #e4e4e4;
}
.maskmapactv .preloader{
  margin-top: 150px;
}
.progrex.progressbar span{
  background: linear-gradient(90deg, #ff1919ff, #fd481fff, #fb7624ff, #f9a52aff, #f7d32fff, #a4e23dff, #51f14bff, #29f6a5ff, #15f9d2ff, #00fbffff);
}
.progrex.progressbar{
  height: 13px;
  border-radius: 7px;
}
.calendar-day .calendar-day-event {
    background-color: #3fafbd!important;
}
.curpointer .item-content{
padding-left:0px;
display: block;
width: 120px;
}
</style>