<template>
  <f7-page @page:beforein="preLetsGo()" name="team_members">
     <f7-navbar :title="$t('updt.memx')" back-link="Back">
        <f7-nav-right>
      <f7-link class="searchbar-enable" data-searchbar=".searchbar-timmem" icon-md="material:search"></f7-link>
    </f7-nav-right>
     <f7-searchbar
      class="searchbar-timmem"
      expandable
      search-container=".tmmbmb"
      search-in=".item-title"
      :placeholder="$t('updt.srch')"
      :remove-diacritics="true"
      :disable-button="true"
    ></f7-searchbar>
     </f7-navbar>
       <f7-list class="searchbar-not-found">
        <f7-list-item :title="$t('vides.noff')"></f7-list-item>
      </f7-list>
      <f7-list class="tmmbmb no-margin-top" media-list>
        <f7-list-item divider :title="$t('vides.almem')+' '+(iManage && miembros.length?'('+$t('vides.sltr')+')':'')"></f7-list-item>
        <f7-list-item v-if="loadingMembers" :title="$t('login.loading')"> <f7-preloader slot="after"></f7-preloader> </f7-list-item>
        <f7-list-item v-if="!loadingMembers && !miembros.length" :title="$t('vides.nomem')"></f7-list-item>
        <f7-list-item v-for="miembro in miembros" :class="{inkactv: miembro.inactive}" :header="miembro.inactive?$t('vides.inamem'):null" :key="miembro.map.uid" :swipeout="(iManage && miembro.map.uid!=user.uid)?true:null" :title="miembro.map.displayName" :after="miembro.inactive?$t('vides.inak'):miembro.tp" :subtitle="miembro.map.email">
          <img :src="miembro.map.photoURL" slot="media" />
           <f7-swipeout-actions v-if="iManage && miembro.map.uid!=user.uid" right>
          <f7-swipeout-button @click="removemember(miembro.map.uid)" close color="red">{{$t('vides.inakr')}}</f7-swipeout-button>
        </f7-swipeout-actions>
          </f7-list-item>
          <f7-block-footer v-if="iManage">{{$t('vides.inxeso')}}</f7-block-footer>
      </f7-list>
  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
export default {
    props:['id'],
  data() {
    return {
      loadingMembers: false,
      miembros: []
    }
  },
  computed:{
    ...mapState(['user','dev']),
      imMember(){
      let privxs= this.user.privx || {};
      let teams = privxs.teams || {};
      return teams[this.id];
    },
    iManage(){
      return ((this.imMember && this.imMember=='admin') || this.user.privx.admin_users )
    },
  },
  methods:{
    removemember(memuid){
      this.$f7.dialog.preloader('Inactivating member...')
    let removeFromTeam = this.$firebase.functions().httpsCallable('removeFromTeam');
      return removeFromTeam({teamid: this.id, memberuid: memuid, dev: this.dev}).then(snw=>{
        let datax=snw.data;
        console.log('llego',snw,datax);
        if(datax.error){
          throw new Error(datax.error)
        }
        let indiexist = this.miembros.findIndex(unmem => unmem.map.uid==memuid);
        if(indiexist!=-1){
          let neoda={};
          neoda.inactive={wh: this.$moment().unix(), rs: 'removed', by: this.user.uid};
          let toreplace=Object.assign({},this.miembros[indiexist],neoda);
            this.miembros.splice(indiexist,1,toreplace);
        }
        console.log('removed OK');
        this.$f7.dialog.close();
      }).catch(error=>{
        this.$f7.dialog.close();
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })
    },
    preLetsGo(){
      //cargar info del team members y de los codes
      this.loadingMembers = true;
      let getMembers = this.$firebase.functions().httpsCallable('getMembers');
      return getMembers({teamid: this.id, dev: this.dev}).then(snw=>{
        let datax=snw.data;
         if(datax.error){
          throw new Error(datax.error)
        }
        this.miembros = datax.members;
        this.loadingMembers = false;
        console.log(datax)
      }).catch(error=>{
        this.loadingMembers = false;
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })
    }
  }
}
</script>
<style>
.tmmbmb .item-media img{
  width: 45px!important;
  height: 45px!important;
  max-height: 45px!important;
  max-width: 45px!important;
  border-radius: 50%;
}
.inkactv .item-after, .inkactv .item-header{
  color: red!important;
}
</style>