<template>
  <f7-page name="selectusers">
     <f7-navbar title="Select recipients" :subtitle="!selectedRecipients.length?'List only includes users with email':(selectedRecipients.length+ ' recipient'+(selectedRecipients.length>1?'s':'')+' selected')" back-link="Back">
      <f7-nav-right><f7-block><f7-button @click="verselecciont = !verselecciont" outline>View {{verselecciont?'all':'selection'}}</f7-button></f7-block></f7-nav-right>
       <f7-subnavbar :inner="false">
        <f7-searchbar
          search-container=".virtual-list"
          search-item="li"
          :placeholder="$t('updt.srch')"
          search-in=".item-title"
          :disable-button="true"
        ></f7-searchbar>
      </f7-subnavbar>
     </f7-navbar>
   <f7-list class="searchbar-not-found no-margin-top">
      <f7-list-item :title="$t('admin.noRes')"></f7-list-item>
    </f7-list>
    <f7-list
      ref="myuserslist"
      class="searchbar-found no-margin-top userslistsx"
      media-list
      virtual-list
      :virtual-list-params="{ items, searchAll, renderExternal, height: 87}"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          :key="index"
          checkbox
          :checked="selectedRecipients.includes(item.email)"
          :value="item.email"
          @change="checkselection"
          :title="item.displayName"
          :footer="'Created at '+item.creationTime"
          :subtitle="item.email"
          :style="`top: ${vlData.topPosition}px`"
        >
        <img slot="media" :src="item.photoURL || '../static/img/avatar.jpg'" >
        </f7-list-item>
      </ul>
    </f7-list>
  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
export default {
  
  data() {
    return {
      verselecciont: false,
      vlData: {
        items: [],
      },
    }
  },
  computed:{
    ...mapState(['user','dev','selectedRecipients']),
    items(){
      if(this.verselecciont){
        return this.$store.state.items.filter(oneuser=>{
          return oneuser.email && this.selectedRecipients.includes(oneuser.email);
        });
      }
      else{
        return this.$store.state.items.filter(oneuser=>{
          return oneuser.email;
        });
      }
    }
  },
   watch:{
    items(neousers){
      console.log('hay nuevos users',neousers);
      this.$refs.myuserslist.f7VirtualList.replaceAllItems(neousers);
    }
  },
  created(){
   
  },
  methods:{
    checkselection(event){
        const value = event.target.value;
        if (event.target.checked) {
          if(!this.selectedRecipients.includes(value)){
            this.$store.commit('pushToArray',{array:'selectedRecipients',value:value});
          }
        } else {
          this.$store.commit('removeFromSimpleArray',{array:'selectedRecipients',value:value});
        }
    },
    searchAll(query, items) {
        const found = [];
        let elkery = query.toLowerCase();
        for (let i = 0; i < items.length; i += 1) {
          if (
             query.trim() === ''
            || items[i].displLower.indexOf(elkery) >= 0
            || (items[i].email && items[i].email.indexOf(elkery) >= 0)
            ) found.push(i);
        }
        return found;
      },
      renderExternal(vl, vlData) {
        this.vlData = vlData;
      }
  }
}
</script>
<style>
.userslistsx img{
  width: 45px;
  border-radius: 25%;
}
</style>