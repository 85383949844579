<template>
  <f7-page class="videoemb" name="instructions">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :title="$t('instructions.title')" :subtitle="$t('instructions.sub')"></f7-nav-title>
    </f7-navbar>
<f7-list >
  <f7-list-item link="#" @click="popupOpened = true" :title="$t('vides.shoonvb')"></f7-list-item>
  <template v-if="false">
  <f7-list-item divider :title="$t('vides.instvx')"></f7-list-item>
  <f7-list-item :title="$t('vides.lgn')" link="/video/8hyGX0ePwfg/"></f7-list-item>
   <f7-list-item :title="$t('vides.nswtx')" link="/video/wKSzocI3FUg/"></f7-list-item>
   <f7-list-item :title="$t('vides.menwtx')" link="/video/yFAkQdwNsaM/"></f7-list-item>
   <f7-list-item :title="$t('vides.prfwtx')" link="/video/I5Kk7-PUkiU/"></f7-list-item>
   <f7-list-item :title="$t('vides.seswtx')" link="/video/Aeq1RVlrcqs/"></f7-list-item>
  </template>
  </f7-list>

<f7-block-title>{{$t('night.howto')}}</f7-block-title>
<f7-row>
  <f7-col v-for="instruc in ietrats" :key="instruc.icnklass" width="100" medium="20">
    <f7-card class="al500 safe-areas"  expandable :swipe-to-close="true">
  <f7-card-content :padding="false">
    <div :class="'bgxpns '+instruc.icnklass" >
      <f7-card-header text-color="white" class="backnegrs">
        {{instruc.title}}
      </f7-card-header>
      <f7-link card-close color="white" class="card-opened-fade-in" :style="{position: 'absolute', right: '15px', top: '15px'}" icon-f7="multiply_circle_fill"></f7-link>
    </div>
    <div class="card-content-padding">
      <p> {{instruc.txt}}</p>
    </div>
  </f7-card-content>
  </f7-card>
  </f7-col>
</f7-row>




<f7-popup class="instruc-popup"  :opened="popupOpened"  @popup:closed="popupOpened = false" @popup:opened="opopReady()" >
      <f7-page v-if="showswiper">
         <f7-navbar >
          <f7-nav-right>
            <f7-link popup-close>{{$t('login.close')}}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-swiper ref="leswiper2" :params="{grabCursor: true}" class="subretodo" pagination :navigation="$f7.device.desktop" scrollbar>
         <swiperone @gonext="nexter()"></swiperone>
        <swipertwo ></swipertwo>
      </f7-swiper>
      </f7-page>
    </f7-popup>

  </f7-page>
</template>
<script>
import swiperone from '../components/swiperone.vue';
import swipertwo from '../components/swipertwo.vue';
import { mapState} from 'vuex'
export default {
   components:{swiperone,swipertwo},
  data() {
    return {
      popupOpened:false,
      showswiper: false,
      ietrats:[
        {icnklass: 'howone', title: this.$t('night.one_titl'),txt:this.$t('night.one_txt')},
        {icnklass: 'howtwo',title: this.$t('night.two_title'),txt:this.$t('night.two_txt')},
        {icnklass: 'howthree',title:  this.$t('night.three_title'),txt:this.$t('night.three_txt')},
        {icnklass: 'howfour',title:  this.$t('night.four_title'),txt:this.$t('night.four_txt')},
        {icnklass: 'howfive',title:  this.$t('night.fv_title'),txt:this.$t('night.fv_txt')},
        {icnklass: 'howsix',title:  this.$t('night.sx_title'),txt:this.$t('night.sx_txt')},
        ]
    }
  },
  computed:{
    ...mapState(['user']),

  },
  created(){
   
  },
  methods:{
    nexter(){
        this.$refs.leswiper2.swiper.slideNext();
      },
    opopReady(){
      this.showswiper = true;
    }
  }
}
</script>
<style>
.subretodo{
  width:100%;
  height: 100%;
}
.bgxpns{
  background-repeat:no-repeat;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
  background-position:center top;
  height: 500px;
}
.howone{
   background-image: url('../static/how/login.png');
}
.howtwo{
   background-image: url('../static/how/newsession.jpg');
}
.howthree{
   background-image: url('../static/how/liveSession.png');
}
.howfour{
   background-image: url('../static/how/mySessions.png');
}
.howfive{
   background-image: url('../static/how/aroundMe.png');
}
.howsix{
   background-image: url('../static/how/profile.png');
}
.backnegrs.card-header{
  background: rgb(0 0 0 / 49%);
  font-size: 18px;
    line-height: 18px;
}
.al500{
  height: 400px;
}
</style>