<template>
  <f7-page @page:beforein="preLetsGo()"  name="aroundme">
    <f7-navbar :back-link="tid!=='x'?'Back':null">
      <f7-nav-left v-if="tid=='x'">
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :title="tid=='x'?$t('common.aroundme'):$t('neotem.map')" :subtitle="tid=='x'?$t('common.aroundmesub'):$t('neotem.maplox')"></f7-nav-title>
      <f7-nav-right v-if="!attempting">
         <f7-list no-hairlines-md class="no-margin">
       <f7-list-input
       class="curpointer"
          type="datepicker"
          :placeholder="$t('adminLive.date')"
          readonly
          :calendar-params="{openIn: 'auto',value: [new Date()], events: { from: !user.phoneNumber?$moment().subtract(3, 'd').toDate():new Date(2020, 8, 12), to: new Date() },  disabled: {from: new Date()}, closeOnSelect: true, on: {
          change: cambiafech
        }
   }"
        >
      </f7-list-input>
         </f7-list>
       </f7-nav-right>
      <f7-subnavbar>
      <f7-block class="padding-right-half">0%</f7-block>
      <f7-progressbar class="progrex" :infinite="computing" :progress="100"></f7-progressbar>
      <f7-block class="padding-left-half">100%</f7-block>
    </f7-subnavbar>
    </f7-navbar>
   <f7-block class="no-margin no-padding almaptop">
      <div class="maskmapactv al100">
        <f7-preloader v-if="attempting"></f7-preloader>
        <template v-else>
          <p class="no-margin-top padding">{{$t('common.wantx')}}</p>
          <f7-block><f7-button @click="geolocme()" fill raised>{{$t('common.load')}}</f7-button></f7-block>
        </template>
      </div>
   </f7-block>
   <f7-block class="fixbott backgorisxx no-margin">
     <f7-row v-if="!lastinfoON">
      <f7-col class="text-align-center">
       {{$t('lgn.clkcfdx')}}
      </f7-col>
    </f7-row>
     <f7-row v-else class="text-align-center">
      <f7-col>
        <font-awesome-icon class="text-color-red" :icon="['fas', 'head-side-cough']" /> {{ toFixedNumber(infoboxselected.h.nom,1)}}% | 
        <font-awesome-icon class="text-color-yellow" :icon="['fas', 'head-side-cough-slash']" /> {{ toFixedNumber(infoboxselected.h.par,1)}}% | 
        <font-awesome-icon class="text-color-primary"  :icon="['fas', 'head-side-mask']" /> {{ toFixedNumber(infoboxselected.h.p,1)}}%
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col class="padding-top fixcenter">
        <GChart
      type="PieChart"
      :data="[
        ['Type', 'Count'],
        ['Mask',    infoboxselected.i.m ],
        ['Partial',      infoboxselected.i.p],
        ['No Mask',  infoboxselected.i.n]
      ]"
      :options="chartOptionsmin"
    />
        </f7-col>
      <f7-col class="altofixer fixcenter">
        <GChart
      type="PieChart"
      :data="[
        ['Type', 'Count'],
        ['Mask',    infoboxselected.k.m ],
        ['Partial',      infoboxselected.k.p],
        ['No Mask',  infoboxselected.k.n]
      ]"
      :options="chartOptions"
    />
      </f7-col>
      <f7-col class="padding-top fixcenter">
        <GChart
      type="PieChart"
      :data="[
        ['Type', 'Count'],
        ['Mask',    infoboxselected.o.m ],
        ['Partial',      infoboxselected.o.p],
        ['No Mask',  infoboxselected.o.n]
      ]"
      :options="chartOptionsmin"
    />
        </f7-col>
    </f7-row>
    <f7-row class="text-align-center">
      <f7-col>{{$t('updt.indoors')}}={{infoboxselected.hi.t || '--'}}</f7-col>
       <f7-col>{{$t('lgn.overall')}}={{infoboxselected.h.t || '--'}}</f7-col>
      <f7-col>{{$t('updt.outdoors')}}={{infoboxselected.ho.t || '--'}}</f7-col>
    </f7-row>
   </f7-block>
  </f7-page>
</template>
<script>
import loadGoogleMapsApi from "load-google-maps-api";
import localforage from 'localforage';
import { commonmixin } from '../mixins/common'
import { GChart } from 'vue-google-charts'
//        <vc-donut :sections="getvaluex(infoboxselected.i)" :total="(infoboxselected.hi.t || 100)" :thickness="25" :foreground="lastinfoON && !infoboxselected.hi.e?'#ffe4db':null" background="#f3f3f3" :size="95" unit="px" ><div class="fgrx text-color-primary">{{infoboxselected.hi.e?'--':(toFixedNumber(infoboxselected.hi.p,1)+'%')}}</div><span>{{$t('updt.indoors')}}</span></vc-donut>

//  <vc-donut :sections="getvaluex(infoboxselected.k)" :total="(infoboxselected.h.t || 100)" :thickness="35" :foreground="lastinfoON && !infoboxselected.h.e?'#ffe4db':null" background="#f3f3f3" :size="115" unit="px" ><div class="fgrx text-color-primary">{{infoboxselected.h.e?'--':(toFixedNumber(infoboxselected.h.p,1)+'%')}}</div><span>{{$t('lgn.overall')}}</span></vc-donut>

//        <vc-donut :sections="getvaluex(infoboxselected.o)" :total="(infoboxselected.ho.t || 100)" :thickness="25" :foreground="lastinfoON && !infoboxselected.ho.e?'#ffe4db':null" background="#f3f3f3" :size="95" unit="px" ><div class="fgrx text-color-primary">{{infoboxselected.ho.e?'--':(toFixedNumber(infoboxselected.ho.p,1)+'%')}}</div><span>{{$t('updt.outdoors')}}</span></vc-donut>

import { mapState} from 'vuex'
export default {
     mixins: [ commonmixin],
     components:{
       GChart
     },
     props:['tid'],
  data() {
    return {
      chartOptions: {
        width: 115,
          height: 115,
          pieHole: 0.6,
          pieSliceText: 'none',
          backgroundColor:'#f3f3f3',
          legend: 'none',
          chartArea: {width: '100%', height: '100%'},
          colors: ['#3fafbd', '#ffcc00', '#ff3b30']
      },
      chartOptionsmin: {
          width: 80,
          height: 80,
          pieHole: 0.6,
          pieSliceText: 'none',
          backgroundColor:'#f3f3f3',
          legend: 'none',
          chartArea: {width: '100%', height: '100%'},
          colors: ['#3fafbd', '#ffcc00', '#ff3b30']
      },
       googleMaps: null,
       lemapa: null,
       attempting: true,
       startPoint:{
         lat: 39.7798004,
         lng: -86.1800271
       },
      prevcenter_lat: null,
      prevcenter_long: null,
      prevradius: null,
      timeouter: null,
      rectangles:{},
      rectamarkers:{},
      infowindows:{},
      lastinfoON:null,
        firstLoadReady: false,
        calendarDate: this.$moment().format('YYYYMMDD'),
      infoboxselected:{
        k:{m:0,n:0,p:0},
        i:{m:0,n:0,p:0},
        o:{m:0,n:0,p:0},
        h:{c:'',p:0,t:0},
        ho:{c:'',p:0,t:0},
        hi:{c:'',p:0,t:0},
        u:0
      },
      computing: true
      //validar aqui abajo
    }
  },
  computed:{
    ...mapState(['user','lastPos','dev'])
  },
  mounted(){
    console.log('teamid',this.tid);
  },
  methods:{
    resetlastinf(){
    this.infoboxselected = Object.assign({},
      {
        k:{m:0,n:0,p:0},
        i:{m:0,n:0,p:0},
        o:{m:0,n:0,p:0},
        h:{c:'',p:0,t:0},
        ho:{c:'',p:0,t:0},
        hi:{c:'',p:0,t:0},
        u:0
      });
    },
     cambiafech(calendar, value){
      if(this.firstLoadReady){
        let freshuser = this.$firebase.auth().currentUser;
        console.log('cambiando fecha',value[0]);
        let momentSelecetd = this.$moment(value[0]);
         this.calendarDate = momentSelecetd.format('YYYYMMDD');
         if(momentSelecetd.isBefore(this.$moment().subtract(2, 'd'), 'day') && !freshuser.phoneNumber){
           this.$f7.dialog.alert(this.$t('updt.datun'),this.$t('updt.unverx'),()=>{
             console.log('volver a la fecha de hoy');
           });
         }
         else{
          console.log('NUeva fehca calendario es',this.calendarDate);
          this.$f7.progressbar.show();
          //BORRAR TODO DE LA INTERFACE
          let keyado = Object.keys(this.rectangles) || [];
          keyado.forEach(rectkey => {
              this.rectangles[rectkey].setMap(null);
              this.rectangles[rectkey] = null;
              //ahora borrar markers tambien
              this.rectamarkers[rectkey].setMap(null);
              this.rectamarkers[rectkey] = null;
              //borrar infowindows
              this.infowindows[rectkey]=null;
          });
          this.rectangles = Object.assign({});
          this.rectamarkers = Object.assign({});
          this.infowindows = Object.assign({});
          this.lastinfoON=null;
          this.resetlastinf();

          //fin borrado todo interface
          if(this.timeouter){
            clearTimeout(this.timeouter);
          }
          this.computing = true;
          this.reinitiQuerry( this.prevcenter_lat,this.prevcenter_long,this.prevradius);
         }
      }
    },
      modulardorQuerries(lat,lng,radio_en_mts){
      console.log('modulando')
      if(this.timeouter){
        clearTimeout(this.timeouter);
      }
      this.timeouter = setTimeout(()=>{
        this.reinitiQuerry(lat,lng,radio_en_mts);
         }, 1500);
    },
    preLetsGo(){
      //ver si teiene navigator geoloc
       if('geolocation' in navigator) {
        /* geolocation is available */
        this.letsgo();
      } else {
        /* geolocation IS NOT available */
          this.loadMap();
      }
    },
    letsgo(){
      //ver si hay last post
      if(this.lastPos){
        //tuvo geoloc, go
          this.geolocme();
      }
      else{
        console.log('viendo si hay geoperm')
        localforage.getItem('geoperm_'+this.user.uid).then((geoperm) => {
        if(geoperm){
          this.geolocme();
        }
        else{
          this.attempting = false;
        }
      }).catch( (err)=> {
        this.attempting = false;
        console.log(err, 'error reading localforage')
      });
      }
    },
    geolocme(){
      //start gelocloc
      this.$f7.progressbar.show();
      this.attempting = true;
      return navigator.geolocation.getCurrentPosition(this.geosuccess, this.geoerror,{ maximumAge: 30000, timeout: 15000 });
    },
    geoerror(err){
      this.$f7.progressbar.hide();
      this.attempting = false;
      console.warn('ERROR(' + err.code + '): ' + err.message);
      this.loadMap();
      this.$f7.dialog.alert(this.$t('common.your'), err.message);
    },
    geosuccess(position){
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          wh: this.$moment().unix()
        };
        this.startPoint = Object.assign({}, pos);
        this.$store.commit('setWhatTo',{what:'lastPos', to: pos});
         localforage.setItem('geoperm_'+this.user.uid, true);
        this.loadMap();
      },
      loadMap(){
      //primero crear el mapa y centrarme
      loadGoogleMapsApi({key:this.$store.state.apimaps,libraries:['geometry','visualization']}).then((googleMaps)=> {
          this.$f7.progressbar.hide();
          this.attempting = false;
          this.googleMaps=googleMaps;
          this.lemapa= new this.googleMaps.Map(document.querySelector('.maskmapactv'), {
             center: {lat: this.startPoint.lat, lng: this.startPoint.lng},
              zoom: 17,
              maxZoom: 18,
              streetViewControl: false,
              mapTypeControl: false,
               fullscreenControl: false,
               restriction: {
                  latLngBounds: {
                      north: 85,
                      south: -85,
                      west: -180,
                      east: 180
                  },
                  strictBounds: true
              },
            });
             this.$f7.progressbar.hide();

            this.lemapa.addListener('idle', ()=> {
                console.log('mapa setted')
                let bounds = this.lemapa.getBounds();
                let centrox = this.lemapa.getCenter();
                if (bounds && centrox) {
                  let ne = bounds.getNorthEast();
                  // Calculate radius (in meters).
                  let radio_en_mts = google.maps.geometry.spherical.computeDistanceBetween(centrox, ne);
                  if(this.prevcenter_lat!=centrox.lat() || this.prevcenter_long!=centrox.lng() || this.prevradius!=radio_en_mts){
                      this.prevcenter_lat=centrox.lat();
                      this.prevradius=radio_en_mts;
                      this.prevcenter_long=centrox.lng();
                      this.$f7.progressbar.show();
                      this.computing = true;
                      this.modulardorQuerries(centrox.lat(),centrox.lng(),radio_en_mts);
                  }
                }
              });
          }).catch((error)=> {
           this.$f7.dialog.alert(error.message,error.code);
            console.log(error)
          });
      },
      dibujarec(box){
        console.log('BLOXIN',box)
        let perc = this.toFixedNumber(box.h.p,0);
        console.log('[er',perc)
          this.rectangles[box.id] = new google.maps.Rectangle({
              strokeColor: box.h.c,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: box.h.c,
              fillOpacity: 0.35,
              map: this.lemapa,
              bounds: {
                north: box.bnd[2],
                south: box.bnd[0],
                east: box.bnd[3],
                west: box.bnd[1]
              }
            });

           this.rectamarkers[box.id] = new google.maps.Marker({
              position: this.rectangles[box.id].getBounds().getCenter(),
               map: this.lemapa,
               icon: '../static/img/'+box.h.c.substring(1)+'.png',
               label: {text: `${perc}%`, color: 'white',fontSize: '12px',fontWeight:'bolder'}
            });
            this.infowindows[box.id] = new google.maps.InfoWindow({
              content: box.h.t+' '+this.$tc('vides.observax', box.h.t)+' <br/>'+this.$t('vides.byo')+' '+box.u+' '+this.$tc('vides.byobsex', box.u),
            });
          this.rectamarkers[box.id].addListener("click", () => {
            if(this.lastinfoON && this.infowindows[this.lastinfoON]){
              this.infowindows[this.lastinfoON].close();
            }
           console.log('cliceado ',box);
            this.infoboxselected = Object.assign({},box);
            this.infowindows[box.id].open(this.lemapa, this.rectamarkers[box.id]);
            this.lastinfoON=box.id;
            this.infowindows[box.id].addListener("closeclick", () => {
              console.log('cerraron esta caja');
               this.resetlastinf();
               this.lastinfoON = null;
            });
          });
      },
       reinitiQuerry(lat,lng,radio_en_mts){
           this.firstLoadReady = true;
          let neoreadlatestpro = this.$firebase.functions().httpsCallable('neoreadlatestpro');
          let radiotoquery = this.toFixedNumber((radio_en_mts/1000),3,10);
          console.log('CHELOX nuevo query con centro y radio km',lat,lng,radiotoquery, this.dev, this.tid, this.user.uid)
          let mapzoom = this.lemapa.getZoom();
          console.log('mapzoom',mapzoom);
          return  neoreadlatestpro({lat: lat, tid: this.tid, lng: lng, zm:mapzoom,  dev: this.dev, radius: radiotoquery, ruta: this.calendarDate }).then((result)=> {

              let respoesta=result.data || {};
              console.log('respppp',respoesta);
              if(respoesta.error){
                throw new Error(respoesta.error)
              }
              let arregloDeNuevas = Object.values(respoesta.procesexd);
              //paracada nueva, si NO esta en vieja, dibujar
              arregloDeNuevas.forEach(unbox => {
                if(!this.rectangles[unbox.id]){
                  this.dibujarec(unbox);
                }
              });
              //para cada vieja, si NO esta en nueva borrar?
              let arregloDeViejas = Object.keys(this.rectangles) || [];
              arregloDeViejas.forEach(rectkey => {
                if(!respoesta.procesexd[rectkey]){
                   this.rectangles[rectkey].setMap(null);
                    this.rectangles[rectkey] = null;
                    delete this.rectangles[rectkey]
                    //ahora borrar markers tambien
                    this.rectamarkers[rectkey].setMap(null);
                    this.rectamarkers[rectkey] = null;
                    delete this.rectamarkers[rectkey]
                    //borrar infowindows
                    this.infowindows[rectkey]=null;
                    delete this.infowindows[rectkey]
                }
              });

              this.$f7.progressbar.hide();
              this.computing = false;
            }).catch(error=>{
              console.log(error);
              this.$f7.progressbar.hide();
                this.startingSession = false;
                this.$f7.dialog.alert(error.message,error.code);
            })
       }
  }
}
</script>
<style>
.al100{
  height: 100%!important;
}
.almaptop{
  height: calc(100% - 168px)!important;
}
.fixbott{
  height: 168px!important;
}
.maskmapactv{
  text-align: center;
  background: #e4e4e4;
}
.maskmapactv .preloader{
  margin-top: 150px;
}
.progrex.progressbar span{
  background: linear-gradient(90deg, #ff1919ff, #fd481fff, #fb7624ff, #f9a52aff, #f7d32fff, #a4e23dff, #51f14bff, #29f6a5ff, #15f9d2ff, #00fbffff);
}
.progrex.progressbar{
  height: 13px;
  border-radius: 7px;
}
.calendar-day .calendar-day-event {
    background-color: #3fafbd!important;
}
.curpointer .item-content{
padding-left:0px;
display: block;
width: 120px;
}

.fgrx{
  font-size:20px;
}

.altofixer{
  height: 115px;
}
.fixcenter > div > div > div {
  margin: 0 auto!important;
}
.backgorisxx{
  background: #f3f3f3;
}
</style>