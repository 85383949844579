<template>
  <f7-page name="exports">
     <f7-navbar :title="$t('admin.export')" :subtitle="$t('admin.exportsub')" back-link="Back">
     </f7-navbar>
  <f7-list inset no-hairlines-md>
    <f7-list-item divider :title="$t('admin.select')"></f7-list-item>
    <f7-list-item radio value="observations" :checked="datasource === 'observations'" @change="datasource = $event.target.value" name="whattoexport"  :title="$t('admin.obs')"></f7-list-item>
    <f7-list-item radio value="sessions" :checked="datasource === 'sessions'" @change="datasource = $event.target.value" name="whattoexport"  :title="$t('admin.ses')"></f7-list-item>
    <f7-list-item divider :title="$t('admin.toExport')"></f7-list-item>
  <f7-list-input
    type="datepicker"
    :placeholder="$t('admin.selDate')"
    readonly
    :calendar-params="{value: [new Date(),new Date()], rangePicker: true, disabled: { from: new Date()}, closeOnSelect: true, on: {
          change: cambiafech
        }}"
  ></f7-list-input>
  </f7-list>
  <f7-block>
  <f7-button fill large raised @click="generate()">{{$t('admin.download')}}</f7-button>
  </f7-block>
  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
import axios from 'axios';

export default {
  props:['teamid'],
  data() {
    return {
      calendarDate: this.$moment().format('YYYYMMDD'),
      calendarDateTo: this.$moment().format('YYYYMMDD'),
      datasource: 'observations'
    }
  },
  computed:{
    ...mapState(['user','dev'])
  },
  methods:{
      cambiafech(calendar, value){
        console.log('cambiando fecha',value[0])
         this.calendarDate = this.$moment(value[0]).format('YYYYMMDD');
         this.calendarDateTo = !value[1]?this.calendarDate:this.$moment(value[1]).format('YYYYMMDD');
        console.log(this.calendarDate,this.calendarDateTo);
    },
   generate(){
      this.$f7.dialog.preloader(this.$t('admin.gene'));
      let fechax = this.calendarDate;
      let fechaxTo = this.calendarDateTo;
      return  this.$firebase.auth().currentUser.getIdToken().then((idToken) => {
        let envx = this.dev?'dev':'prod';
        //https://us-central1-maskcount-2020.cloudfunctions.net/maskcountapi
        //http://localhost:5000/maskcount-2020/us-central1/maskcountapi
        return axios.get('https://us-central1-maskcount-2020.cloudfunctions.net/maskcountapi/exprtsrange/'+this.datasource+'/'+envx+'/'+fechax+'/'+fechaxTo+'/'+this.teamid, {
                    headers: {'Authorization': 'Bearer ' + idToken},
                     responseType: 'blob', // important
                    });
       }).then(response=>{
          this.$f7.dialog.close();
          const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'text/csv'}));
          let fileLink = document.createElement('a');
          document.body.appendChild(fileLink);
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'mk_'+this.datasource+'_'+fechax+'.csv');
          fileLink.classList.add("external");
          fileLink.click();
          window.URL.revokeObjectURL(fileURL);
          this.$f7.toast.show({text: this.$t('admin.file'), closeTimeout: 2500, position: 'center',destroyOnClose: true});
      }).catch(error=>{
         this.$f7.dialog.close();
        console.log(error, error.message,error.response, error?.response?.data);
         this.$f7.dialog.alert( error.response?.data?.message || error.message);
      });
   }
  }
}
</script>