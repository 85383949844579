<template>
  <f7-page @page:beforein="getpredata()" name="profile">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :title="$t('profile.navTitlex')" :subtitle="$t('profile.subTitlex')"></f7-nav-title>
    </f7-navbar>

<f7-row>
    <f7-col width="100" medium="50">
<f7-block v-if="canshare"><f7-button @click="invitefirend()" fill large color="purple"><f7-preloader v-if="reloadxing"></f7-preloader><span v-else>{{ $t('profile.inviteButton') }}</span></f7-button></f7-block>
<f7-list media-list class="no-margin-vertical">
  <f7-list-item :class="{'skeleton-text skeleton-effect-blink':savingaccnt}" :title="user.displayName || $t('profile.setupName')" class="userpanl" :subtitle="user.email || null">
      <img slot="media" :src="user.photoURL || '../static/img/avatar.jpg'" />
      <div slot="text" class="provikons"> 
        <font-awesome-icon v-for="(providerx,ifx) in user.providerData" :key="ifx" :class="`fa-lg text-color-${providers_dicc[providerx.providerId].col}`" :icon="[providers_dicc[providerx.providerId].type, providers_dicc[providerx.providerId].iko]" />
      </div>
  </f7-list-item>
<input type="file" id="myimg-input" @change="filesChange($event)" accept="image/*" />
</f7-list>
<f7-block class="no-margin-vertical">
 <f7-row>
    <f7-col>
      <f7-button @click="tomarFoto()" fill raised>{{$t('profile.chagAvatar')}}</f7-button>
    </f7-col>
    <f7-col>
      <f7-button @click="editDisplayName()" fill raised>{{$t('profile.editName')}}</f7-button>
    </f7-col>
  </f7-row>
</f7-block>
  <f7-block-title class="no-margin-bottom margin-top">{{$t('profile.veriObs')}}</f7-block-title>
<f7-block v-if="!doIhavePhone">{{$t('profile.interested')}} <f7-button fill raised @click="openPhoneVerification()">{{$t('profile.linkPhone')}}</f7-button></f7-block>
<f7-block class="no-margin-bottom" v-else>{{ $t('profile.phonver', { phonenumber : doIhavePhone.uid } ) }}  <font-awesome-icon class="fa-lg" icon="check-double" /></f7-block>
    </f7-col>
    <f7-col width="100" medium="50">
<f7-block-title  >{{$t('profile.myBadg')}}</f7-block-title>
<f7-block>
  <p v-if="!mybadges.length">{{$t('profile.collectBadg')}}</p>
   <f7-chip v-for="badge in mybadges" :key="badge.iko" :text="badge.name" media-bg-color="blue">
        <font-awesome-icon slot="media" :icon="badge.iko"/>
      </f7-chip>
      <f7-chip v-if="doIhavePhone" text="Verified" media-bg-color="green">
        <font-awesome-icon slot="media" icon="check-double"/>
      </f7-chip>
  </f7-block>
<f7-block-title>{{$t('profile.myStats')}}</f7-block-title>
<f7-list :class="{'skeleton-text skeleton-effect-blink': loadingStats}" inset>
  <f7-list-item :title="$t('profile.ses')" :after="observx || '0'"></f7-list-item>
  <f7-list-item :title="$t('profile.time')" :after="duracion"></f7-list-item>
</f7-list> 
<f7-block-title >{{$t('profile.pref')}}</f7-block-title>
<f7-list class="no-margin-vertical">
   <f7-list-input
    :label="$t('profile.lang')"
    type="select"
    defaultValue="EN"
    :placeholder="$t('profile.please')"
    :value="$i18n.locale"
    @input="setLocale($event.target.value)"
  >
   <font-awesome-icon  slot="media" class="fa-2x" icon="language" />
     <option :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{locale.name}}
      </option>
  </f7-list-input>
  <f7-list-item :class="{'skeleton-text skeleton-effect-blink':chgpartialmask}" :header="$t('lgn.parx')" :title="ispartialon?$t('lgn.enbxs'):$t('lgn.disbxs')">
    <font-awesome-icon  slot="media" class="fa-2x" icon="head-side-cough-slash" />
    <f7-preloader v-if="chgpartialmask" slot="after"></f7-preloader>
    <f7-toggle v-else slot="after" @toggle:change="cambiamsks" :checked="ispartialon"></f7-toggle>
  </f7-list-item>
</f7-list>

    </f7-col>
  </f7-row>



    <f7-popup class="phoneverifier" :opened="phoneverifierOpened"  @popup:closed="phoneverifierOpened = false" >
      <phoneverifier v-if="phoneverifierOpened" @endphoneflow="phoneverifierOpened = false" ></phoneverifier>
    </f7-popup>
  </f7-page>
</template>
<script>
import localforage from 'localforage';
import { mapState} from 'vuex'
import { getSupportedLocales } from "@/util/i18n/supported-locales"
import { photomixin } from '../mixins/photomixin'
import { commonmixin } from '../mixins/common'
import phoneverifier from '../components/phoneverifier.vue';

export default {
  mixins: [photomixin, commonmixin],
  components: {phoneverifier},
  data() {
    return {
      locales: getSupportedLocales(),
      savingaccnt: false,
      mybadges:[],
      loadingStats: true,
      duracion:0,
      observx:0,
      phoneverifierOpened: false,
      canshare: !!navigator.share,
      reloadxing:false,
      chgpartialmask:false
    }
  },
  computed:{
    ...mapState(['user','core_badges','forceGetMyBadges','providers_dicc']),
    doIhavePhone(){
      return this.user.providerData?this.user.providerData.find(prov=> prov.providerId=='phone'):null;
    }
  },
  created(){
     localforage.getItem('backtoprofile').then((val)=>{
      if(val){
        localforage.setItem('backtoprofile', false);
        return this.$f7.dialog.alert(this.$t('night.pore'),()=>{
          this.invitefirend();
        })
      }
    }).catch(error=>{
      console.log('error getting back to profile');
    });
  },
  methods:{
    cambiamsks(event){
      this.chgpartialmask=true;
      console.log('cambiando partial mask a',event);
      let tosave=event?null : event;
      return this.$firebase.database().ref('privx/'+this.user.uid+'/pm').set(tosave).then(()=>{
          this.$store.commit('setprivxPM',tosave);
           this.chgpartialmask=false;
        }).catch(error=>{
          console.log(error);
           this.chgpartialmask=false;
          this.$f7.dialog.alert(error.message,error.code)
        })
    },
      setLocale(lalocale){
        console.log('set new locale ',lalocale);
        this.$i18n.locale = lalocale;
        localforage.setItem('locale', lalocale);
      },
    invitefirend(){
      let invite={
        title: 'MaskCount',
        text: this.$t('profile.become'),
        url: 'https://'+location.hostname+'?iv='+this.user.uid,
      };
      return navigator.share(invite).then(() => {
      console.log('Successful share');
        //fix for ios 14
        if(this.$f7.device.ios && parseInt(this.$f7.device.osVersion.split('.')[0])>13){
           return localforage.setItem('backtoprofile', true).then(()=> {
            return location.reload();
           });
        }
          let toastIcon = this.$f7.toast.show({
            icon: '<i class="fas fa-2x fa-head-side-mask"></i>',
            text:this.$t('profile.succes'),
            position: 'bottom',
            closeTimeout: 2000,
            destroyOnClose: true
          });
      }).catch((error) => {
         console.log('Error sharing', error,error.code,error.message);
        if(error.code ==0 && (this.$f7.device.ios && parseInt(this.$f7.device.osVersion.split('.')[0])>13)){
          this.reloadxing = true;
           return localforage.setItem('backtoprofile', true).then(()=> {
            return location.reload();
           }).catch(error=>{
             return location.reload();
           })
        }
        else{
          this.$f7.dialog.alert(error.message||error.code||error,this.$t('profile.error'))
        }
     
      
      });
    },
    openPhoneVerification(){
      this.phoneverifierOpened = true
    },
    getpredata(){

   let getsessions = this.$firebase.functions().httpsCallable('getsessions');
      let promesax = [ getsessions({ dev: this.$store.state.dev, onlystats: true }) ];
      if(this.forceGetMyBadges){
        promesax.push( this.$firebase.database().ref('privx/'+this.user.uid+'/badges').once('value') );
      }
      return Promise.all(promesax).then(multisnaps=>{
        let result = multisnaps[0];
         let respoesta=result.data;
          console.log('res',respoesta);
          if(respoesta.error){
            throw new Error(respoesta.error)
          }
        this.observx=respoesta.observx;
        this.duracion= this.printDuraxion(this.$moment.duration(respoesta.duracion*1000));
        this.loadingStats = false;
        //ahora badges
        if(multisnaps[1]){
          let snpsho = multisnaps[1];
          this.$store.commit('setWhatTo',{what:'forceGetMyBadges',to: false});
          if(snpsho.exists()){
            this.$store.commit('setBadges',snpsho.val());
          }
        }
        this.wearMybadges();
      }).catch(error=>{
         console.log(error);
         this.$f7.dialog.alert(error.message,error.code);
      });
    },
    wearMybadges(){
      let badges = this.user.privx.badges || {};
      let badgekeys = Object.keys(badges) || [];
      badgekeys.forEach(badgeid => {
        if(this.core_badges[badgeid]){
          this.mybadges.push(this.core_badges[badgeid]);
        }
      });
    },
    editDisplayName(){
      this.$f7.dialog.prompt(this.$t('profile.how'), (name)=> {
       if(!name || !name.trim()){
         return this.$f7.dialog.alert(this.$t('profile.privide'),this.$t('profile.empty'));
       }
       this.savingaccnt=true;
      return this.$firebase.auth().currentUser.updateProfile({
        displayName: name
      }).then(()=>{
        this.savingaccnt=false;
        this.$store.commit('setUserProp',{prop: 'displayName', val: name });
       }).catch(error=>{
         this.savingaccnt=false;
         this.$f7.dialog.alter(error.message, error.code)
       });
      },()=>{},this.user.displayName);
    }
  }
}
</script>
<style>
.userpanl img{
  width: 80px;
}
.provikons svg{
  margin-left: 4px;
}
#myimg-input{
    display: none;
}
</style>