<template>
  <f7-page @page:beforein="loadSessions()" name="userinfo">
     <f7-navbar :title="displayName" :subtitle="email" back-link="Back">
     </f7-navbar>
  <f7-block-title class="no-margin-bottom">{{$t('user.account')}}</f7-block-title>
<f7-list media-list class="no-margin-vertical">
  <f7-list-item  :title="displayName ||$t('user.notSet')" class="userprofx" :subtitle="email || $t('user.eNotSet')">
      <img slot="media" :src="photoURL || '../static/img/avatar.jpg'" />
      <div slot="text" class="provikons"> 
        <font-awesome-icon v-for="(providerx,ifx) in providers" :key="ifx" :class="`fa-lg text-color-${providers_dicc[providerx].col}`" :icon="[providers_dicc[providerx].type, providers_dicc[providerx].iko]" />
      </div>
  </f7-list-item>
</f7-list>
 <f7-list class="no-margin" simple-list>
      <f7-list-item>
        <span>{{dev?'Dev':'Beta'}} Access</span>
          <f7-preloader v-if="loadingSessions"></f7-preloader>
          <f7-toggle v-else :checked="dev?!!privx.dev:!!privx.beta" @toggle:change="chgPriv(dev?'dev':'beta',$event)" ></f7-toggle>
      </f7-list-item>
      </f7-list>
<f7-block-title>{{$t('user.stats')}}</f7-block-title>
<f7-list :class="{'skeleton-text skeleton-effect-blink': loadingSessions}" inset>
  <f7-list-item :title="$t('user.ses')" :after="observx || '0'"></f7-list-item>
  <f7-list-item :title="$t('user.tObs')" :after="duracion"></f7-list-item>
</f7-list> 
<f7-block-title>{{$t('user.ses')}}</f7-block-title>
<f7-block class="text-align-center" v-if="loadingSessions">
  <p>{{$t('user.loadSes')}}</p>
  <f7-preloader></f7-preloader>
</f7-block>
<f7-block v-if="!loadingSessions && !lessions.length">{{displayName || email}} {{$t('user.hasNot')}}</f7-block>
<f7-list media-list class="no-margin-top">
   <ul>
  <sessionli v-for="session in lessions"  :key="session.id" :session="session" ></sessionli>
  </ul>
</f7-list>
  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
import { commonmixin } from '../mixins/common'
import sessionli from '../components/sessionli.vue';

export default {
    mixins: [commonmixin],
     components:{
    sessionli
  },
  props:['displayName','displLower','email','creationTime','lastSignInTime','photoURL','providers','uid'],
  data() {
    return {
      loadingSessions: true,
      lessions:[],
      privx: {},
      duracion:0,
      observx:0
    }
  },
  computed:{
    ...mapState(['user','dev','providers_dicc'])
  },
  created(){
   console.log(this.displayName,this.displLower,this.email,this.creationTime,this.lastSignInTime,this.photoURL,this.providers,this.uid);
  },
  methods:{
    chgPriv(ambiente,que){
      this.$f7.progressbar.show();
      console.log('chgPriv',ambiente,que);
      let tosave = que?que:null;
      this.$firebase.database().ref('privx/'+this.uid+'/'+ambiente).set(tosave).then(()=>{
        this.$f7.progressbar.hide();
      }).catch(error=>{
         console.log(error)
      this.$f7.progressbar.hide();
      this.$f7.dialog.alert(error.message,error.code);
      })
    },
   loadSessions(){
     this.loadingSessions= true;
        let getsessions = this.$firebase.functions().httpsCallable('getsessions');
     let promisex = [
        getsessions({ dev: this.$store.state.dev, all: true, target: this.uid }),
       this.$firebase.database().ref('privx/'+this.uid).once('value')
     ];
     return Promise.all(promisex).then(presnapsno=>{
       let result = presnapsno[0];
       let respoesta=result.data;
          console.log('res',respoesta);
          if(respoesta.error){
            throw new Error(respoesta.error)
          }
          this.lessions = respoesta.innerArray;
           this.duracion= this.printDuraxion(this.$moment.duration(respoesta.duracion*1000));
          this.observx=respoesta.observx;
        //ahora privs
        this.privx = presnapsno[1].val() || {};
         this.loadingSessions= false
     }).catch(error=>{
       console.log(error)
       this.loadingSessions= false
      this.$f7.dialog.alert(error.message,error.code);
     });
   }
  }
}
</script>
<style>
.userprofx img{
  width: 80px;
}
.provikons svg{
  margin-left: 4px;
}
</style>