<template>
    <f7-popup class="demo-popup" :closeOnEscape="false" :closeByBackdropClick="false" :opened="popupOpened" @popup:opened="opopReady()" >
      <f7-page v-if="showswiper">
       <f7-swiper  ref="leswiper" :params="{grabCursor: true}" class="subretodo" pagination :navigation="$f7.device.desktop" scrollbar>
         <swiperone @gonext="nexter()"></swiperone>
        <swipertwo ></swipertwo>
        <f7-swiper-slide class="bakgrix">
          <f7-block class="titlinitation text-align-center">{{$t('onBoarding.ready')}}</f7-block>
          <f7-block class="margin-bottom-half margin-top text-align-center">{{$t('onBoarding.read')}}</f7-block>
          <f7-block class="no-margin-bottom margin-top-half">
            <f7-row >
              <f7-col class="lewarning" width="100" medium="60">{{$i18n.locale!='en'?$t('updt.reaua'):''}}</f7-col>
               <f7-col width="100" medium="40">
                 <f7-list class="no-margin-vertical langsel">
                  <f7-list-input
                    type="select"
                    defaultValue="EN"
                    :placeholder="$t('profile.please')"
                    :value="$i18n.locale"
                    @input="setLocale($event.target.value)"
                  >
                  <font-awesome-icon  slot="media" class="fa-2x" icon="language" />
                    <option :value="locale.code" v-for="locale in locales" :key="locale.code">
                        {{locale.name}}
                      </option>
                  </f7-list-input>
                </f7-list>
               </f7-col>
            </f7-row>
          </f7-block>
           
          <f7-block v-html="$t('common.termsofusep')" class="minaltoscroll inset no-margin-vertical">
          </f7-block>
           <f7-list class="margin-bottom-half margin-top" inset>
            <f7-list-item checkbox :checked="acceptedTimeUA" @change="acceptedTimeUA = $event.target.checked" :title="$t('onBoarding.accept')"></f7-list-item>
          </f7-list>

          <f7-block v-if="acceptedTimeUA" class="no-margin-vertical">
              <f7-block class="no-margin-top text-align-center margin-bottom-half">{{$t('onBoarding.you')}}</f7-block>
            <f7-button :class="{disabled: saving}" @click="saveAndGo()" large fill raised><f7-preloader color="white" v-if="saving"></f7-preloader><span v-else>{{$t('onBoarding.save')}}</span></f7-button>
          </f7-block>
        </f7-swiper-slide>
      </f7-swiper>
      </f7-page>
    </f7-popup>
</template>
<script>
import swiperone from '../components/swiperone.vue';
import swipertwo from '../components/swipertwo.vue';
import { getSupportedLocales } from "@/util/i18n/supported-locales"
import localforage from 'localforage';

export default {
  components:{swiperone,swipertwo},
  props:['user'],
    data() {
      return {
        locales: getSupportedLocales(),
        showswiper: false,
        popupOpened: false,
        acceptedTimeUA: false,
        saving:false
        }
    },
    mounted() {
      console.log('montando onboarding', this.user)
      setTimeout(() => this.computeOpened(), 400);
    },
    methods:{
       setLocale(lalocale){
        console.log('set new locale ',lalocale);
        this.$i18n.locale = lalocale;
        localforage.setItem('locale', lalocale);
      },
      nexter(){
        this.$refs.leswiper.swiper.slideNext();
      },
      gotoMenux(urlx){
        this.$store.commit('setWhatTo',{what:'activeTab', to: urlx})
        this.$f7.views.main.router.navigate(urlx)
        this.popupOpened = false
        this.showswiper = false
      },
      saveAndGo(){
        if(!this.acceptedTimeUA){
         return this.$f7.dialog.alert(this.$t('onBoarding.must'),this.$t('onBoarding.missing'))
        }
        this.$f7.progressbar.show();
        this.saving = true;
        let timetsm = this.$firebase.database.ServerValue.TIMESTAMP
        return this.$firebase.database().ref('privx/'+this.user.uid+'/ua').set(timetsm).then(()=>{
          console.log('saved ok',timetsm);
          this.acceptedTimeUA=this.$moment().valueOf();
          this.$store.commit('setSignaUA',this.acceptedTimeUA);
          this.$f7.progressbar.hide();
           this.saving = false;
          this.gotoMenux('/');
        }).catch(error=>{
          console.log(error);
          this.$f7.progressbar.hide();
          this.saving = false;
          this.$f7.dialog.alert(error.message,error.code)
        })

      },
      computeOpened(){
        if(!this.user.privx || !this.user.privx.ua){
          this.popupOpened = true;
        }
      },
     opopReady(){
      this.showswiper = true;
    }
  }
}
</script>
<style>
.lewarning{
  font-style: italic;
    color: #6f6f6f;
    text-align: center;
}
@media screen and (min-width: 960px){
  .lewarning{
    line-height: 40px;
  }
}
.subretodo{
  width:100%;
  height: 100%;
}

.minaltoscroll{
  height: calc(100% - 400px);
  overflow-y: auto;
  font-size: 13px;
  padding-top: 10px;
  background: #FFF!important;
}
.bakgrix{
  background:#f3f3f3;
  height: 100%;
}
.titlinitation{
  font-size: 17px;
  color: #129cab;
  font-weight: bold;
  margin-top:20px;
  margin-bottom: 10px!important;
}
.langsel li{
  background: #d4fbff;
}
.langsel .item-content, .langsel .item-media{
  height: 40px;
    min-height: 40px;
}
.langsel .item-inner{
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}
</style>