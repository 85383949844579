var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{attrs:{"name":"aroundme"},on:{"page:beforein":function($event){return _vm.preLetsGo()}}},[_c('f7-navbar',{attrs:{"back-link":_vm.tid!=='x'?'Back':null}},[(_vm.tid=='x')?_c('f7-nav-left',[_c('f7-link',{attrs:{"icon-material":"menu","panel-open":"left"}})],1):_vm._e(),_vm._v(" "),_c('f7-nav-title',{attrs:{"title":_vm.tid=='x'?_vm.$t('common.aroundme'):_vm.$t('neotem.map'),"subtitle":_vm.tid=='x'?_vm.$t('common.aroundmesub'):_vm.$t('neotem.maplox')}}),_vm._v(" "),(!_vm.attempting)?_c('f7-nav-right',[_c('f7-list',{staticClass:"no-margin",attrs:{"no-hairlines-md":""}},[_c('f7-list-input',{staticClass:"curpointer",attrs:{"type":"datepicker","placeholder":_vm.$t('adminLive.date'),"readonly":"","calendar-params":{openIn: 'auto',value: [new Date()], events: { from: !_vm.user.phoneNumber?_vm.$moment().subtract(3, 'd').toDate():new Date(2020, 8, 12), to: new Date() },  disabled: {from: new Date()}, closeOnSelect: true, on: {
        change: _vm.cambiafech
      }
 }}})],1)],1):_vm._e(),_vm._v(" "),_c('f7-subnavbar',[_c('f7-block',{staticClass:"padding-right-half"},[_vm._v("0%")]),_vm._v(" "),_c('f7-progressbar',{staticClass:"progrex",attrs:{"infinite":_vm.computing,"progress":100}}),_vm._v(" "),_c('f7-block',{staticClass:"padding-left-half"},[_vm._v("100%")])],1)],1),_vm._v(" "),_c('f7-block',{staticClass:"no-margin no-padding almaptop"},[_c('div',{staticClass:"maskmapactv al100"},[(_vm.attempting)?_c('f7-preloader'):[_c('p',{staticClass:"no-margin-top padding"},[_vm._v(_vm._s(_vm.$t('common.wantx')))]),_vm._v(" "),_c('f7-block',[_c('f7-button',{attrs:{"fill":"","raised":""},on:{"click":function($event){return _vm.geolocme()}}},[_vm._v(_vm._s(_vm.$t('common.load')))])],1)]],2)]),_vm._v(" "),_c('f7-block',{staticClass:"fixbott backgorisxx no-margin"},[(!_vm.lastinfoON)?_c('f7-row',[_c('f7-col',{staticClass:"text-align-center"},[_vm._v("\n     "+_vm._s(_vm.$t('lgn.clkcfdx'))+"\n    ")])],1):_c('f7-row',{staticClass:"text-align-center"},[_c('f7-col',[_c('font-awesome-icon',{staticClass:"text-color-red",attrs:{"icon":['fas', 'head-side-cough']}}),_vm._v(" "+_vm._s(_vm.toFixedNumber(_vm.infoboxselected.h.nom,1))+"% | \n      "),_c('font-awesome-icon',{staticClass:"text-color-yellow",attrs:{"icon":['fas', 'head-side-cough-slash']}}),_vm._v(" "+_vm._s(_vm.toFixedNumber(_vm.infoboxselected.h.par,1))+"% | \n      "),_c('font-awesome-icon',{staticClass:"text-color-primary",attrs:{"icon":['fas', 'head-side-mask']}}),_vm._v(" "+_vm._s(_vm.toFixedNumber(_vm.infoboxselected.h.p,1))+"%\n    ")],1)],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"padding-top fixcenter"},[_c('GChart',{attrs:{"type":"PieChart","data":[
      ['Type', 'Count'],
      ['Mask',    _vm.infoboxselected.i.m ],
      ['Partial',      _vm.infoboxselected.i.p],
      ['No Mask',  _vm.infoboxselected.i.n]
    ],"options":_vm.chartOptionsmin}})],1),_vm._v(" "),_c('f7-col',{staticClass:"altofixer fixcenter"},[_c('GChart',{attrs:{"type":"PieChart","data":[
      ['Type', 'Count'],
      ['Mask',    _vm.infoboxselected.k.m ],
      ['Partial',      _vm.infoboxselected.k.p],
      ['No Mask',  _vm.infoboxselected.k.n]
    ],"options":_vm.chartOptions}})],1),_vm._v(" "),_c('f7-col',{staticClass:"padding-top fixcenter"},[_c('GChart',{attrs:{"type":"PieChart","data":[
      ['Type', 'Count'],
      ['Mask',    _vm.infoboxselected.o.m ],
      ['Partial',      _vm.infoboxselected.o.p],
      ['No Mask',  _vm.infoboxselected.o.n]
    ],"options":_vm.chartOptionsmin}})],1)],1),_vm._v(" "),_c('f7-row',{staticClass:"text-align-center"},[_c('f7-col',[_vm._v(_vm._s(_vm.$t('updt.indoors'))+"="+_vm._s(_vm.infoboxselected.hi.t || '--'))]),_vm._v(" "),_c('f7-col',[_vm._v(_vm._s(_vm.$t('lgn.overall'))+"="+_vm._s(_vm.infoboxselected.h.t || '--'))]),_vm._v(" "),_c('f7-col',[_vm._v(_vm._s(_vm.$t('updt.outdoors'))+"="+_vm._s(_vm.infoboxselected.ho.t || '--'))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }