<template>
  <f7-page @page:beforein="encenderObservablesCodigos()" @page:beforeout="apagarObserv()" name="team_codes">
     <f7-navbar :title="$t('updt.cod')" back-link="Back">
     </f7-navbar>
     <f7-list accordion-list>
        <f7-list-item id="creacodexrs" accordion-item :title="$t('vides.gennwo')">
           <f7-accordion-content>
          <f7-list inset class="margin-top no-margin-bottom">
            <f7-list-item divider :title="$t('vides.selcoty')"></f7-list-item>
            <f7-list-item radio @change="codetypetogen = $event.target.value" value="member" name="codetype" :checked="codetypetogen === 'member'" title="Member"></f7-list-item>
            <f7-list-item radio @change="codetypetogen = $event.target.value" value="admin" name="codetype" :checked="codetypetogen === 'admin'" title="Admin"></f7-list-item>
              <f7-list-input
              :label="$t('vides.maxn')"
              type="select"
              defaultValue="1"
              :value="codenumbs"
              @input="codenumbs = $event.target.value"
              placeholder="Please choose..."
            >
             <option v-for="seatn in nseats" :value="seatn" :key="seatn">{{seatn}}</option>
          </f7-list-input>
          </f7-list>
      <f7-block class="no-margin-top">
          <f7-button @click="newCodeGenex()" :class="{disabled:geningcode}" color="primary" large raised fill><f7-preloader v-if="geningcode"></f7-preloader> <span v-else>{{$t('vides.genco')}} <font-awesome-icon icon="plus"/></span></f7-button>
      </f7-block>
           </f7-accordion-content>
        </f7-list-item>
     </f7-list>
        <f7-list media-list >
        <f7-list-item divider :title="$t('vides.reds')"></f7-list-item>
        <f7-list-item v-if="loadingCodes" :title="$t('login.loading')"> <f7-preloader slot="after"></f7-preloader> </f7-list-item>
       <f7-list-item
        v-for="code in codigos" 
        :key="code.k" 
        :title="code.k" 
        :header="$t('vides.type')+': '+code.tp"
        :footer="$t('vides.isson')+' '+$moment.unix(code.iss).format('LLL')"
        swipeout
        @swipeout:delete="remCode(code)"	
        >
        <f7-button slot="after" @click="sendInviteCode(code.k)" fill large raised v-if="canshare"><font-awesome-icon icon="share-alt"/></f7-button>
        <div class="marcero" slot="media">{{code.num - (code.used || 0)}}<small>/</small>{{code.num}}<br/><span class="peq">{{$t('vides.aval')}}</span></div>
         <f7-swipeout-actions right>
          <f7-swipeout-button delete :confirm-text="$t('vides.confdel')">{{$t('observation.delete')}}</f7-swipeout-button>
        </f7-swipeout-actions>
        </f7-list-item>
        <f7-list-item v-if="!loadingCodes && !Object.keys(codigos).length" title="No codes available"></f7-list-item>
     </f7-list>

  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
export default {
    props:['id'],
  data() {
    return {
      geningcode: false,
      loadingCodes: true,
      codigos: {},
      codetypetogen: 'member',
      codenumbs: 1,
      nseats: [...Array(999).keys()].map(i=>i + 1),
      canshare: !!navigator.share,
      codesRef: null
    }
  },
  computed:{
    ...mapState(['user','dev']),
    adminCodes(){
      return Object.values(this.codigos).filter(unco=>unco.tp=='admin');
    },
    memberCodes(){
      return Object.values(this.codigos).filter(unco=>unco.tp=='member');
    }
  },
  methods:{
    apagarObserv(){
      if(this.codesRef){
        this.codesRef.off();
      }
    },
    encenderObservablesCodigos(){
      console.log('encendiendo observable codigos')
      this.codesRef = this.$firebase.database().ref('team-codes').orderByChild('tid').equalTo(this.id);
      this.codesRef.on('child_added', (data)=> {
        console.log('agregando',data.key,data.val())
        let neocode={};
        neocode[data.key]=data.val();
        this.codigos = Object.assign({},this.codigos,neocode);
      });
      this.codesRef.on('child_changed', (data)=> {
        let neocode = {};
        neocode[data.key]=Object.assign({}, (this.codigos[data.key]?this.codigos[data.key] : {}),data.val());
        this.codigos = Object.assign({},this.codigos,neocode);
        console.log('editando',data.key,data.val())
      });
      this.codesRef.on('child_removed', (data)=> {
        let clonado = Object.assign({}, this.codigos);
        delete clonado[data.key]
        console.log('eliminando',data.key,data.val())
        this.codigos = Object.assign({},clonado);
      });
      this.codesRef.on('value', (snapshot)=> {
        console.log('todo ya pasó');
        this.loadingCodes=false;
      });
    },
    removemember(memuid){
      this.$f7.dialog.preloader('Inactivating member...')
    let removeFromTeam = this.$firebase.functions().httpsCallable('removeFromTeam');
      return removeFromTeam({teamid: this.id, memberuid: memuid, dev: this.dev}).then(snw=>{
        let datax=snw.data;
        console.log('llego',snw,datax);
        if(datax.error){
          throw new Error(datax.error)
        }
        let indiexist = this.miembros.findIndex(unmem => unmem.map.uid==memuid);
        if(indiexist!=-1){
          let neoda={};
          neoda.inactive={wh: this.$moment().unix(), rs: 'removed', by: this.user.uid};
          let toreplace=Object.assign({},this.miembros[indiexist],neoda);
            this.miembros.splice(indiexist,1,toreplace);
        }
        console.log('removed OK');
        this.$f7.dialog.close();
      }).catch(error=>{
        this.$f7.dialog.close();
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })
    },
    exitTeam(){
      return this.$f7.dialog.confirm('Are your sure you want to leave this team?','Please confirm',()=>{
        console.log('Acepto!');
       this.$f7.dialog.preloader('Leaving team...');
        let leaveteam = this.$firebase.functions().httpsCallable('leaveteam');
      return leaveteam({teamid: this.id, dev: this.dev}).then(snw=>{
        let datax=snw.data;
        console.log('llego',snw,datax);
        if(datax.error){
          throw new Error(datax.error)
        }
        //salir del equipo datax
        this.$store.commit('delTeamfromPriv',datax.teamid)
        this.$f7.dialog.close();
        this.$f7.views.main.router.back();
      }).catch(error=>{
        this.$f7.dialog.close();
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })
      });
    },
     sendInviteCode(elcode){
      let invite={
        title: 'MaskCount Teams',
        text: this.$t('updt.invt',{ code: elcode}),
        url: 'https://'+location.hostname,
      };
      navigator.share(invite).then(() => {
      console.log('Successful share');
         let toastIcon = this.$f7.toast.show({
          icon: '<i class="fas fa-2x fa-head-side-mask"></i>',
          text:this.$t('profile.succes'),
          position: 'bottom',
          closeTimeout: 2000,
          destroyOnClose: true
        });
      }).catch((error) => {
      console.log('Error sharing', error);
      this.$f7.dialog.alert(error.message||error.code||error,this.$t('profile.error'))
      });
    },
    remCode(codex){
      console.log('deletginc doe',codex)
      return this.$firebase.database().ref('team-codes/'+codex.k).set(null).then(()=>{
        console.log('deleted ok');
        let codigos = Object.assign({},this.codigos);
        delete codigos[codex.k];
        this.codigos = Object.assign({},codigos)
      }).catch(error=>{
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })
    },
    newCodeGenex(){
      this.geningcode = true;
      console.log('generating for',this.codenumbs)
      let gencodex = this.$firebase.functions().httpsCallable('gencodex');
      return gencodex({teamid: this.id, codetype: this.codetypetogen, num: this.codenumbs, dev: this.dev}).then(snw=>{
        let datax=snw.data;
         if(datax.error){
          throw new Error(datax.error)
        }
        let lne={};
        lne[datax.k]=datax;
        this.codigos = Object.assign({},this.codigos,lne);
        console.log('llego',snw,datax);
        this.geningcode = false;
        this.codenumbs = 1;
        this.$f7.accordion.close('#creacodexrs') 
      }).catch(error=>{
        this.geningcode = false;
        console.log(error);
        this.codenumbs = 1;
        this.$f7.dialog.alert(error.message,error.code);
      })
    },
    editname(){
      if(this.iManage){
        this.$f7.dialog.prompt('Indicate the new name','New name', (name)=> {
        console.log('el equipo es ',name)
        let elnombre = name || '';
        let sinespacios = name.trim();
        if(!sinespacios){
          return this.$f7.dialog.alert('You must provide a name','Empty field');
        }
        return this.$firebase.database().ref('teams/'+this.id+'/name').set(sinespacios).then(()=>{
          console.log('log saved')
           this.localname = sinespacios;
          let saverp={};
          saverp[this.id] = {
            id: this.id,
            name: sinespacios,
            createdAt: this.createdAt
          };
          this.$store.commit('mergeObjTo',{what:'teams',to:  saverp});
        }).catch(error=>{
          console.log(error);
          this.$f7.dialog.alert(error.message,error.code);
        })
      },()=>{
        console.log('nada')
      },this.name);
      }
    }
  }
}
</script>
<style>

.peq{
  font-size: 11px;
}
.marcero{
  margin: 0 auto;
  line-height: 13px;
  font-size: 23px;
  margin-top: 6px;
  text-align: center;
}
</style>