<template>
  <f7-page  name="home">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :title="$t('common.neosession')" :subtitle="$t('common.maskCountSub')"></f7-nav-title>
    </f7-navbar>
    <template v-if="!hayGeoLocation"
    >
  <f7-block class="nogeodata">
  <span class="fa-stack fa-2x">
    <i class="fas fa-location-arrow fa-stack-1x"></i>
    <i class="fas fa-ban fa-stack-2x" style="color:Tomato"></i>
  </span>
  <p class="no-margin-top" v-html="$t('common.ohNo')">
  </p>
  </f7-block>
  <f7-block class="text-align-center">{{$t('common.thank')}}</f7-block>
</template>
<template v-else>
<f7-row>
    <f7-col width="100" medium="75">
      <f7-block class="text-align-center margin-vertical-half">
        <p>{{$t('common.ready')}}<br/><span class="bold">{{$t('common.device')}}</span></p>
        </f7-block>
        <f7-block>
          <p v-html="$t('common.alora')"></p>
        </f7-block>
        <f7-block class="no-margin-bottom">
        <f7-button @click="startSession()" large fill raised><f7-preloader color="white" v-if="startingSession"></f7-preloader><span v-else>{{$t('common.start')}}</span></f7-button>
      </f7-block>
    </f7-col>
    <f7-col width="100" medium="25">
      <f7-block class="text-align-center">
        <p class="text-align-left fontexplan">{{$t('updt.panimexpp')}}</p>
        <img v-if="ispartialon" src="../static/img/partialmask-on.gif" class="swipgif"/>
        <img v-else src="../static/img/partialmask-off.gif" class="swipgif"/>
        <p class="text-align-left fontexplan"> {{$t('updt.saffirst')}}</p>
      </f7-block>
    </f7-col>
  </f7-row>


  </template>
  </f7-page>
</template>
<script>
import localforage from 'localforage';
import { mapState} from 'vuex';
import { commonmixin } from '../mixins/common';

export default {
  mixins: [ commonmixin],
  data() {
    return {
      hayGeoLocation: true,
      startingSession: false
    }
  },
  computed:{
    ...mapState(['user','geoperm','dev','alreadyAsked'])
  },
  created(){
    if('geolocation' in navigator) {
      /* geolocation is available */
      this.hayGeoLocation = true
    } else {
      /* geolocation IS NOT available */
        this.hayGeoLocation = false
    }

    //trigger instal prompt
    this.installPromtp();
  },
  methods:{
    installPromtp(){
      if (!this.$f7.device.desktop && !this.$f7.device.standalone && !this.alreadyAsked) {
      // if (true) {
        console.log('It is android device');
        let toastIcon = this.$f7.toast.show({
          text: this.$t('nov.adthmone')+' '+(this.$f7.device.android?'<i class="fas fa-ellipsis-v"></i>':'<i class="fas fa-external-link-alt"></i>')+' '+this.$t('nov.addhomtwo'),
          closeButton: true,
          closeTimeout: 15000,
          closeButtonText: 'OK'
        });
        this.$store.commit('setWhatTo',{what:'alreadyAsked',to: true});
      }
    },
    geosuccess(position){
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          wh: this.$moment().unix()
        };
        this.$store.commit('setWhatTo',{what:'lastPos', to: pos});
        let neo_startObservation = this.$firebase.functions().httpsCallable('neo_startObservation');
      return  neo_startObservation({lat: position.coords.latitude, lng: position.coords.longitude, dev: this.dev }).then((result)=> {
          let respoesta=result.data;
          console.log('res',respoesta);
          if(respoesta.error){
            throw new Error(respoesta.error)
          }
          let saver={};
          saver[respoesta.id]=respoesta;
          this.$store.commit('mergeObjTo',{what:'sessions',to:saver});
           this.$store.commit('setWhatTo',{what:'preloadedObservations', to: []});
           this.startingSession = false;
          this.$f7.views.main.router.navigate('/session/'+respoesta.id);
          localforage.setItem('geoperm_'+this.user.uid, true);
        }).catch(error=>{
          console.log(error);
            this.$f7.progressbar.hide();
            this.startingSession = false;
            if(error.message=='ACTIVE_EXISTS'){
              this.$f7.dialog.alert(this.$t('common.active'),this.$t('common.conflict'),()=>{
                this.$store.commit('setWhatTo',{what:'activeTab', to: '/sessions/'});
                this.$f7.views.main.router.navigate('/sessions/');
              });
            }
            else{
              this.$f7.dialog.alert(error.message,error.code);
            }
        })
      },
      geoerror(err){
        this.$f7.progressbar.hide();
        this.startingSession = false;
        console.warn('ERROR(' + err.code + '): ' + err.message);
        this.$f7.dialog.alert(this.$t('common.geo'), err.message);
      },
    startSession(){
        this.$f7.progressbar.show();
        this.startingSession = true;
      return navigator.geolocation.getCurrentPosition(this.geosuccess, this.geoerror,{ maximumAge: 30000, timeout: 15000 });
    }
  }
}
</script>
<style>
 .nogeodata{
  background: #ffe9e9;
    margin: 0px!important;
    padding: 20px;
    text-align: center;
    color: #610101ee;
}
.swipgif{
  width: 80%;
}
.fontexplan{
  font-size:13px;
  color: #4a4a4a;
    font-style: italic;
}
</style>