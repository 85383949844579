<template>
  <f7-page name="admin">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :title="$t('common.admin')" :subtitle="$t('common.adminsub')"></f7-nav-title>
    </f7-navbar>
    <f7-block-title>Admin Menu</f7-block-title>
   <f7-list media-list>
     <f7-list-item
     :title="$t('common.maps')"
     link="/admin/core/"
     :subtitle="$t('common.data')"
     ><font-awesome-icon icon="globe-americas" class="fa-2x" slot="after" /></f7-list-item>
      <f7-list-item
      v-if="myprivx.admin_users"
     :title="$t('common.users')"
     link="/admin/users/"
     :subtitle="$t('common.manage')"
     ><font-awesome-icon icon="user-cog" class="fa-2x" slot="after" /></f7-list-item>
     <f7-list-item
      v-if="myprivx.admin_users"
     :title="$t('updt.leteam')"
     link="/admin/teams/"
     :subtitle="$t('updt.crmgn')"
     ><font-awesome-icon icon="users" class="fa-2x" slot="after" /></f7-list-item>
      <f7-list-item
      v-if="myprivx.admin_export"
     :title="$t('common.export')"
     link="/admin/exports/0/"
     :subtitle="$t('common.download')"
     ><font-awesome-icon icon="cloud-download-alt" class="fa-2x" slot="after" /></f7-list-item>
      <f7-list-item
      v-if="myprivx.admin_users"
     title="Message users"
     link="/admin/messenger/"
     subtitle="Send email to your users"
     ><font-awesome-icon icon="envelope" class="fa-2x" slot="after" /></f7-list-item>
   </f7-list>


  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
export default {
  data() {
    return {
    
    }
  },
  computed:{
    ...mapState(['user','dev']),
    myprivx(){
      return this.user.privx || {}
    }
  },
  created(){
   
  },
  methods:{
  
  }
}
</script>
<style>

</style>