export const fireBaseConfigProd = {
  apiKey: "AIzaSyCXBEK9Im5wRvo4xkIJc2O3D6KgE2CVBuY",
  authDomain: "app.maskcount.com",
  databaseURL: "https://maskcount-2020.firebaseio.com",
  projectId: "maskcount-2020",
  storageBucket: "maskcount-2020.appspot.com",
  messagingSenderId: "412429105952",
  appId: "1:412429105952:web:78492830100e79e958f545",
  measurementId: "G-VK57D83HLE"
}
export const devConfig = {
  apiKey: "AIzaSyCXBEK9Im5wRvo4xkIJc2O3D6KgE2CVBuY",
  authDomain: "app.maskcount.com",
  databaseURL: "https://maskcount-2020-dev.firebaseio.com",
  projectId: "maskcount-2020",
  storageBucket: "maskcount-2020-dev",
  messagingSenderId: "412429105952",
  appId: "1:412429105952:web:78492830100e79e958f545",
  measurementId: "G-VK57D83HLE"
}