<template>
  <f7-page @page:beforein="preLetsGo()" @page:beforeout="apagarGeoQuerries()" name="core">
     <f7-navbar :title="$t('adminLive.title')" :subtitle="$t('adminLive.subtitle')" back-link="Back">
       <f7-nav-right v-if="!attempting">
         <f7-list no-hairlines-md class="no-margin">
       <f7-list-input
       class="curpointer"
          type="datepicker"
          :placeholder="$t('adminLive.date')"
          readonly
          :calendar-params="{openIn: 'auto',value: [new Date()], disabled: { from: new Date()}, closeOnSelect: true, on: {
          change: cambiafech
        }
   }"
        >
      </f7-list-input>
         </f7-list>
       </f7-nav-right>
     </f7-navbar>
   <f7-block class="no-margin no-padding">
      <div class="maskmapactv">
        <f7-preloader v-if="attempting"></f7-preloader>
      </div>
   </f7-block>

  </f7-page>
</template>
<script>
import loadGoogleMapsApi from "load-google-maps-api";
import localforage from 'localforage';
import { commonmixin } from '../mixins/common'
import { mapState} from 'vuex'
export default {
   mixins: [ commonmixin],
  data() {
    return {
       googleMaps: null,
       lemapa: null,
       attempting: true,
       startPoint:{
         lat: 39.7798004,
         lng: -86.1800271
       },
      prevcenter_lat: null,
      prevcenter_long: null,
      prevradius: null,
      coolMapData: [],
      coolmap: null,
      heatMapData: [],
      heatmap: null,
       initialDateRoute: this.$moment().format('YYYYMMDD'),
       calendarDate: this.$moment().format('YYYYMMDD'),
       timeouter: null,
       firstLoadReady: false,
       coreDataHolder:{},
      //validar aqui abajo
    }
  },
  computed:{
    ...mapState(['user','lastPos','dev']),

  },
  created(){
   
  },
  methods:{
    cambiafech(calendar, value){
      if(this.firstLoadReady){
        console.log('cambiando fecha',value[0])
         this.calendarDate = this.$moment(value[0]).format('YYYYMMDD');
          this.reinitiQuerry();
      }
      
    },
    modulardorQuerries(){
      console.log('modulando')
      if(this.timeouter){
        clearTimeout(this.timeouter);
      }
      this.timeouter = setTimeout(()=>{
        this.reinitiQuerry();
         }, 1500);
    },
    apagarGeoQuerries(){
      if(this.geoQuery){
        this.geoQuery.cancel();
      }
      if(this.timeouter){
        clearTimeout(this.timeouter);
      }
        this.$f7.progressbar.hide();
    },
    preLetsGo(){
      //ver si teiene navigator geoloc
       if('geolocation' in navigator) {
        /* geolocation is available */
        this.letsgo();
      } else {
        /* geolocation IS NOT available */
          this.loadMap();
      }
    },
    letsgo(){
      //ver si hay last post
      if(this.lastPos){
        //tuvo geoloc, go
          this.geolocme();
      }
      else{
        console.log('viendo si hay geoperm')
        localforage.getItem('geoperm_'+this.user.uid).then((geoperm) => {
        if(geoperm){
          this.geolocme();
        }
        else{
          this.attempting = false;
        }
      }).catch( (err)=> {
        this.attempting = false;
        console.log(err, 'error reading localforage')
      });
      }
    },
    geolocme(){
      //start gelocloc
      this.$f7.progressbar.show();
      this.attempting = true;
      return navigator.geolocation.getCurrentPosition(this.geosuccess, this.geoerror,{ maximumAge: 30000, timeout: 15000 });
    },
    geoerror(err){
      this.$f7.progressbar.hide();
      this.attempting = false;
      console.warn('ERROR(' + err.code + '): ' + err.message);
      this.loadMap();
      this.$f7.dialog.alert(this.$t('adminLive.failed'), err.message);
    },
    geosuccess(position){
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          wh: this.$moment().unix()
        };
        this.startPoint = Object.assign({}, pos);
        this.$store.commit('setWhatTo',{what:'lastPos', to: pos});
         localforage.setItem('geoperm_'+this.user.uid, true);
        this.loadMap();
      },
      loadMap(){
      //primero crear el mapa y centrarme
      loadGoogleMapsApi({key:this.$store.state.apimaps,libraries:['geometry','visualization']}).then((googleMaps)=> {
          this.$f7.progressbar.hide();
          this.attempting = false;
          this.googleMaps=googleMaps;
          this.lemapa= new this.googleMaps.Map(document.querySelector('.maskmapactv'), {
             center: {lat: this.startPoint.lat, lng: this.startPoint.lng},
              zoom: 17,
              streetViewControl: false,
              mapTypeControl: false,
               fullscreenControl: false
            });
            //inicializar heatmasp
              this.coolmap = new google.maps.visualization.HeatmapLayer({
                data: this.coolMapData,
                gradient: [
                  'rgba(0, 247, 255,0)',
                  'rgb(0, 247, 255)',
                  'rgb(0, 229, 255)',
                  'rgb(0, 217, 255)',
                  'rgb(0, 200, 255)',
                  'rgb(0, 183, 255)',
                  'rgb(0, 170, 255)',
                  'rgb(0, 153, 255)',
                  'rgb(0, 136, 255)',
                  'rgb(0, 123, 255)',
                  'rgb(0, 106, 255)']
              });
              this.coolmap.setMap(this.lemapa);
              this.heatmap = new google.maps.visualization.HeatmapLayer({
                data: this.heatMapData,
                gradient: [
                  'rgba(255, 140, 0, 0)',
                  'rgb(255, 140, 0)',
                  'rgb(255, 124, 0)',
                  'rgb(255, 109, 0)',
                  'rgb(255, 93, 0)',
                  'rgb(255, 78, 0)',
                  'rgb(255, 62, 0)',
                  'rgb(255, 47, 0)',
                  'rgb(255, 31, 0)',
                  'rgb(255, 16, 0)',
                  'rgb(255, 0, 0)']
              });
              this.heatmap.setMap(this.lemapa);

             this.$f7.progressbar.hide();

            this.lemapa.addListener('idle', ()=> {
                console.log('mapa setted')
                let bounds = this.lemapa.getBounds();
                let centrox = this.lemapa.getCenter();
                if (bounds && centrox) {
                  let ne = bounds.getNorthEast();
                  // Calculate radius (in meters).
                  let radio_en_mts = google.maps.geometry.spherical.computeDistanceBetween(centrox, ne);
                  if(this.prevcenter_lat!=centrox.lat() || this.prevcenter_long!=centrox.lng() || this.prevradius!=radio_en_mts){
                      this.prevcenter_lat=centrox.lat();
                      this.prevradius=radio_en_mts;
                      this.prevcenter_long=centrox.lng();
                        this.$f7.progressbar.show();
                      this.modulardorQuerries();
                  }
                }
              });
          }).catch((error)=> {
           this.$f7.dialog.alert(error.message,error.code || error.name);
            console.log(error)
          });
      },
      reinitiQuerry(){
        this.firstLoadReady = true;
        let lat =this.prevcenter_lat;
        let lng = this.prevcenter_long;
        let radio_en_mts =  this.prevradius;
        console.log('iniciando nuevo query con centro y radio',lat,lng,radio_en_mts);
        let radiotoquery = this.toFixedNumber((radio_en_mts/1000),3,10);
        console.log('radio en KM ',radiotoquery);
        if(!this.geoFireInstance || (this.initialDateRoute!=this.calendarDate)){
          this.initialDateRoute= this.calendarDate;
          console.log('querying ', this.initialDateRoute)
          if(this.geoQuery){
            this.geoQuery.cancel();
          }
          this.coreDataHolder=Object.assign({});
          this.geoQuery = null;
          this.geoFireInstance = new this.$geofire.GeoFire(this.$firebase.database().ref('lox/'+this.initialDateRoute));
        }
        if(!this.geoQuery){
          console.log('Encendiendo observables')
          this.geoQuery = this.geoFireInstance.query({
            center: [lat, lng],
            radius: radiotoquery
          });
          this.geoQuery.on("key_entered", (key, meta, location, distance)=> {
           // console.log('Nuevo punto', key, meta, location)
            this.coreDataHolder[key]={i:key, l:location,z:meta, v:true};
          });
          this.geoQuery.on("key_exited", (key, meta, location, distance) => {
          // console.log('Punto saliendo', key, meta, location);
           this.coreDataHolder[key]={i:key, l:location,z:meta, v:false};
          });
          this.geoQuery.on("ready", ()=> {
            this.coolMapData=[];
            this.heatMapData=[];
            console.log('readyx')
              this.$f7.progressbar.hide();
              console.log(this.coreDataHolder);
              let toos = Object.values(this.coreDataHolder);
              toos.forEach(undot => {
                if(undot.v){
                  if(undot.z.m=='m'){
                    this.coolMapData.push({location: new google.maps.LatLng(undot.l[0],undot.l[1]), weight: undot.z.n});
                  }
                  else{
                  this.heatMapData.push({location: new google.maps.LatLng(undot.l[0],undot.l[1]), weight: undot.z.n});
                  }
                }
              });
             this.coolmap.setData(this.coolMapData);
             this.heatmap.setData(this.heatMapData);

             let cuantosvisibles = toos.filter(x=> x.v).length;
             console.log('VISIBLES',cuantosvisibles);
          })
        }
        else{
          console.log('actualizando query')
          this.geoQuery.updateCriteria({
            center: [lat, lng],
            radius: radiotoquery
          });
        }
      }
  }
}
</script>
<style>
.maskmapactv{
  height: calc(100vH - 56px);
  text-align: center;
  background: #e4e4e4;
}
.maskmapactv .preloader{
  margin-top: 150px;
}
.curpointer, .curpointer input{
  cursor: pointer!important;
  text-align: center!important;
}
</style>