
export const commonmixin = {
  data(){
    return {
        
    }
  },
  computed:{
    ispartialon(){
      return !(this.$store.state.user.privx && this.$store.state.user.privx.pm);
    }
  },
  methods:{
    getvaluex(lobjex){
      if(!lobjex){
        return [];
      }
   let suval=[ { value: lobjex.m || 0, color: '#3fafbd' }];
   if(lobjex.p){
     suval.push({ value: lobjex.p, color: '#ffcc00' })
   }
   return suval;
 },
    printDuraxion(duration){
      let horas = duration.hours()<10?`0${duration.hours()}`:duration.hours();
      let minutos = duration.minutes()<10?`0${duration.minutes()}`:duration.minutes();
      let segundos = duration.seconds()<10?`0${duration.seconds()}`:duration.seconds();
      return `${horas}:${minutos}:${segundos}`
    },
    toFixedNumber(num, digits, base){
      let pow = Math.pow(base||10, digits);
      return Math.round(num*pow) / pow;
    }
  }
 
}