<template>
  <f7-page 
   infinite
      :infinite-distance="50"
      :infinite-preloader="showPreloader"
      @infinite="llamandoInfinito"
      ptr 
      @ptr:refresh="llamandoPullreferh"
      @page:beforein="shouldIcall()"
  name="team">
     <f7-navbar back-link="Back">
       <f7-nav-title>{{localname}} <f7-badge v-if="localexp" color="blue">PRO</f7-badge></f7-nav-title>
       <f7-nav-right  v-if="iManage">
         <f7-block><f7-link @click="editname()"><font-awesome-icon icon="edit"/></f7-link></f7-block>
       </f7-nav-right>
     </f7-navbar>
  <f7-block-title>Menu</f7-block-title>
   <f7-list media-list class="no-margin-bottom">
     <f7-list-item
     v-if="iManage"
     :title="$t('updt.cod')"
     :link="'/team/codes/'+id+'/'"
     :footer="$t('updt.codbu')"
     ><font-awesome-icon icon="code" class="fa-2x" slot="after" /></f7-list-item>
      <f7-list-item
     :title="$t('updt.memx')"
      :link="'/team/members/'+id+'/'"
     :footer="$t('updt.memxsu')"
     ><font-awesome-icon icon="users-cog" class="fa-2x" slot="after" /></f7-list-item>
         <f7-list-item
     :title="$t('neotem.map')"
      :link="'/aroundme/'+id+'/'"
     :footer="$t('neotem.maplox')"
     ><font-awesome-icon icon="map-marked-alt" class="fa-2x" slot="after" /></f7-list-item>
      <f7-list-item
       v-if="(iAdminTeam && exp) || iPeterExports"
       class="expdisbl"
       :class="{expenabl:localexp}"
     :title="$t('updt.exp')"
     :header="iPeterExports?(localexp?'Export enabled':'Export not enabled'): null"
     :link="'/admin/exports/'+id+'/'"
     :footer="$t('updt.expsux')"
     ><font-awesome-icon icon="cloud-download-alt" class="fa-2x" slot="after" /></f7-list-item>
   </f7-list>
   <f7-list class="no-margin-top" v-if="iPeterExports" simple-list>
      <f7-list-item divider title="Configure export capabilities"></f7-list-item>
      <f7-list-item>
        <span>Team data export</span>
        <f7-toggle :checked="localexp" @toggle:change="cambiaexport"></f7-toggle>
      </f7-list-item>
    </f7-list>

     <f7-block v-if="imMember"><f7-button @click="exitTeam()" color="red">{{$t('vides.levtm')}}</f7-button></f7-block>
   <f7-list media-list class="margin-top teamseslist">
    <f7-list-item :title="$t('vides.latses')" divider></f7-list-item>
    <ul>
      <sessionli v-for="session in contentToList" :teamid="id" :nodetails="!localexp" :byx="(miembros[session.u]?miembros[session.u].map.displayName:session.u)" :imgsrc="miembros[session.u]?miembros[session.u].map.photoURL:'../static/img/avatar.jpg'" :key="session.id" :session="session" ></sessionli>
    </ul>
</f7-list>
<f7-block v-if="firstloadDone && !contentToList.length">
 {{$t('updt.nosed')}}
</f7-block>
  </f7-page>
</template>
<script>
import { commonmixin } from '../mixins/common'
import sessionli from '../components/sessionli.vue';

import { mapState} from 'vuex'
export default {
    props:['name','id','createdAt','hr','exp'],
    mixins: [commonmixin],
    components:{
      sessionli
    },
  data() {
    return {
      localname:'',
      localexp: false,
      //session abajo
      iAmLoading: false,
      showPreloader: true,
      lastnotikeyCargada:null,
      laultima:null,
      contentYaRendereado:{},
      postsbyload: 20,
      contentToList:[],
      firstloadDone: false,
      miembros:{}
    }
  },
  computed:{
    ...mapState(['user','dev']),
    imMember(){
      let privxs= this.user.privx || {};
      let teams = privxs.teams || {};
      return teams[this.id];
    },
    iPeterUsers(){
      return (this.user.privx && this.user.privx.admin_users)
    },
    iPeterExports(){
      return (this.user.privx && this.user.privx.admin_export)
    },
    iAdminTeam(){
      return (this.imMember && this.imMember=='admin');
    },
    iManage(){
      return (this.iAdminTeam || this.iPeterUsers )
    }
  },
  created(){
    console.log(this.name, this.id, this.createdAt, this.hr)
    this.localname = this.name
    this.localexp = this.exp
  },
  methods:{
        cambiaexport(neoavalr){
      console.log('cambiadno expor a ',neoavalr);
      let tosave=neoavalr || null;
      this.$f7.dialog.preloader('Saving...');
      return this.$firebase.database().ref('teams/'+this.id+'/exp').set(tosave).then(()=>{
         this.$f7.dialog.close();
          let prevtema= Object.assign({},this.$store.getters.oneteam(this.id));
          let interteams = {};
          prevtema.exp = tosave;
          this.localexp = tosave;
        interteams[this.id] = prevtema;
        this.$store.commit('mergeObjTo',{what:'teams',to: interteams});
      }).catch(error=>{
        this.$f7.dialog.close();
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })
    },
    exitTeam(){
      return this.$f7.dialog.confirm(this.$t('vides.conflev'),this.$t('observation.confi'),()=>{
        console.log('Acepto!');
       this.$f7.dialog.preloader(this.$t('vides.laving'));
        let leaveteam = this.$firebase.functions().httpsCallable('leaveteam');
      return leaveteam({teamid: this.id, dev: this.dev}).then(snw=>{
        let datax=snw.data;
        console.log('llego',snw,datax);
        if(datax.error){
          throw new Error(datax.error)
        }
        //salir del equipo datax
        this.$store.commit('delTeamfromPriv',datax.teamid)
        this.$f7.dialog.close();
        this.$f7.views.main.router.back();
      }).catch(error=>{
        this.$f7.dialog.close();
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })
      });
    },
    editname(){
      if(this.iManage){
        this.$f7.dialog.prompt(this.$t('vides.neonamd'),this.$t('vides.neonam'), (name)=> {
        console.log('el equipo es ',name)
        let elnombre = name || '';
        let sinespacios = name.trim();
        if(!sinespacios){
          return this.$f7.dialog.alert('You must provide a name','Empty field');
        }
        //editnameteam
        this.$f7.dialog.preloader('Saving...');
         let editnameteam = this.$firebase.functions().httpsCallable('editnameteam');
          return editnameteam({teamname: sinespacios, teamid: this.id, dev: this.dev}).then(snw=>{
            let datax=snw.data;
            if(datax.error){
              throw new Error(datax.error)
            }
            console.log('datax',datax);
            let interteams = {};
            interteams[datax.id] = datax;
            this.$store.commit('mergeObjTo',{what:'teams',to: interteams});
             this.localname = sinespacios;
            this.$f7.dialog.close();
          }).catch(error=>{
            this.$f7.dialog.close();
            console.log(error);
            this.$f7.dialog.alert(error.message,error.code);
          });
      },()=>{
        console.log('nada')
      },this.localname);
      }
    },
    preGetMembers(){
      return new Promise((resolve,reject)=>{
          let getMembers = this.$firebase.functions().httpsCallable('getMembers');
          return getMembers({teamid: this.id, dic: true, dev: this.dev}).then(snw=>{
            let datax=snw.data;
            if(datax.error){
              throw new Error(datax.error)
            }
            console.log(datax)
            resolve(datax.dic);
          }).catch(error=>{
           reject(error);
          })
      });
    },
    shouldIcall(){
      if(!this.contentToList.length){
        this.$f7.dialog.preloader(this.$t('login.loading'))
        return this.preGetMembers().then((members)=>{
          this.miembros = members;
          console.log('TENGO MIEMBROS')
          this.$f7.dialog.close();
          this.llamandoInfinito();
        }).catch(error=>{
          this.$f7.dialog.close();
          console.log(error);
          this.$f7.dialog.alert(error.message,error.code);
        })
      }
    },
     llamandoPullreferh(done){
          console.log('ptr iniciando');
          if(this.laultima){
              //ir a buscar a ver si hay mas
              this.llamarConRangos({checkNuevas:true,done:done});
          }
          else{
              //no hay , hacer esfuerzo por traer
              this.startretreivalagain({done:done});
          }
        },
        llamandoInfinito(){
            console.log('llegando al infinito');
           // Exit, if iAmLoading in progress
           if (this.iAmLoading) return;
           console.log('no estaba cargando, puedo seguir');
           // Set iAmLoading flag
           this.iAmLoading = true;
           this.llamarConRangos({});
       },
       startretreivalagain(payload={}){
        console.log('Iniciando primer retreival');
        this.iAmLoading = true;
        this.firstloadDone = false;
        this.lastnotikeyCargada=null;
        this.laultima=null;
        this.contentYaRendereado={};
        this.contentToList=[];
       this.llamarConRangos(payload);
    },
    llamarConRangos(payload){
         let getsessions = this.$firebase.functions().httpsCallable('getsessions');
        return  getsessions({ dev: this.$store.state.dev, teamid: this.id, lastnotikeyCargada:this.lastnotikeyCargada,checkNuevas:payload.checkNuevas,laultima:this.laultima,postsbyload:this.postsbyload }).then((result)=> {
          let respoesta=result.data;
          console.log('res',respoesta);
          if(respoesta.error){
            throw new Error(respoesta.error)
          }
        this.lastnotikeyCargada = respoesta.lastnotikeyCargada
          let preinnerArray = respoesta.innerArray || [];
          let ultimaDelListado = respoesta.ultimaDelListado;
          let innerArray = [];
          if(preinnerArray.length){
            preinnerArray.forEach((elemntoToLoad) => {
              if(!this.contentYaRendereado[elemntoToLoad.id]){
                this.contentYaRendereado[elemntoToLoad.id]=true;
                innerArray.push(elemntoToLoad);
              }
            });
            }
            //Si al terminar el loop, no tengo defindia ULTIMA, es por que es la primera llamada. Guardar la ultima
            if(!this.laultima && ultimaDelListado){
              this.laultima=ultimaDelListado.id;
            }
            //al terminar el loop, mezclar el arreglo interno, con el arreglo padre
            if(payload.checkNuevas){
                this.contentToList=innerArray.concat(this.contentToList);
                this.iAmLoading = false;
            }
            else{
                this.contentToList=this.contentToList.concat(innerArray);
                 if(respoesta.numchildren<this.postsbyload){
                    this.showPreloader = false;
                }
                else{
                    this.iAmLoading = false;
                }
            }
            //si llegaron menos de las que pedi, se acabaron, ensconder el preloader de infinite scroll y no apagar el freno de iAmLoading
            console.log('done loading',this.contentToList);
             if(payload.done){
               payload.done();
             }
             if(!this.firstloadDone && innerArray.length<this.postsbyload){
               this.firstloadDone = true;
               this.llamandoInfinito();
             }
             this.firstloadDone = true;
        });
    }
  }
}
</script>
<style>
.gaugeclass .gauge-value-text{
  font-size: 16px!important;
}
.teamseslist .item-title-row{
  height:20px!important;
}
.teamseslist .item-after img{
  width:40px;
  height: 40px;
  border-radius: 50%;
}
.teamseslist .item-title-row .item-after {
    position: relative;
    top: -11px;
}
.expenabl .item-header{
  color: green!important;
}
.expdisbl .item-header{
  color: red;
}
</style>