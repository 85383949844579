<template>
      <f7-page>
        <f7-navbar :title="$t('endSession.finish')">
        </f7-navbar>
        <f7-block class="margin-vertical">
          <p>{{$t('endSession.adjust')}}</p>
        </f7-block>
        <f7-list no-hairlines-md class="margin-bottom-half margin-top">
          <f7-list-input
            :label="$t('endSession.name')"
            type="text"
            :value="sessionname"
             @input="sessionname = $event.target.value"
            :placeholder="$t('endSession.sesName')"
            clear-button
          >
          </f7-list-input>
           <f7-list-input
            :label="$t('endSession.notes')"
            type="textarea"
            :laceholder="$t('endSession.sesNotes')"
             :value="sessionNotes"
             @input="sessionNotes = $event.target.value"
          >
          </f7-list-input>
        </f7-list>
        <template v-if="allowTimeChange">
          <f7-block class="no-margin-vertical">{{$t('endSession.seems')}}</f7-block>
          <f7-block-title class="display-flex justify-content-space-between">{{$t('endSession.end')}} <span>{{cuando()}}</span></f7-block-title>
          <f7-list simple-list>
          <f7-list-item>
          <f7-list-item-cell class="width-auto flex-shrink-0">
          <font-awesome-icon class="fa-lg" icon="history" />
          </f7-list-item-cell>
          <f7-list-item-cell class="flex-shrink-3">
          <f7-range
          :min="0"
          :max="maxDelta"
          :step="1"
          :value="deltaTime"
          color="primary"
          @range:change="onDeltaChange"
          ></f7-range>
          </f7-list-item-cell>
          </f7-list-item>
          </f7-list>
        </template>

        <f7-block>
          <f7-row>
            <f7-col width="100" class="margin-top"><f7-button large raised @click="cerrarSesion()" color="primary" fill>{{$t('endSession.save')}}</f7-button></f7-col>
            <f7-col width="100" class="margin-top"><f7-button large raised color="gray" @click="$emit('closeme')" >{{$t('endSession.back')}}</f7-button></f7-col>
            </f7-row>
        </f7-block>
        <f7-block class="text-align-center">
          <p>{{$t('updt.justprac')}}</p>
          <f7-button large outline raised color="red" @click="deleteMeSession()" >{{$t('updt.delse')}}</f7-button>
        </f7-block>
      </f7-page>
</template>
<script>
//endsession

export default {
  props:['session','lastspot','requestStop','captured'],
    data() {
      return {
          deltaTime: 200,
          maxDelta: 400,
          sessionname: '',
          sessionNotes: '',
          allowTimeChange: false,
          nowTimeforCalc: this.$moment().unix()
        }
    },
    watch:{
      lastspot(newlast){
        console.log('new last observation', newlast)
         this.canChangeTime()
      },
      requestStop(newlast){
        console.log('new opened', newlast)
         this.canChangeTime()
      }
    },
    mounted() {
      console.log(this.session)
      this.sessionname = `${this.$t('endSession.near')} ${this.session.formatted_address || (this.session.ginf?this.session.ginf.formatted_address:'')}`
      console.log('el last stop es',this.lastspot)
      this.canChangeTime()
    },
    methods:{
      deleteMeSession(){
       return this.$f7.dialog.confirm('This will delete any recorded data','Are you sure you want to delete the session?',()=>{
         this.realdeleteMeSession();
       });
      },
      realdeleteMeSession(){
        console.log('lets delete', this.captured);
        let deletions={};
        this.captured.forEach(onecaptrs => {
          let key=onecaptrs.k || onecaptrs.oki;
          if(key){
            deletions['lox/'+this.session.ruta+'/'+key] = null;
          }
        });
        this.$f7.dialog.preloader(this.$t('endSession.closing'));
        return this.$firebase.database().ref().update(deletions).then(()=>{
          return this.cerrarSesion(true);
        });
      },
      cuando(){
        let aret = this.maxDelta - this.deltaTime;
        if(aret<1){
          return this.$t('endSession.now');
        }
        else{
          return this.$moment.unix(this.nowTimeforCalc-(aret*60)).format('LLL')
        }
      },
      onDeltaChange(value) {
        this.deltaTime = value
      },
      canChangeTime(){
        this.nowTimeforCalc = this.$moment().unix()
        let fulldelta = this.nowTimeforCalc-this.lastspot;
        let deltaminuteado = Math.floor(fulldelta/60);
        this.deltaTime = deltaminuteado;
        this.maxDelta = deltaminuteado;
        console.log(fulldelta,(1*60*5))
        if((fulldelta) > (1*60*5) ){
          this.allowTimeChange = true;
        }
        else{
          this.allowTimeChange = false;
        }
      },
      cerrarSesion(nopreloader = false){
        let neounix = this.allowTimeChange?(this.nowTimeforCalc-((this.maxDelta - this.deltaTime)*60)):null;
        if(!nopreloader){
          this.$f7.dialog.preloader(this.$t('endSession.closing'));
        }
          let endSession = this.$firebase.functions().httpsCallable('endSession');
            return  endSession({sessionid: this.session.id, name:this.sessionname, notes: this.sessionNotes, neotime: neounix, dev: this.$store.state.dev }).then((result)=> {
          let respoesta=result.data;
          console.log('res',respoesta);
          if(respoesta.error){
            throw new Error(respoesta.error)
          }
          this.$firebase.analytics().logEvent('timing_complete', {
            name: 'session',
            value: respoesta.session.d
          });
        this.$store.commit('createOrUpdateSession',respoesta.session);
         this.$f7.dialog.close();
         this.$emit('closeme');
         this.$f7.views.main.router.back();
         if(respoesta.badge){
           this.$store.commit('setWhatTo',{what:'forceGetMyBadges',to: true});
           this.$f7.dialog.alert(this.$t('endSession.earned'),this.$t('endSession.congrats'),()=>{
             this.$store.commit('setWhatTo',{what:'activeTab', to: '/profile/'});
                this.$f7.views.main.router.navigate('/profile/');
           });
         }
        }).catch(error=>{
          console.log(error);
           this.$f7.dialog.close();
            this.$f7.dialog.alert(error.message,error.code);
        })
      }
  }
}
</script>
<style>

</style>