<template>
  <f7-page name="messager">
     <f7-navbar title="Messenger" subtitle="email your users" back-link="Back">
     </f7-navbar>
     <f7-list>
        <f7-list-item radio :checked="letarget === 'all'" @change="changingTarget($event.target.value)" value="all" name="target-users" title="Everybody"></f7-list-item>
        <f7-list-item radio :checked="letarget === 'users'" @change="changingTarget($event.target.value)" value="users" name="target-users" title="Select recipients"></f7-list-item>
     <f7-list-item v-if="loadingUsers" footer="Please wait to select recipients..." title="Loading users"><f7-preloader slot="after"></f7-preloader></f7-list-item>
     <f7-list-item v-if="!loadingUsers && letarget === 'users'" link="/admin/selectusers/" :title="(selectedRecipients.length+ ' Recipient'+(selectedRecipients.length==1?'':'s')+' selected')" ></f7-list-item>
     </f7-list>
     <f7-block-title>Compose your message</f7-block-title>
     <f7-list>
       <f7-list-input
        label="Subject"
        type="text"
        placeholder="Short subject here"
         :value="subjcetx"
        @input="subjcetx = $event.target.value"
        clear-button
      >
      </f7-list-input>
     </f7-list>
    <f7-text-editor
      placeholder="Message here..."
      :buttons="[
        ['bold', 'italic', 'underline', 'strikeThrough'],
        ['orderedList', 'unorderedList']
      ]"
      :value="tosend"
      @texteditor:change="writme"
    />
  <f7-block>
    <p v-if="letarget === 'users' && !selectedRecipients.length">Please select at least one recipient</p>
  <f7-button :class="{disabled: (letarget === 'users' && !selectedRecipients.length)}" fill large raised @click="sendmail()">Send</f7-button>
  </f7-block>
  </f7-page>
</template>
<script>

import { mapState} from 'vuex'

export default {
  data() {
    return {
      tosend:'',
      subjcetx:'',
      letarget:'all',
      loadingUsers: false
    }
  },
  computed:{
    ...mapState(['user','dev','items','selectedRecipients']),
  },
  methods:{
    changingTarget(neotarget){
      if(neotarget==='users' && !this.items.length){
        this.loadingUsers = true;
        this.letarget = neotarget;
        this.loadmyusers();
      }
      else{
        this.letarget = neotarget;
      }
    },
    writme(valors){
      this.tosend=valors;
    },
      sendmail(){
        console.log('sending mail',this.tosend);
        if(!this.subjcetx || !this.tosend){
          return this.$f7.dialog.alert('Subject and body are required','Missing data');
        }
        this.$f7.dialog.preloader('Sending...');
        let messageusers = this.$firebase.functions().httpsCallable('messageusers');
      return messageusers({emailsTargets: this.selectedRecipients, subject: this.subjcetx, message: this.tosend, dev: this.dev}).then(snw=>{
        let datax=snw.data;
        console.log('llego',snw,datax);
        if(datax.error){
          throw new Error(datax.error)
        }
        //salir del equipo datax
        this.subjcetx ='';
        this.tosend = '';
        this.$f7.dialog.close();
        this.$f7.dialog.alert('Email was sent Ok','Success');
      }).catch(error=>{
        this.$f7.dialog.close();
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })
    },
    loadmyusers(){
       this.loadingUsers = true;
       let myusrs = this.$firebase.functions().httpsCallable('myusrs');
      return myusrs({ dev: this.dev }).then((result)=> {
          let respoesta=result.data;
          console.log('res',respoesta);
          if(respoesta.error){
            throw new Error(respoesta.error)
          }
          this.$store.commit('setWhatTo',{what:'items', to: (respoesta || [])});
          this.loadingUsers = false
        }).catch(error=>{
          console.log(error);
          this.loadingUsers = false
            this.$f7.dialog.alert(error.message,error.code);
        })
   }
  }
}
</script>