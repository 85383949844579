<template>
  <f7-page  
      @page:beforein="shouldIcall()"
       name="myteams">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :title="$t('updt.myteams')" :subtitle="$t('updt.myteamssub')"></f7-nav-title>
         <f7-nav-right>
        <f7-block v-if="loadingTeams"><f7-preloader ></f7-preloader></f7-block>
        <f7-link v-else @click="shouldIcall(true)" icon-material="refresh"></f7-link>
        </f7-nav-right>
    </f7-navbar>
  <f7-list class="margin-top" inset>
    <f7-list-item :title="$t('updt.jon')" divider></f7-list-item>
     <f7-list-input
          class="spcecas"
          :label="$t('updt.kode')"
          type="text"
          :value="codetoredeem"
          @input="codetoredeem = $event.target.value"
          :placeholder="$t('updt.typhe')"
        >
  </f7-list-input>
        <f7-button @click="redeemCodex()"  :class="{disabled:rdmming}" fill large raised><f7-preloader v-if="rdmming"></f7-preloader> <span v-else>{{$t('updt.reddm')}} <font-awesome-icon icon="exchange-alt"/></span></f7-button>
  </f7-list>
  
  <f7-list>
    <f7-list-item :title="$t('updt.myteams')" divider></f7-list-item>
    <f7-list-item v-if="!myteams.length && !loadingTeams" :title="$t('updt.notmem')"></f7-list-item>
    <f7-list-item
          v-for="team in myteams"
          :key="team.id"
          link="#"
          @click="gototeam(team)"
          :title="team.name"
          :footer="privxmyteams[team.id]"
          :badge="team.exp?'PRO':null" 
          :badge-color="team.exp?'blue':null"
        >
        </f7-list-item>
  </f7-list>
  <f7-block class="text-align-center" v-if="numadmin>4">{{$t('neotem.needmo')}}<br/>{{$t('neotem.cntc')}}</f7-block>
  <template v-else>
      <f7-block-title>{{$t('neotem.wantx')}}</f7-block-title>
    <f7-block>
      <p>{{$t('neotem.whyteamsx')}}</p>
      <f7-button @click="createTeam()">{{$t('neotem.creat')}}</f7-button>
    </f7-block>
  </template>

  </f7-page>
</template>
<script>
import { mapState} from 'vuex'
export default {
  data() {
    return {
        myteams:[],
        loadingTeams: false,
        codetoredeem: '',
        rdmming: false,
      }
  },
  computed:{
    ...mapState(['user','teams','dev']),
    privxmyteams(){
      let myprivs=this.user.privx || {};
      return myprivs.teams || {}
    },
    numadmin(){
      let letms = Object.values(this.privxmyteams) || [];
      let cuants = letms.filter(tipo=>tipo=='admin');
      return cuants.length
    }
  },
  mounted(){
    console.log(this.privxmyteams)
  },
  methods:{
    createTeam(){
       this.$f7.dialog.prompt(this.$t('neotem.creaname'), (name)=> {
        let elnombre = name || '';
        let sinespacios = name.trim();
        if(!sinespacios){
          return this.$f7.dialog.alert(this.$t('neotem.mustname'));
        }
        console.log('create',sinespacios)
        this.gocreateTeam(sinespacios);
      });
    },
    gocreateTeam(teamname){
      console.log('create team');
      this.$f7.dialog.preloader(this.$t('neotem.creating'));
      let createam = this.$firebase.functions().httpsCallable('createam');
      return createam({teamname: teamname, dev: this.dev}).then(snw=>{
        let datax=snw.data;
        if(datax.error){
          throw new Error(datax.error)
        }
        console.log('datax',datax);
        let teamprivxs = {};
        teamprivxs[datax.id] = datax.tp;
        //add a myprivx tambien setTeamStatus
        this.$store.commit('setTeamStatus',teamprivxs);
        let interteams = {};
        interteams[datax.id] = datax;
        this.$store.commit('mergeObjTo',{what:'teams',to: interteams});
        this.$f7.dialog.close();
         this.myteams = this.$store.getters.myTeams;
      }).catch(error=>{
        this.$f7.dialog.close();
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })


    },
     gototeam(team){
     return this.$f7router.navigate('/admin/team/', {
        props: team
      })
    },
    redeemCodex(){
      if(!this.codetoredeem){
        return this.$f7.dialog.alert(this.$t('updt.noemp'), this.$t('updt.plscode'));
      }
      this.rdmming = true ;
        let jointeam = this.$firebase.functions().httpsCallable('jointeam');
      return jointeam({code: this.codetoredeem, dev: this.dev}).then(snw=>{
        let datax=snw.data;
        if(datax.error){
          throw new Error(datax.error)
        }
        let teamprivxs = {};
        teamprivxs[datax.id] = datax.tp;
        //add a myprivx tambien setTeamStatus
        this.$store.commit('setTeamStatus',teamprivxs);
        let interteams = {};
        interteams[datax.id] = datax;
        this.$store.commit('mergeObjTo',{what:'teams',to: interteams});
        this.rdmming = false;
        this.codetoredeem = '';
         this.myteams = this.$store.getters.myTeams;
         this.$f7.dialog.alert(this.$t('updt.newnow'), this.$t('updt.redsuc'));
      }).catch(error=>{
        this.rdmming = false;
        console.log(error);
        this.$f7.dialog.alert(error.message,error.code);
      })
    },
   shouldIcall(force){
      if(!this.myteams.length || force){
        this.readMyTeams(force);
      }
      else{
        this.myteams = this.$store.getters.myTeams;
      }
    },
    readMyTeams(force){
      if(force){
        this.$store.commit('setObjTo',{what:'teams',to: {}});
      }
     this.loadingTeams = true;
     let susprivs = this.user.privx || {};
     let teamprivs = susprivs.teams || {};
     let teasmids = Object.keys(teamprivs) || [];
     let promesexr = [];
     teasmids.forEach(unteamid => {
       if(!this.teams[unteamid] || force){
          promesexr.push( this.$firebase.database().ref('teams/'+unteamid).once('value') );
       }
     });
      return Promise.all(promesexr).then(nspsh=>{
        let interteams = {};
        nspsh.forEach(unsnap => {
          if(unsnap.exists()){
            let teaminfo = unsnap.val();
            console.log('teaminfo',teaminfo)
            interteams[teaminfo.id] = teaminfo
          }
        });
        if(force){
          this.$store.commit('setObjTo',{what:'teams',to: interteams});
        }
        else{
          this.$store.commit('mergeObjTo',{what:'teams',to: interteams});
        }
         this.loadingTeams = false
         this.myteams = this.$store.getters.myTeams;
      }).catch(error=>{
        console.log(error);
        this.loadingTeams = false
        this.$f7.dialog.alert(error.message,error.code);
      });
   }
  }
}
</script>
<style>
.spcecas input{
  text-transform: uppercase;
}
</style>