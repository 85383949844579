// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
import 'typeface-open-sans'
// Import App Component
import App from '../components/app.vue';

import * as firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

import { fireBaseConfigProd , devConfig} from '../config/firebase_config'
const firebaseConfig = process.env.NODE_ENV === 'production' ? fireBaseConfigProd : devConfig;
firebase.initializeApp(firebaseConfig);

const defaultAnalytics = firebase.analytics();
//firebase.functions().useFunctionsEmulator('http://localhost:5000');

Vue.prototype.$firebase = firebase;

import * as geofire from 'geofire';
Vue.prototype.$geofire =  geofire;

import moment from 'moment';
Vue.prototype.$moment=moment;
import i18n from "../i18n";

import { store } from '../store/store';

import { library } from '@fortawesome/fontawesome-svg-core'
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch()
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)


// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  i18n,
  // Register App Component
  components: {
    app: App
  },
});