<template>
  <f7-list-item 
  class="litemlink"
  @click="getSessionData(session)" 
  :link="!nodetails?'#':null" 
  :header="byx?($t('vides.by')+' '+byx):null"
  :badge="!byx?(!session.c?$t('common.live'):$t('common.completed')):null" 
  :badge-color="!session.c?'green':'primary'" 
  :footer="session.c?('n: '+(session.os || 0)+' '+$t('common.duration')+session.durax):($t('common.started')+$moment.unix(session.w).fromNow())" 
  :title="$moment.unix(session.w).format('MMM D YYYY, hh:mm A')" 
  :subtitle="session.name || `${$t('endSession.near')} ${session.formatted_address}`">
    <GChart
      type="PieChart"
      :data="[
        ['Type', 'Count'],
        ['Mask',    session.k.m ],
        ['Partial',      session.k.p],
        ['No Mask',  session.k.n]
      ]"
      v-if="session.k"
      :options="chartOptions"
      :enableInteractivity="false"
      slot="media"
      class="fixwidhtoh"
    />
       <span v-if="session.c" slot="text">
         <font-awesome-icon  class="text-color-red" :icon="['fas', 'head-side-cough']" />: {{toFixedNumber(session.stats.nom,1)}}% | 
        <font-awesome-icon  class="text-color-yellow" :icon="['fas', 'head-side-cough-slash']" />: {{toFixedNumber(session.stats.par,1)}}% | 
        <font-awesome-icon class="text-color-primary"  :icon="['fas', 'head-side-mask']" />: {{toFixedNumber(session.stats.p,1)}}%
        </span>
        <img  v-if="imgsrc" :src="imgsrc" slot="after" />

  </f7-list-item>
</template>
<script>
import { commonmixin } from '../mixins/common'
import { GChart } from 'vue-google-charts'
//       <vc-donut slot="media" :foreground="session.c?'#ffe4db':null" :size="70" unit="px" :thickness="25" :sections="getvaluex(session.k)" :total="(session.os || 100)"><h1 class="text-color-primary">{{(session.noper || session.per=='--')?'--':`${Math.round(session.per * 100)}%`}}</h1></vc-donut>
export default {
  props:['session','byx','imgsrc','nodetails','teamid'],
    mixins: [commonmixin],
    components:{
      GChart
    },
    data(){
      return{
        chartData: [
        ['Type', 'Count'],
        ['Work',     11],
        ['Eat',      2],
        ['Commute',  2]
      ],
      chartOptions: {
        width: 80,
          height: 80,
          pieHole: 0.6,
          pieSliceText: 'none',
          legend: 'none',
          chartArea: {width: '100%', height: '100%'},
          colors: ['#3fafbd', '#ffcc00', '#ff3b30']
      }
      }
    },
    methods:{
    getSessionData(session){
      if(!this.nodetails){
        if(this.teamid){
          //quiere decir que viene de un team
        let getSession = this.$firebase.functions().httpsCallable('getSession');
        this.$f7.dialog.preloader(this.$t('login.loading'))
        return getSession({teamid: this.teamid, sessionid: session.id, dev: this.$store.state.dev}).then(snw=>{
            let datax=snw.data;
            if(datax.error){
              throw new Error(datax.error)
            }
            console.log('loxs',datax);
            this.$store.commit('setWhatTo',{what:'preloadedObservations', to: datax});
            let saver={};
            saver[session.id]=session;
            this.$store.commit('mergeObjTo',{what:'sessions',to:saver});
              //entrar de una
              this.$f7.dialog.close();
              this.$f7.views.main.router.navigate('/session/'+session.id);
        }).catch(error=>{
          this.$f7.dialog.close();
          console.log(error);
          this.$f7.dialog.alert(error.message, error.code);
        })
      }
      else{
      let fechastring =  session.ruta;// this.$moment.unix(session.w).format('YYYYMMDD');
        this.$f7.dialog.preloader('Loading data...')
        return this.$firebase.database().ref('lox/'+fechastring).orderByChild('z/o').equalTo(session.id).once('value').then(snpshot=>{
          let loxs = snpshot.val() || {};
          let arrayados = Object.values(loxs) || [];
            arrayados.sort((a, b)=> {
              return b.z.w - a.z.w;
            });
            this.$store.commit('setWhatTo',{what:'preloadedObservations', to: arrayados});
            let saver={};
            saver[session.id]=session;
            this.$store.commit('mergeObjTo',{what:'sessions',to:saver});
            //si la session aun no esta cerrada, pedir geoloc y grabar, antes de entrar
            if(!session.c){
              return navigator.geolocation.getCurrentPosition(
                (position)=>{
                    let pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                      wh: this.$moment().unix()
                    };
                    this.$store.commit('setWhatTo',{what:'lastPos', to: pos});
                    this.$f7.dialog.close();
                    this.$f7.views.main.router.navigate('/session/'+session.id);
                  }
                , (err)=>{
                  this.$f7.dialog.close();
                    console.warn('ERROR(' + err.code + '): ' + err.message);
                    this.$f7.dialog.alert(this.$t('common.geo'), err.message);
                  }
                ,{ maximumAge: 30000, timeout: 15000 });
            }
            else{
              //entrar de una
              this.$f7.dialog.close();
              this.$f7.views.main.router.navigate('/session/'+session.id);
            }
        }).catch(error=>{
          this.$f7.dialog.close();
          console.log(error);
          this.$f7.dialog.alert(error.message, error.code);
        })
      }
 
      }
    },
  }
}
</script>
<style>
.fixwidhtoh{
   width: 80px;
}

</style>