<template>
  <f7-page  infinite
      :infinite-distance="50"
      :infinite-preloader="showPreloader"
      @infinite="llamandoInfinito"
      ptr
      @ptr:refresh="llamandoPullreferh"
      @page:beforein="shouldIcall()"
       name="sessions">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :title="$t('common.observations')" :subtitle="$t('common.prevses')"></f7-nav-title>
         <f7-nav-right>
         <f7-link @click="startretreivalagain()" icon-material="refresh"></f7-link>
        </f7-nav-right>
    </f7-navbar>
<f7-list media-list class="no-margin-top">
  <ul>
  <sessionli v-for="session in contentToList"  :key="session.id" :session="session" ></sessionli>
  </ul>
</f7-list>
<f7-block v-if="firstloadDone && !contentToList.length">
 {{$t('common.any')}}
</f7-block>
  </f7-page>
</template>
<script>
import { commonmixin } from '../mixins/common'
import { mapState} from 'vuex'
import sessionli from '../components/sessionli.vue';


export default {
  mixins: [commonmixin],
  components:{
    sessionli
  },
  data() {
    return {
      iAmLoading: false,
      showPreloader: true,
      lastnotikeyCargada:null,
      laultima:null,
      contentYaRendereado:{},
      postsbyload: 20,
      contentToList:[],
      firstloadDone: false,
      }
  },
  computed:{
    ...mapState(['user','sessionChanged'])
  },
  watch:{
    sessionChanged(sessionid){
      if(sessionid){
        this.getnmodflist(sessionid);
      }
    }
  },
  created(){
    
  },
  methods:{
    percent(what,de){
      if(!what || !de){
        return 0;
      }
      return this.toFixedNumber(((what / de)*100),2);
    },
    coloreaMe(k){
      let tot=k.n + k.m + k.p;
      if(!k.n && !k.m && !k.p){
        return {p:null,c:null,e:true};
      }
      let ret={p: this.percent(k.m,tot),t:tot, par: this.percent(k.p,tot), nom: this.percent(k.n,tot) };
      return ret;
    },
    getnmodflist(sessionid){
      let sessioninfo = this.$store.getters.onesession(sessionid);
      if(sessioninfo.id){
        let duration = this.$moment.duration(this.$moment.unix(sessioninfo.c).diff(this.$moment.unix(sessioninfo.w)));
        sessioninfo.durax = this.printDuraxion(duration);
        if(!sessioninfo.k){
          sessioninfo.k={m:0,n:0,p:0};
        }
        else{
          sessioninfo.k = Object.assign({m:0,n:0,p:0},sessioninfo.k)
        }
        sessioninfo.stats= this.coloreaMe(sessioninfo.k);
        sessioninfo.per = (sessioninfo.k && sessioninfo.os)? this.toFixedNumber((sessioninfo.k.m / sessioninfo.os),2) : '--';
        if(!sessioninfo.c){
          sessioninfo.noper = true;
        }
        else{
          sessioninfo.noper = null;
        }
       let clonao =  this.contentToList.map(obj => obj.id==sessioninfo.id? sessioninfo : obj).filter(obj => !obj.empty && !obj.inconsistent);
        this.contentToList = clonao;
      }
    },
    shouldIcall(){
      if(!this.contentToList.length){
        this.llamandoInfinito();
      }
    },
    llamandoPullreferh(done){
          console.log('ptr iniciando');
          if(this.laultima){
              //ir a buscar a ver si hay mas
              this.llamarConRangos({checkNuevas:true,done:done});
          }
          else{
              //no hay , hacer esfuerzo por traer
              this.startretreivalagain({done:done});
          }
        },
        llamandoInfinito(){
            console.log('llegando al infinito');
           // Exit, if iAmLoading in progress
           if (this.iAmLoading) return;
           console.log('no estaba cargando, puedo seguir');
           // Set iAmLoading flag
           this.iAmLoading = true;
           this.llamarConRangos({});
       },
       startretreivalagain(payload={}){
        console.log('Iniciando primer retreival');
        this.iAmLoading = true;
        this.firstloadDone = false;
        this.lastnotikeyCargada=null;
        this.laultima=null;
        this.contentYaRendereado={};
        this.contentToList=[];
       this.llamarConRangos(payload);
    },
    llamarConRangos(payload){
        let getsessions = this.$firebase.functions().httpsCallable('getsessions');
        return  getsessions({ dev: this.$store.state.dev, lastnotikeyCargada:this.lastnotikeyCargada,checkNuevas:payload.checkNuevas,laultima:this.laultima,postsbyload:this.postsbyload }).then((result)=> {
          let respoesta=result.data;
          console.log('res',respoesta);
          if(respoesta.error){
            throw new Error(respoesta.error)
          }
          this.lastnotikeyCargada = respoesta.lastnotikeyCargada
          let preinnerArray = respoesta.innerArray || [];
          let ultimaDelListado = respoesta.ultimaDelListado;
          let innerArray = [];
          if(preinnerArray.length){
            preinnerArray.forEach((elemntoToLoad) => {
              if(!this.contentYaRendereado[elemntoToLoad.id]){
                this.contentYaRendereado[elemntoToLoad.id]=true;
                innerArray.push(elemntoToLoad);
              }
            });
            }
            //Si al terminar el loop, no tengo defindia ULTIMA, es por que es la primera llamada. Guardar la ultima
            if(!this.laultima && ultimaDelListado){
              this.laultima=ultimaDelListado.id;
            }
            //al terminar el loop, mezclar el arreglo interno, con el arreglo padre
            if(payload.checkNuevas){
                this.contentToList=innerArray.concat(this.contentToList);
                this.iAmLoading = false;
            }
            else{
                this.contentToList=this.contentToList.concat(innerArray);
                 if(respoesta.numchildren<this.postsbyload){
                    this.showPreloader = false;
                }
                else{
                    this.iAmLoading = false;
                }
            }
            //si llegaron menos de las que pedi, se acabaron, ensconder el preloader de infinite scroll y no apagar el freno de iAmLoading
            console.log('done loading',this.contentToList);
             if(payload.done){
               payload.done();
             }
             if(!this.firstloadDone && innerArray.length<this.postsbyload){
               this.firstloadDone = true;
               this.llamandoInfinito();
             }
             this.firstloadDone = true;
        });
    }
  }
}
</script>
<style>
.gaugeclass .gauge-value-text{
  font-size: 16px!important;
}
</style>