<template>
  <f7-page @page:beforein="preLetsGo()" name="users">
     <f7-navbar :title="$t('admin.users')+' ('+items.length+')'" :subtitle="$t('admin.usersusb')" back-link="Back">
       <f7-nav-right>
         <f7-link popover-open=".popsorterusers" v-if="items.length"><font-awesome-icon :icon="tipossort[sorterIndex].iko"/></f7-link>
         <f7-link v-if="!loadingUsers" @click="readMyUsers()"><font-awesome-icon icon="sync"/></f7-link>
         <f7-block v-else><f7-preloader></f7-preloader></f7-block>
       </f7-nav-right>
       <f7-subnavbar :inner="false">
        <f7-searchbar
          search-container=".virtual-list"
          search-item="li"
          :placeholder="$t('updt.srch')"
          search-in=".item-title"
          :disable-button="true"
        ></f7-searchbar>
      </f7-subnavbar>
     </f7-navbar>
   <f7-list class="searchbar-not-found no-margin-top">
      <f7-list-item :title="$t('admin.noRes')"></f7-list-item>
    </f7-list>
    <f7-list
      ref="myuserslist"
      class="searchbar-found no-margin-top userslistsx"
      media-list
      virtual-list
      :virtual-list-params="{ items, searchAll, renderExternal, height: 101}"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          :key="index"
          link="#"
          @click="gotouser(item)"
          :title="item.displayName || '--'"
          :footer="'Created on '+$moment(item.creationTime).format('LLL')"
          :header="item.lstSess?('Last session: '+$moment.unix(item.lstSess).format('LLL')):'No sessions'"
          :subtitle="item.email || '-@-'"
          :style="`top: ${vlData.topPosition}px`"
        >
        <img slot="media" :src="item.photoURL || '../static/img/avatar.jpg'" >
        </f7-list-item>
      </ul>
    </f7-list>
    <f7-block v-if="loadingUsers" class="text-align-center">
      <p>{{$t('admin.loadUsers')}}</p>
       <f7-preloader></f7-preloader>
    </f7-block>


 <f7-popover class="popsorterusers  popover-menu">
    <f7-list>
        <f7-list-item v-for="untiposort in tipossort" :key="untiposort.id" radio :value="untiposort.id" @change="cambiarselector($event.target.value)" name="sorter-typex" :checked="sorterIndex==untiposort.id" :title="$t(untiposort.trs)">
          <font-awesome-icon slot="after" :icon="untiposort.iko"/>
        </f7-list-item>
    </f7-list>
  </f7-popover>

  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
export default {
  
  data() {
    return {
      loadingUsers: false,
      sorterIndex: 'alphabeta',
      tipossort:{
        'alphabeta': {
          iko: 'sort-alpha-down',
          trs: 'nov.alphab',
          id: 'alphabeta'
          },
       'timestamp': {
          iko: 'sort-numeric-down',
          trs: 'nov.timstmp',
          id: 'timestamp'
          },
        'lastsess': {
          iko: 'sort-amount-down',
          trs: 'nov.lastses',
          id: 'lastsess'
          }
      },
      vlData: {
        items: [],
      },
    }
  },
  computed:{
    ...mapState(['user','dev','items']),

  },
   watch:{
    items(neousers){
      console.log('hay nuevos users',neousers);
      this.$refs.myuserslist.f7VirtualList.replaceAllItems(neousers);
    }
  },
  methods:{
    actualizarListado(){
      let lositems = [...this.items];
       if(this.sorterIndex=='alphabeta'){
         lositems.sort((a, b) => {
          if(a.displLower > b.displLower){
              return 1
          }
          else if(a.displLower < b.displLower){
              return -1
          }
          else{
            return 0
          }
        });
       }
      else if(this.sorterIndex=='timestamp'){
        lositems.sort((a, b) => {
            if(a.creationTimeUnix > b.creationTimeUnix){
                return -1
            }
            else if(a.creationTimeUnix < b.creationTimeUnix){
                return 1
            }
            else{
              return 0
            }
        });
      }
       else if(this.sorterIndex=='lastsess'){
        lositems.sort((a, b) => {
            if(a.lstSess > b.lstSess){
                return -1
            }
            else if(a.lstSess < b.lstSess){
                return 1
            }
            else{
              return 0
            }
        });
      }
      this.$store.commit('setWhatTo',{what:'items', to: (lositems || [])});
    },
    cambiarselector(acual){
      this.sorterIndex = acual;
      this.$f7.popover.close('.popsorterusers');
      this.actualizarListado();
    },
    gotouser(user){
     return this.$f7router.navigate('/admin/user/', {
        props: user
      })
    },
    searchAll(query, items) {
        const found = [];
        let elkery = query.toLowerCase();
        for (let i = 0; i < items.length; i += 1) {
          if (
             query.trim() === ''
            || items[i].displLower.indexOf(elkery) >= 0
            || (items[i].email && items[i].email.indexOf(elkery) >= 0)
            ) found.push(i);
        }
        return found;
      },
      renderExternal(vl, vlData) {
        this.vlData = vlData;
      },
    preLetsGo(){
      if(!this.items.length){
        this.readMyUsers();
      }
    },
   readMyUsers(){
     this.loadingUsers = true
       let myusrs = this.$firebase.functions().httpsCallable('myusrs');
          return myusrs({ dev: this.dev }).then((result)=> {
              let respoesta=result.data;
              if(respoesta.error){
                throw new Error(respoesta.error)
              }
              this.$store.commit('setWhatTo',{what:'items', to: (respoesta || [])});
              this.loadingUsers = false
            }).catch(error=>{
              console.log(error);
              this.loadingUsers = false
                this.$f7.dialog.alert(error.message,error.code);
            })
   }
  }
}
</script>
<style>
.userslistsx img{
  width: 70px;
  border-radius: 25%;
}
</style>