<template>
  <f7-page name="about">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :title="$t('common.about')" subtitle="maskcount.com"></f7-nav-title>
    </f7-navbar>


    <f7-row>
    <f7-col width="100" medium="35">
    <f7-block>{{$t('vides.whaboutx')}}</f7-block>
   <f7-block-title>{{$t('updt.fedbk')}}</f7-block-title>
    <f7-block>{{$t('updt.pls')}}<f7-button fill raised target="_blank" external href="mailto:support@maskcount.com?subject=feedback">support@maskcount.com</f7-button></f7-block>
    
    <f7-block-title>{{$t('updt.supx')}}</f7-block-title>
    <f7-block>{{$t('updt.lnldon')}}<f7-button fill raised target="_blank" external href="https://www.regenstrief.org/give/">regenstrief.org/give</f7-button></f7-block>

    <f7-block-title>{{$t('vides.akcno')}}</f7-block-title>
    <f7-block>
    <p v-html="$t('vides.porlx')"></p><p>{{$t('vides.coll')}} Brian Dixon, PhD; Umberto Tachinardi, MD, MS; Jennifer Williams, MS; Sara Minard, MA; Marcelo Lopetegui, MD, MS; Eneida Mendonca, MD, PhD; Todd Saxton, PhD; Kun Huang, PhD; Sarah Wiehe, MPH, MS; Shaun Grannis, MD, MS; Maurizio Mattoli.</p>
    <p>Software development by <f7-link external href="https://hicapps.cl" target="_blank">HICAPPS SpA</f7-link><br/>
      Copyright &copy; 2020, Regenstrief Institute, Inc.</p>
    </f7-block>

    </f7-col>
    <f7-col width="100" medium="65">
  <f7-block-title>{{$t('updt.privacypol')}}</f7-block-title>
           <f7-block class="scrollforze inset no-margin-vertical" v-html="$t('login.xagrxeementxp')">
              </f7-block>
<f7-block-title>{{$t('common.review')}}</f7-block-title>
 <f7-block class="scrollforze inset no-margin-vertical" v-html="$t('common.termsofusep')"></f7-block>


    </f7-col>
  </f7-row>
 
  
  </f7-page>
</template>
<script>

import { mapState} from 'vuex'
export default {
  data() {
    return {
    
    }
  },
  computed:{
    ...mapState(['user']),

  },
  created(){
   
  },
  methods:{
  
  }
}
</script>
<style>
.scrollforze{
  height: 200px;
  overflow-y: auto;
  font-size: 13px;
  padding-top: 10px;
  background: #FFF!important;
}
</style>