<template>
  <f7-page @page:beforein="getStartedFlow()" @page:beforeout="apagarGeoWatch()" name="session">
     <f7-navbar :title="!focusedSession.c?duraxion:$t('observation.titleended')" :subtitle="!focusedSession.c?`${$t('observation.current')}: ${currTime}`:`${$t('observation.from')} ${$moment.unix(focusedSession.w).format('hh:mm:ss A')} ${$t('observation.to')} ${$moment.unix(focusedSession.c).format('hh:mm:ss A')}`" :back-link="focusedSession.c?'Back':null" >
       <f7-nav-right>
         <template v-if="!focusedSession.c">
         <f7-block class="no-padding-left"><f7-button @click="reviewsheetOpened = true" outline color="purple" raised><span class="escondechico">{{$t('observation.edit')}} </span><font-awesome-icon class="fa-lg" icon="pen"/></f7-button></f7-block>
         <f7-block class="no-padding-left"><f7-button @click="confirmEndObservation()" outline color="purple" raised>{{$t('observation.en')}} <font-awesome-icon class="fa-lg" icon="flag-checkered" /></f7-button></f7-block>
         </template>
        <f7-block v-else-if="focusedSession.notes" class="no-padding-left"><f7-button popover-open=".popnotas" color="purple" ><font-awesome-icon class="fa-lg" icon="sticky-note"/></f7-button></f7-block>
       </f7-nav-right>
       <f7-subnavbar v-if="!focusedSession.c" class="sublistx" :inner="false">
          <f7-list class="no-margin" simple-list>
      <f7-list-item class="lasimplelist">
        <f7-list-item-cell class="width-auto flex-shrink-0" :class="{'text-color-purple':focusedSession.in, 'text-color-gray': !focusedSession.in }">
          <f7-link @click="cambiandwhere(0,true)" class="fixlink"><img src="../static/img/purple.png" class="icosample" /><font-awesome-icon class="fa-lg"  icon="house-user"/>
          <div>{{$t('updt.indoors')}}</div></f7-link>
        </f7-list-item-cell>
        <f7-list-item-cell class="flex-shrink-3">
          <f7-range
           class="neoknob"
            :min="0"
            :max="10"
            :step="10"
            :value="10"
            color="purple"
            id="texasranger"
            @range:changed="cambiandwhere"
          ></f7-range>
        </f7-list-item-cell>
        <f7-list-item-cell class="width-auto flex-shrink-0" :class="{'text-color-purple':!focusedSession.in, 'text-color-gray': focusedSession.in }">
          <f7-link @click="cambiandwhere(10,true)" class="fixlink"> <font-awesome-icon class="fa-lg"  icon="street-view"/><img src="../static/img/purple-dot.png" class="icosample" /><div>{{$t('updt.outdoors')}}</div></f7-link>
        </f7-list-item-cell>
      </f7-list-item>
    </f7-list>
    </f7-subnavbar>
     </f7-navbar>
     <f7-block v-if="!focusedSession.c" class="no-margin-vertical no-padding-vertical">
     <f7-row class="lepdaxsd">
      <f7-col class="martopseven" width="30">{{$t('vides.lstrc')}}:</f7-col>
      <f7-col width="70" class="text-align-right">
          <f7-chip v-if="captured[0]" deleteable @click="predeleteobs(captured[0])" :class="ickclasses[captured[0].m]" :text="captured[0].n+' - '+captured[0].hrt" >
                <img slot="media" :src="`https://maps.google.com/mapfiles/ms/icons/${captured[0].t?(colorespins[captured[0].m]):colorespinsdots[captured[0].m]}.png`"  v-if="captured[0].svd" />
                 <f7-preloader :color="f7colorstype[captured[0].m]" v-else slot="media"></f7-preloader>
            </f7-chip>
            <span v-else>{{$t('vides.noobs')}}</span>
        </f7-col>
    </f7-row>
     </f7-block>
  <f7-block class="todoelalto no-margin-vertical no-padding">
    <div class="maskmap">
        <f7-preloader></f7-preloader>
      </div>
      <div class="restosinmap">
         <f7-row class="session">
          <f7-chip class="womasktop" :text="kounter.n || '0'" >
          <font-awesome-icon slot="media"   :icon="['fas', 'head-side-cough']" />
          </f7-chip>
          <f7-chip v-if="ispartialon || (focusedSession.c && kounter.p)" class="wpartmasktop" :text="kounter.p || '0'" >
          <font-awesome-icon slot="media"   :icon="['fas', 'head-side-cough-slash']" />
          </f7-chip>
          <f7-chip class="wmasktop" :text="kounter.m || '0'" >
          <font-awesome-icon slot="media"   :icon="['fas', 'head-side-mask']" />
          </f7-chip>
      </f7-row>
       <f7-row :no-gap="true" v-if="!focusedSession.c">
         <f7-col class="padding-horizontal-half margin-top" :width="ispartialon?25:33">
           <f7-button class="multiplier" @click="abrekountkpicker()"  outline raised large>{{prekountermask}}<font-awesome-icon icon="caret-down"/></f7-button>
           </f7-col>
        <f7-col class="margin-top padding-horizontal-half nomask" :width="ispartialon?25:33">
          <f7-button @click="presave('n')" class="mskbut badgrad typbut" fill raised large >
           <font-awesome-icon class="fa-lg" :icon="['fas', 'head-side-cough']" />
          </f7-button>
        </f7-col>
         <f7-col v-if="ispartialon" class="margin-top padding-horizontal-half nomask" :width="ispartialon?25:33">
          <f7-button @click="presave('p')" class="mskbut partialgrad typbut" fill raised large >
          <font-awesome-icon class="fa-lg" :icon="['fas', 'head-side-cough-slash']" />
          </f7-button>
        </f7-col>
        <f7-col class="margin-top padding-horizontal-half mask" :width="ispartialon?25:33">
          <f7-button @click="presave('m')" class="mskbut goodgrad typbut" fill raised large >
           <font-awesome-icon class="fa-lg" :icon="['fas', 'head-side-mask']" />
          </f7-button>
        </f7-col>
      </f7-row>
      <f7-list v-if="focusedSession.c && captured.length" class="no-margin-vertical liknms limitlistbellow">
                 <f7-list-item v-for="obx in captured" :key="obx.k"  :footer="`${obx.pos.lat}, ${obx.pos.lng}`" :title="obx.hrt" >
                    <f7-chip slot="after" :class="ickclasses[obx.m]" :text="obx.n" >
                    <img slot="media" :src="`https://maps.google.com/mapfiles/ms/icons/${obx.t?(colorespins[obx.m]):colorespinsdots[obx.m]}.png`"  v-if="obx.svd" /> <f7-preloader :color="f7colorstype[obx.m]" v-else slot="media"></f7-preloader>
                    </f7-chip>
                   </f7-list-item>
              </f7-list>
              <div v-if="!focusedSession.c" class="kardwsite">
                <f7-card  class="elevation-24 " >
             <f7-list id="lacarderx" inset class="no-margin-vertical leswiper unsetwhitespc" media-list>
                <f7-list-item
                id="scroleammi"
                class="litindr"
                  swipeout
                  :subtitle="ispartialon?$t('lgn.swipe'):$t('observation.swipe')"
                  :text="ispartialon?$t('lgn.over'):$t('observation.over')"
                  @swipeout:open="abriendosiwpeout()"
                  @swipeout:closed="puedeverticalptr = true"
                >
                <f7-row :no-gap="true" slot="inner-start">
                  <f7-col class="text-align-left"><font-awesome-icon class="fa-lg text-color-red fa-flip-horizontal" icon="share-square" /><br/>{{$t('observation.noMask')}}</f7-col>
                  <f7-col v-if="ispartialon" class="text-align-center"><font-awesome-icon class="fa-lg text-color-yellow fa-rotate-90" icon="share-square" /><br/>{{$t('lgn.partial')}} </f7-col>
                  <f7-col class="text-align-right"><font-awesome-icon class="fa-lg text-color-primary" icon="share-square" /><br/>{{$t('observation.mask')}} </f7-col>
                </f7-row>
                <f7-preloader v-if="saving" :color="saving" slot="footer"></f7-preloader>
                  <f7-swipeout-actions right>
                    <f7-swipeout-button overswipe close color="red" @click="presave('n')">{{$t('observation.noMask')}}</f7-swipeout-button>
                  </f7-swipeout-actions>
                  <f7-swipeout-actions left>
                      <f7-swipeout-button overswipe close color="primary" @click="presave('m')">{{$t('observation.mask')}}</f7-swipeout-button>
                  </f7-swipeout-actions>
                </f7-list-item>
              </f7-list>
              </f7-card>
              </div>
      </div>
  </f7-block>
  <f7-popup class="endsessionpopup" :opened="finishObserPopup" @popup:open="requestStop = $moment().unix()" @popup:closed="finishObserPopup = false">
    <endsession v-if="loadFinishPopup" @closeme="finishObserPopup = false" :requestStop="requestStop" :lastspot="lastspot" :captured="captured" :session="this.focusedSession"></endsession>
  </f7-popup>

  <f7-sheet class="demo-sheet" :opened="relocateSheetOpened" @sheet:closed="relocateSheetOpened = false">
      <f7-toolbar>
        <div class="left">
           <f7-link color="red" sheet-close>{{$t('observation.cancel')}}</f7-link>
        </div>
        <div class="right">
          <f7-block>
          <f7-button @click="attemptUpdateLocation()" fill raised sheet-close>{{$t('observation.save')}}</f7-button>
          </f7-block>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <div class="relocatemap"></div>
      </f7-page-content>
    </f7-sheet>

     <f7-sheet class="review" :opened="reviewsheetOpened" @sheet:closed="reviewsheetOpened = false">
      <f7-toolbar>
        <div class="left"><f7-block>{{$t('login.swip')}}</f7-block></div>
        <div class="right">
          <f7-link sheet-close>{{$t('login.close')}}</f7-link>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
          <f7-list v-if="captured.length" class="no-margin-vertical liknms">
                 <f7-list-item v-for="obx in captured" :key="obx.k" :swipeout="!focusedSession.c" @swipeout:delete="deleteObs(obx)" :footer="`${obx.pos.lat}, ${obx.pos.lng}`" :title="obx.hrt" >
                    <f7-chip slot="after" :class="ickclasses[obx.m]" :text="obx.n" >
                    <img slot="media" :src="`https://maps.google.com/mapfiles/ms/icons/${obx.t?(colorespins[obx.m]):colorespinsdots[obx.m]}.png`"  v-if="obx.svd" /> <f7-preloader :color="f7colorstype[obx.m]" v-else slot="media"></f7-preloader>
                    </f7-chip>
                    <f7-swipeout-actions v-if="!focusedSession.c" left>
                      <f7-swipeout-button color="orange" close @click="editObs(obx)">{{$t('observation.edit')}}</f7-swipeout-button>
                    </f7-swipeout-actions>
                    <f7-swipeout-actions v-if="!focusedSession.c" right>
                       <f7-swipeout-button delete overswipe :confirm-text="$t('observation.sure')">{{$t('observation.delete')}}</f7-swipeout-button>
                    </f7-swipeout-actions>
                   </f7-list-item>
              </f7-list>
      </f7-page-content>
    </f7-sheet>

 <f7-popover v-if="focusedSession.c && focusedSession.notes" class="popnotas popover-menu">
    <f7-block>{{focusedSession.notes}}</f7-block>
  </f7-popover>

  </f7-page>
</template>
<script>
import loadGoogleMapsApi from "load-google-maps-api";
import localforage from 'localforage';
import { mapState} from 'vuex'
import endsession from '../components/endsession.vue';
import PullToRefresh from 'pulltorefreshjs';
import { commonmixin } from '../mixins/common'

export default {
   mixins: [commonmixin],
  props:['sessionid'],
  components:{
    endsession
  },
  data() {
    return {
      geowatcher: null,
      googleMaps: null,
      lemapa: null,
      stopCounter: false,
      duraxion:'00:00:00',
      currTime: this.$moment().format('hh:mm A'),
      lastPosLocal: {},
      captured:[],
      kounter:{
        n:0,
        m:0,
        p:0
      },
      contextToast: null,
      finishObserPopup: false,
      loadFinishPopup: false,
      myposMarker: null,
      lastspot: 0,
      timeouter: null,
      requestStop: 0,
      editDialogHolder: null,
      focusEdit: null,
      kountpickerMasks:null,
      prekountermask:1,
      relocateSheetOpened:false,
      relocatemap: null,
      relocatemarker: null,
      markers:{},
      reviewsheetOpened:false,
      animarkers:{},
      saving: null,
      leptr: null,
      puedeverticalptr: true,
      colorespins:{
        m:'lightblue',
        n: 'red',
        p: 'yellow'
      },
      colorespinsdots:{
        m:'ltblue-dot',
        n: 'red-dot',
        p: 'yellow-dot'
      },
      typeicons:{
        m:'head-side-mask',
        n:'head-side-cough',
        p:'head-side-cough-slash'
      },
      f7colorstype:{
        m:'teal', 
        n:'red',
        p:'yellow'
      },
      ickclasses:{
        m:'wmask', 
        n:'womask',
        p:'wpartmask'
      },
      translations:{
        m:'observation.mask',
        n:'observation.noMask',
        p:'lgn.partial'
      }
    }
  },
  computed:{
    ...mapState(['user','lastPos','preloadedObservations']),
    focusedSession(){
      return this.$store.getters.onesession(this.sessionid);
    },
  },
  created(){
    console.log('sessionid is '+this.sessionid,this.focusedSession);
     if(!this.focusedSession.c){
       //aun no esta concluida, encender timer
        this.lastPosLocal = Object.assign({},this.lastPos);
        setTimeout(() => this.getDuration(), 1000);
         setTimeout(() => this.repme(), 5000);
     }
     else{
       //calcular e imprimir duracion total
       let y =  this.$moment.unix(this.focusedSession.w)
        let duration = this.$moment.duration(this.$moment.unix(this.focusedSession.c).diff(y));
        this.printDuraxion(duration);
     }

     this.kountpickerMasks = this.$f7.picker.create({
           renderToolbar: ()=> {
            return '<div class="toolbar">' +
              '<div class="toolbar-inner">' +
                '<div class="left"><div class="text-color-white block padding-left-half">' +
                  this.$t('lgn.chs')+
                '</div></div>' +
                '<div class="right"><div class="block padding-right-half">' +
                  '<a href="#" class=" button charcoal button-fill color-white sheet-close popover-close">'+this.$t('observation.setj')+'</a>' +
                '</div></div>' +
              '</div>' +
            '</div>';
          },
          cols: [
            {
              textAlign: 'center',
              values: [...Array(99).keys()].map(i=>i + 1)
            }
          ],
          cssClass:'kountermasks',
          on: {
            change: (p,v)=> {
              this.prekountermask = parseInt(v);
            },
          }
        });
  },
  mounted(){
    if(!this.focusedSession.c && this.ispartialon){
      let outer = this;
        this.leptr = PullToRefresh.init({
          mainElement: '#lacarderx',
          triggerElement:'#lacarderx',
          instructionsPullToRefresh:'Partial Mask',
          instructionsReleaseToRefresh:'Partial Mask',
          instructionsRefreshing:'Partial Mask',
          refreshTimeout: 50,
          shouldPullToRefresh: ()=>{
            return this.puedeverticalptr
          },
          onRefresh() {
            console.log('refrescando la tarjeta');
            outer.presave('p');
          }
        });
      }

  },
  methods:{
    abriendosiwpeout(){
      this.puedeverticalptr = false;
      console.log('abirendo wipeout');
      
    },
    cambiandwhere(neovalor,forzar){
      if(forzar){
        this.$f7.range.setValue('#texasranger', neovalor);
      }
      else{
        console.log('cambiando valor a',neovalor);
        let estasession = Object.assign({},this.focusedSession);
        estasession.in = neovalor==10?null:true;
        this.$store.commit('createOrUpdateSession',estasession);
      }
        //toast para cambio de contexto
         this.contextToast = this.$f7.toast.show({
          text: this.$t('nov.cntxset')+' '+((this.focusedSession.in)?this.$t('updt.indoors'):this.$t('updt.outdoors')),
          closeTimeout: 2000,
        });
    },
    attemptUpdateLocation(){
      let neoposition = this.relocatemap.getCenter();
      let newlat = neoposition.lat();
      let newlng = neoposition.lng();
      console.log('atempting to change location',this.focusEdit,neoposition);
      //guardar en db
      let toupdate={
        g: this.encodeGeohash([newlat,newlng]),
        l: [newlat,newlng]
      };
       return this.$firebase.database().ref('lox/'+this.focusedSession.ruta+'/'+this.focusEdit.k).update(toupdate).then(()=>{
         console.log('updated succesfully')
         //actualizar en mapa
         //setear coordenadas //setPosition
        this.markers[this.focusEdit.k].setPosition(new google.maps.LatLng(newlat,newlng));
         //actualizar en listado
         //omitir por ahora, no parece necesario
       }).catch(error=>{
          console.log('error editting', error);
        });
    },
    abrekountkpicker(){
      this.kountpickerMasks.open();
    },
    predeleteobs(lobs){
      return this.$f7.dialog.confirm(this.$t('updt.undolat'),this.$t('updt.undo'),()=>{
        console.log('borrando');
        this.deleteObs(lobs)
      });
    },
    deleteObs(lobs){
      console.log('@swipeout:delete="deleteObs(lobs)',lobs);
       this.kounter[lobs.m]=this.kounter[lobs.m] - lobs.n;
        this.markers[lobs.k].setMap(null);
        this.markers[lobs.k] = null;
       let mainfilter = this.captured.filter((el)=> { return el.k != lobs.k; });
       this.captured = mainfilter;
      return this.$firebase.database().ref('lox/'+this.focusedSession.ruta+'/'+lobs.k).set(null).then(()=>{
        console.log('observation deleted');
      }).catch(error=>{
        console.log('error deletion', error);
      })
    },
    editObs(lobs){
      this.focusEdit = null;
      this.focusEdit = Object.assign({},lobs);
      console.log('attemptint to edit observatoin',lobs)
      if(!this.editDialogHolder){
       this.editDialogHolder = this.$f7.dialog.create({
          title: this.$t('observation.editObs'),
          text: this.$t('observation.what'),
          buttons: [
            {
              text:this.$t('observation.loc'),
              onClick:()=>{
                this.relocateSheetOpened = true;
                if(!this.relocatemap){
                  this.relocatemap= new this.googleMaps.Map(document.querySelector('.relocatemap'), {
                    center: this.focusEdit.pos,
                      zoom: 17,
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false
                    });

                  this.relocatemap.addListener('center_changed', ()=> {
                    console.log('cambio el centro del mapa de correccion');
                     this.relocatemarker.setPosition(this.relocatemap.getCenter());
                  });
                }
                else{
                  this.relocatemap.setCenter(this.focusEdit.pos);
                }
                let elikicon= `https://maps.google.com/mapfiles/ms/icons/${this.focusEdit.t?(this.colorespins[this.focusEdit.m]):this.colorespinsdots[this.focusEdit.m]}.png`;
                if(!this.relocatemarker){
                  this.relocatemarker = new google.maps.Marker({
                    position: this.focusEdit.pos,
                    map: this.relocatemap,
                    icon: elikicon
                  });
                }
                else{
                   this.relocatemarker.setIcon(elikicon);
                   this.relocatemarker.setPosition(this.focusEdit.pos);
                }
              }
            },
            {
              text:this.$t('observation.count'),
              onClick:()=>{
                this.$f7.dialog.prompt(this.$t('observation.correct'), (numb)=> {
                  let numero = parseInt(numb);
                  if(!numero || numero<1){
                    this.$f7.dialog.alert(this.$t('observation.value'));
                  }
                  else{
                    console.log('chanting to',numero);
                     return this.$firebase.database().ref('lox/'+this.focusedSession.ruta+'/'+this.focusEdit.k+'/z/n').set(numero).then(()=>{
                       //cambiar el count total
                      //descontar del previo
                      this.kounter[this.focusEdit.m]=this.kounter[this.focusEdit.m] - this.focusEdit.n;
                      //sumar al nuevo
                      this.kounter[this.focusEdit.m]=this.kounter[this.focusEdit.m] + numero;
                      //cambiar el weithg del mapa (ya no aplica)
                      //cambiar el N del item en la lista
                      let lindex = this.captured.findIndex(unobs => unobs.oki === this.focusEdit.k);
                      if(lindex>-1){
                        this.captured[lindex].n = numero;
                      }
                     }).catch(error=>{
                      console.log('error editting', error);
                    });
                  }
                },()=>{},this.focusEdit.n);
              }
            },
            {
              text: this.$t('observation.type'),
              onClick: ()=>{
                //preguntar solo si hay 3 opciones 3030
                if(this.ispartialon){
                  let opciones=[
                        {
                          text: this.$t(this.translations.m),
                          kclp: 'm',
                          onClick: ()=>{
                             this.cambiartipe(this.focusEdit,'m');
                          }
                        },
                        {
                          text: this.$t(this.translations.p),
                           kclp: 'p',
                          onClick: ()=>{
                            this.cambiartipe(this.focusEdit,'p');
                          }
                        },
                        {
                          text: this.$t(this.translations.n),
                          kclp: 'n',
                          onClick: ()=>{
                            this.cambiartipe(this.focusEdit,'n');
                          }
                        }
                      ];
                   let cuales = opciones.filter(unaop=>{
                     return unaop.kclp!==this.focusEdit.m
                   });
                   cuales.push({
                      text: this.$t('observation.cancel'),
                      color: 'red'
                    });
                    let stringa=`${this.$t('lgn.chg')} ${this.$t(this.translations[this.focusEdit.m])} ${this.$t('lgn.toc')}:`;

                    this.$f7.dialog.create({
                      title: this.$t('lgn.chging'),
                      text: stringa,
                      buttons: cuales,
                      verticalButtons: true,
                    }).open();
                }
                else{
                  let neo=(this.focusEdit.m=='m')?this.$t('observation.noMask'):this.$t('observation.mask');
                this.$f7.dialog.confirm(this.$t('observation.confirm')+neo,this.$t('observation.confi'), ()=> {
                 let save = (this.focusEdit.m=='m')?'n':'m';
                 console.log('cambiando el tipo a ',save);
                  this.cambiartipe(this.focusEdit,save);
                  });
                }
              }
            },
            {
              text: this.$t('lgn.ctxt'),
              onClick: ()=>{
                 let neo=(this.focusEdit.t)?this.$t('updt.outdoors'):this.$t('updt.indoors');
                this.$f7.dialog.confirm(this.$t('observation.confirm')+neo,this.$t('observation.confi'), ()=> {
                 let save = this.focusEdit.t?null:true;
                 console.log('cambiando el contexto a ',save);
                     return this.$firebase.database().ref('lox/'+this.focusedSession.ruta+'/'+this.focusEdit.k+'/z/t').set(save).then(()=>{
                      console.log('observation edited ok');
                        //cambiar en el mapa
                        let elicono= `https://maps.google.com/mapfiles/ms/icons/${save?(this.colorespins[this.focusEdit.m]):this.colorespinsdots[this.focusEdit.m]}.png`
                        this.markers[this.focusEdit.k].setIcon(elicono);
                        //cambiar en lista
                        let lindex = this.captured.findIndex(unobs => unobs.oki === this.focusEdit.k);
                        if(lindex>-1){
                          console.log('EDIANTO ESTE',this.captured[lindex])
                          this.captured[lindex].t = save;
                        }

                      }).catch(error=>{
                        console.log('error editting', error);
                    });
                });
              }
            },
            {
              text: this.$t('observation.cancel'),
              color: 'red'
            }
          ],
          verticalButtons: true,
        });
      }
     this.editDialogHolder.open();
    },
    cambiartipe(localfocedit,save){
        return this.$firebase.database().ref('lox/'+this.focusedSession.ruta+'/'+localfocedit.k+'/z/m').set(save).then(()=>{
          console.log('observation edited ok');
          //descontar del previo
            this.kounter[localfocedit.m]=this.kounter[localfocedit.m] - localfocedit.n;
          //sumar al nuevo
            this.kounter[save]=this.kounter[save] + localfocedit.n;
            //cambiar en el mapa 
            let elicono= `https://maps.google.com/mapfiles/ms/icons/${localfocedit.t?(this.colorespins[save]):this.colorespinsdots[save]}.png`
            console.log('cambiando este marker',this.markers[localfocedit.k])
            this.markers[localfocedit.k].setIcon(elicono);
            console.log('neuvo ikono este marker',this.markers[localfocedit.k])

            //cambiar en lista
            let lindex = this.captured.findIndex(unobs => unobs.oki === localfocedit.k);
            if(lindex>-1){
              this.captured[lindex].m = save;
            }

          }).catch(error=>{
            console.log('error editting', error);
        });
    },
    repme(){
      clearTimeout(this.timeouter);
      if(!this.focusedSession.c){
        this.$firebase.database().ref('livex/'+this.focusedSession.id).set({u:this.user.uid, w:this.$moment().unix()}).catch(error=>{
          console.log('error savign rep',error);
        })
         this.timeouter = setTimeout(() => this.repme(), 10000);
      }
    },
    cerrarSesion(){
        this.$f7.dialog.preloader(this.$t('observation.endSes'));
          let endSession = this.$firebase.functions().httpsCallable('endSession');
          return  endSession({sessionid: this.focusedSession.id, dev: this.$store.state.dev }).then((result)=> {
          let respoesta=result.data;
          console.log('res',respoesta);
          if(respoesta.error){
            throw new Error(respoesta.error)
          }
          this.$store.commit('createOrUpdateSession',respoesta.session);
          this.$firebase.analytics().logEvent('timing_complete', {
            name: 'session',
            value: respoesta.session.d
          });
         this.$f7.dialog.close();
         this.$f7.views.main.router.back();
        }).catch(error=>{
          console.log(error);
           this.$f7.dialog.close();
            this.$f7.dialog.alert(error.message,error.code);
        })
    },
    confirmEndObservation(){
      this.reviewsheetOpened = false;
      if(!this.captured.length){
       return this.$f7.dialog.confirm(this.$t('observation.withouth'),this.$t('observation.noObs'), ()=> {
            this.cerrarSesion();
        });
      }
      else{
        console.log('ending')
        this.lastspot = this.captured[0].w;
        this.loadFinishPopup = true;
        this.finishObserPopup = true;
      }
    },
    printDuraxion(duration){
        let horas = duration.hours()<10?`0${duration.hours()}`:duration.hours();
        let minutos = duration.minutes()<10?`0${duration.minutes()}`:duration.minutes();
        let segundos = duration.seconds()<10?`0${duration.seconds()}`:duration.seconds();
        this.duraxion = `${horas}:${minutos}:${segundos}`
    },
    getDuration(){
      if(!this.stopCounter){
        let y =  this.$moment.unix(this.focusedSession.w)
        let duration = this.$moment.duration(this.$moment().diff(y));
        this.printDuraxion(duration);
        this.currTime = this.$moment().format('hh:mm A'),
        setTimeout(() => this.getDuration(), 1000);
      }
    },
    apagarGeoWatch(){
      this.stopCounter = true;
      clearTimeout(this.timeouter);
      if(this.geowatcher){
        return navigator.geolocation.clearWatch(this.geowatcher);
      }
      if(this.kountpickerMasks){
        this.kountpickerMasks.destroy();
      }

    },
    getStartedFlow(){
      //primero crear el mapa y centrarme
      loadGoogleMapsApi({key:this.$store.state.apimaps,libraries:['geometry','visualization'],language:'en'}).then((googleMaps)=> {
           this.googleMaps=googleMaps;
          this.lemapa= new this.googleMaps.Map(document.querySelector('.maskmap'), {
             center: !this.focusedSession.c?{lat: this.lastPos.lat, lng: this.lastPos.lng}:this.focusedSession.location,
              zoom: !this.focusedSession.c?17:13,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false
            });
             if(!this.focusedSession.c){
               //si aun no esta concluida mostrar mi posicion actual y encender el geotracking
              this.myposMarker = new google.maps.Marker({
                position: {lat: this.lastPos.lat, lng: this.lastPos.lng},
                map: this.lemapa,
                icon: '../static/img/neome.png'
              });
                //iniciar tracking geo
                this.geowatcher=navigator.geolocation.watchPosition(this.geosuccess, this.geoerror, {enableHighAccuracy: true, timeout: 15000 });
                //preguntar por indoor/outdoor
                this.$f7.progressbar.hide();
                this.$f7.dialog.create({
                  title: this.$t('nov.spex'),
                  text: this.$t('nov.ychcng'),
                  buttons: [
                    {
                      text: this.$t('updt.indoors'),
                      onClick: ((dialog, e)=>{
                        this.cambiandwhere(0,true);
                      })
                    },
                    {
                      text: this.$t('updt.outdoors'),
                       onClick: ((dialog, e)=>{
                        this.cambiandwhere(10,true);
                      })
                    }
                  ],
                  verticalButtons: true,
                }).open();
             }
             else{
             this.$f7.progressbar.hide();
             }

            //si es que hay observaciones previas, agregar
            if(this.preloadedObservations.length){
              this.preloadedObservations.forEach(unlox => {
                let lobjtoarray = Object.assign({},unlox.z,{ pre: true, svd: true, oki: unlox.z.k, pos:{lat: unlox.l[0], lng:unlox.l[1] }, hrt: this.$moment.unix(unlox.z.w).format('hh:mm:ss A')});
                this.captured.push(lobjtoarray);
                this.printDataToMap({nm:unlox.z.m, t: unlox.z.t , llavex: unlox.z.k, pre: true, contador: unlox.z.n, lat: unlox.l[0],lng:unlox.l[1]});
              });
            }

          }).catch((error)=> {
           this.$f7.dialog.alert(error.message,error.code);
            console.log(error)
          });
    
      },
      geosuccess(position){
        let pos = { lat: position.coords.latitude, lng: position.coords.longitude , acc: position.coords.accuracy };
        this.lastPosLocal = Object.assign({},pos);
        console.log('nueva posicion',pos)
        this.lemapa.setCenter(pos);
        this.myposMarker.setOptions({
          position:pos
        });
        // this.myposMarker.setPosition(pos);
        // this.myposMarker.setZIndex(this.googleMaps.Marker.MAX_ZINDEX + 1);
      },
      geoerror(err){
        console.warn('ERROR(' + err.code + '): ' + err.message);
        this.$f7.views.main.router.back();
        return this.$f7.dialog.alert(error.message, error.code);
      },
    //validar de aqui a abajo 
    printDataToMap(pyld){
      this.kounter[pyld.nm]=this.kounter[pyld.nm] + pyld.contador;
      // let metax = pyld.nm=='m'?'ltblue':'red';
     
        if(!this.markers[pyld.llavex]){
          this.markers[pyld.llavex] = new this.googleMaps.Marker({
            position: new this.googleMaps.LatLng(pyld.lat, pyld.lng),
            map: this.lemapa,
            animation: pyld.ani?this.googleMaps.Animation.DROP:null,
            icon: `https://maps.google.com/mapfiles/ms/icons/${pyld.t?(this.colorespins[pyld.nm]):this.colorespinsdots[pyld.nm]}.png`
          });
          if(!pyld.pre){
            this.markers[pyld.llavex].addListener('animation_changed', ()=>{
              if(this.markers[pyld.llavex]){
                console.log('cambio en animacion')
                if(!this.animarkers[pyld.llavex]){
                  console.log('iniciando rebote')
                  this.animarkers[pyld.llavex]=2;;
                  this.markers[pyld.llavex].setAnimation(google.maps.Animation.BOUNCE);
                }
                else if(this.animarkers[pyld.llavex]==2){
                  this.animarkers[pyld.llavex]=3;
                  console.log('defininedo stop')
                  setTimeout(()=>{
                    if(this.markers[pyld.llavex]){
                      this.markers[pyld.llavex].setAnimation(null);
                    }
                    }, 2000);
                }
              }
            });
          }
        }
        else{
          this.markers[pyld.llavex].setVisible(true);
        }
    },
    encodeGeohash(location) {
        let precision = 10;
      // Characters used in location geohashes
      let BASE32 = '0123456789bcdefghjkmnpqrstuvwxyz';
      let latitudeRange = {
          min: -90,
          max: 90
      };
      let longitudeRange = {
          min: -180,
          max: 180
      };
      let hash = '';
      let hashVal = 0;
      let bits = 0;
      let even = 1;
      while (hash.length < precision) {
          var val = even ? location[1] : location[0];
          var range = even ? longitudeRange : latitudeRange;
          var mid = (range.min + range.max) / 2;
          if (val > mid) {
              hashVal = (hashVal << 1) + 1;
              range.min = mid;
          }
          else {
              hashVal = (hashVal << 1) + 0;
              range.max = mid;
          }
          even = !even;
          if (bits < 4) {
              bits++;
          }
          else {
              bits = 0;
              hash += BASE32[hashVal];
              hashVal = 0;
          }
      }
      return hash;
  },
    presave(nm){
      let contador = this.prekountermask;
      this.saving = this.f7colorstype[nm];
        this.kountpickerMasks.setValue([1]);
      console.log('grabando');
       let today=this.focusedSession.ruta;
      let llavex = this.$firebase.database().ref('lox/'+today).push().key;
      this.printDataToMap({llavex,nm,t:(this.focusedSession.in || null),contador,ani:true, lat: this.lastPosLocal.lat,lng:this.lastPosLocal.lng});
      let tosavex={
        u: this.user.uid,
        m: nm,
        n: contador,
        o: this.sessionid,
        k: llavex,
        w: this.$moment().unix(),
        t: this.focusedSession.in || null
      };
      let lobjtoarray = Object.assign({},tosavex,{svd: false, oki: llavex, pos:this.lastPosLocal, hrt: this.$moment().format('hh:mm:ss A')});
      console.log(lobjtoarray);
      this.captured.unshift(lobjtoarray);
      let geohashed = this.encodeGeohash([this.lastPosLocal.lat,this.lastPosLocal.lng]);
      console.log('el geohash',geohashed);
      let fullObjToSave = {
        g: geohashed,
        l: [this.lastPosLocal.lat,this.lastPosLocal.lng],
        a: this.lastPosLocal.acc,
        z: tosavex
      };
      return this.$firebase.database().ref('lox/'+today+'/'+llavex).set(fullObjToSave).then(()=>{
        console.log('meta saved');
        this.saving=null;
        let lindex = this.captured.findIndex(unobs => unobs.oki === llavex);
        if(lindex>-1){
          this.captured[lindex].svd = true;
        }
        this.repme()
      }).catch(error=>{
        console.log('error guardado', error);
      })
    }
  }
}
</script>
<style>
@media screen and (max-width: 390px){
  .escondechico{
    display: none;
  }
}
.martopseven{
margin-top: 7px;
white-space: nowrap;
}
.todoelalto{
  height: calc(100% - 52px);
}
.maskmap{
  height:30%;
  text-align: center;
  background: #e4e4e4;
}
.maskmap .preloader{
  margin-top: 20%;
}
.restosinmap{
  height:70%;
  margin:0px!important;
  padding:0px!important;
}
.session{
  height: 36px!important;
  background: #ffffff;
}
.session .duraclock{
  font-size: 16px;
  padding-top: 5px;
}
.sublistx {
  display: block;
}
.sublistx .item-content{
  height:48px!important;
}
.kardwsite{
  height: calc(100% - 120px);
  margin-top: 15px;
}
.kardwsite .card, .kardwsite .card .list, .kardwsite .card .list ul, .kardwsite .card .list ul li{
  height: 100%
}
.sublistx .item-media svg{
  margin: 0px auto;
}
.limitlistbellow{
  height: calc(100% - 36px);
  overflow-y: auto;
}
/** validar abajo */

.nogeodata{
  background: #ffe9e9;
  margin: 0px!important;
  padding: 20px;
  text-align: center;
  color: #610101ee;
}
.liknms .item-media svg{
  margin: 0 auto;
}


.chip.wmask .chip-media{
  color: #3fafbd;
  border: 1px solid #3fafbd;
  background: #FFF;
}
.chip.womask .chip-media{
  color: #ff4101;
  border: 1px solid #ff7901;
  background: #FFF;
}
.chip.wpartmask .chip-media{
  color: #e6bb00;
  border: 1px solid #cca700;
  background: #FFF;
}
.stepper-input-wrap input{
  background:#FFF;;
}
.badgrad {
  background: linear-gradient(270deg, #ff8c00ff, #ff7c00ff, #ff6d00ff, #ff5d00ff, #ff4e00ff, #ff3e00ff, #ff2f00ff, #ff1f00ff, #ff1000ff, #ff0000ff);
  /* background: linear-gradient(270deg, #8e6d6fff, #a95253ff, #c43637ff, #df1b1cff, #fa0000ff); */
}
.partialgrad{
  background: #e6bb00;
}
.goodgrad{
  /* background:  linear-gradient(270deg, #006affff, #007bffff, #0088ffff, #0099ffff, #00aaffff, #00b7ffff, #00c8ffff, #00d9ffff, #00e5ffff, #00f7ffff); */
  background: linear-gradient(270deg, #06f5f9ff, #21daddff, #3cbfc2ff, #57a3a6ff);
}


.kountermasks .toolbar{
  background: #293E51
}
.kountermasks .link.sheet-close.popover-close{
  color:#FFF!important;
}

.chip.womasktop .chip-media{
  color: #ff4101;
  background: #FFF;
}

.chip.wmasktop .chip-media{
  color: #3fafbd;
  background: #FFF;
}

.chip.wpartmasktop .chip-media{
   color: #e6bb00;
  background: #FFF;
}

.chip.wmasktop, .chip.wpartmasktop, .chip.womasktop{
  background: #FFF;
}

.session .chip-label{
  font-size: 20px!important;
  font-weight: bolder;
  color: #5a5a5a;
}


.multiplier{
  border-radius: 10px;
  display: inline-block!important;
  width: 100%;
  font-size: 19px;
  background: #FFF;
   border-color: #293E51;
  color: #293E51;
}

/* .nomask {
  padding-right: 0px!important;
  padding-left: 8px!important;
}
.mask{
  padding-left: 0px!important;
  padding-right: 8px!important;
} */
.typbut{
  display: inline-block!important;
  width: 100%;
  border-radius: 10px;
}

.relocatemap{
  background:#cecece;
  height: 100%;
}
.leswiper .item-subtitle{
  margin-top: 13%;
}
.leswiper .item-subtitle, .leswiper .item-text{
  text-align:center!important;
}
.leswiper .item-after{
  font-size: 16px;
  color:#212121;
}
.leswiper .item-footer{
  text-align: center;
}
.lepdaxsd{
  padding-bottom: 6px!important;
    padding-top: 6px!important;
}


.neoknob .range-knob-wrap{
  width: 20px!important;
  height: 20px!important;
}

.neoknob.range-slider-horizontal .range-knob-wrap {
    margin-top: -10px!important;
    margin-left: -10px!important;
}
.lasimplelist, .lasimplelist .fixlink{
  line-height: 20px!important;
  text-align: center!important;
  font-size: 14px!important;
}
.fixlink{
  display:block!important;
  padding: 0px!important;
  margin-top: -3pxpx;
  color: unset;
}
.subnavbar a.fixlink,.navbar a.fixlink {
   color: unset;
}
.ptr--ptr{
  background: #fbd50d!important;
}
.icosample{
  width: 23px;
    height: 23px;
    position: relative;
    top: 7px;
}
.text-color-gray .icosample{
  filter: grayscale(100%)!important;
  opacity: 0.3;
}
.unsetwhitespc .item-subtitle, .unsetwhitespc .item-text{
  white-space: unset!important;
  max-height: unset;
    text-overflow: unset;
    overflow: unset;
    -webkit-line-clamp: unset;
}

.text-color-gray .fixlink div {
  color: #cecece;
}
.button.color-white.charcoal{
   color: #293E51!important;
}
</style>