<template>
      <f7-page >
       <f7-navbar :title="loginattempt?$t('lgn.lgph'):$t('phoneVerifier.phone')">
          <f7-nav-right>
            <f7-link @click="$emit('abortlogin')" popup-close>{{$t('observation.cancel')}}</f7-link>
          </f7-nav-right>
       </f7-navbar>
       <f7-block>
         <f7-list class="centrico" no-hairlines-md>
          <f7-list-input
            :label="$t('phoneVerifier.mobile')"
            type="number"
            placeholder="16148877385"
             :value="phonnum"
            @input="phonnum = $event.target.value"
            clear-button
          >
            <font-awesome-icon icon="plus" slot="media" />
          </f7-list-input>
         </f7-list>
         <f7-block class="margin-vertical text-align-center">{{$t('phoneVerifier.should')}}</f7-block>
         <f7-button fill raised @click="goflow()">{{$t('phoneVerifier.verify')}}</f7-button>
       </f7-block>
       <f7-block  id="verifix" ></f7-block>
      </f7-page>
    
</template>
<script>
import { mapState } from 'vuex'
export default {
   props:['loginattempt'],
    data() {
      return {
       phonnum: '',
       confirmationResult: null
      }
    },
    computed:{
       ...mapState(['user']),
    },
    mounted() {
      console.log('montando phone verifier', this.user);
      this.$firebase.auth().useDeviceLanguage();
        setTimeout(this.drawcahtr, 400);
    },
    methods:{
      drawcahtr(){
        console.log('dibj')
              window.recaptchaVerifier = new this.$firebase.auth.RecaptchaVerifier('verifix', {
        'size': 'invisible',
        'callback': (response)=> {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // this.goflow();
          console.log('resolvio catpcha');
        }
        });
      },
      goflow(){
        this.$f7.dialog.preloader(this.$t('phoneVerifier.req'));
        let phoneNumber = '+'+this.phonnum.replace(/\D/g,'');
        console.log('this.phonnum',phoneNumber)
        let appVerifier = window.recaptchaVerifier;
        this.$firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier).then((confirmationResult)=> {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          this.$f7.dialog.close();
          this.confirmationResult = confirmationResult;
          this.$f7.dialog.prompt(this.$t('phoneVerifier.code'),this.$t('phoneVerifier.your'),(code)=>{
            this.verifycode(code);
          },()=>{
            this.$emit('endphoneflow');
          });
        }).catch((error)=> {
          this.$f7.dialog.close();
          // Error; SMS not sent
          console.log('error sms', error);
          this.$f7.dialog.alert(error.message, error.code);
        });
      },
      verifycode(code){
        if(this.loginattempt){
          this.$f7.dialog.preloader(this.$t('phoneVerifier.verifying'));
          this.confirmationResult.confirm(code).then((result)=> {
            this.$f7.dialog.close();
            this.$emit('endphoneflow');
            // User signed in successfully.
            var user = result.user;
            console.log('usser logged in via phone',user)
          }).catch( (error)=> {
            this.$f7.dialog.close();
            // User couldn't sign in (bad verification code?)
            // ...
            this.$f7.dialog.alert(error.message, error.code);
          });
        }
        else{
          this.$f7.dialog.preloader(this.$t('phoneVerifier.verifying'));
          let credential = this.$firebase.auth.PhoneAuthProvider.credential(this.confirmationResult.verificationId, code);
          this.$firebase.auth().currentUser.linkWithCredential(credential).then((usercred)=> {
            this.$f7.dialog.close();
            let user = usercred.user;
            console.log("Account linking success", user);
            this.$store.commit('setUserProp',{prop: 'providerData', val: user.providerData });
             this.$emit('endphoneflow');
          }).catch((error)=> {
            this.$f7.dialog.close();
            console.log("Account linking error", error);
            this.$f7.dialog.alert(error.message, error.code);
          });
        }
      }
  }
}
</script>
<style>
.centrico .item-media svg{
  margin: 0 auto;
}
.centrico .item-media{
  position: relative;
    top: -10px;
}
</style>