<template>
   <f7-login-screen :opened="loginOpened" id="my-login-screen" >
      <f7-view>
        <f7-page :class="`mylogin back${bg}`" login-screen>
          <f7-block class="text-align-center no-margin-vertical"><img class="masklogo" src="../static/img/maskpro.png"></f7-block>
          <f7-block v-if="dev" class="text-align-center no-margin-vertical"> <f7-badge color="red">Dev</f7-badge></f7-block>
          <f7-login-screen-title class="no-margin-vertical lgtitle"><span class="count"><span class="mask">Mask</span>Count <span class="version mask">{{$store.state.appVersion}}</span></span></f7-login-screen-title>
          <f7-block class="login-rwd no-margin-vertical text-align-center">{{$t('login.real')}}</f7-block>
          <f7-block class="login-bccly no-margin-vertical text-align-center">{{$t('login.by')}} <span class="bold">{{$t('login.like')}}</span>.</f7-block>
          <f7-block class="margin-top">
            <p class="text-align-center gray">{{loading?$t('login.loading'):(loginwithpass?$t('lgn.lgpasu'):$t('login.prexfferedx'))}}</p>
             <f7-row class="altox" v-if="loading">
              <f7-col><f7-button class="padtop" large ><f7-preloader ></f7-preloader></f7-button></f7-col>
            </f7-row>
            <template v-else>
               <template v-if="!loginwithpass">
                <f7-row >
                  <f7-col><f7-button class="padtop" @click="loginwith('facebook')" large color="blue" fill raised><f7-preloader color="white" v-if="cuallogin=='facebook'"></f7-preloader><font-awesome-icon v-else class="fa-2x" :icon="['fab', 'facebook-f']" /></f7-button></f7-col>
                  <f7-col><f7-button class="padtop" @click="loginwith('google')" large color="red" fill raised><f7-preloader color="white" v-if="cuallogin=='google'"></f7-preloader><font-awesome-icon v-else  class="fa-2x" :icon="['fab','google-plus-g']" /></f7-button></f7-col>
                  <f7-col><f7-button class="padtop" @click="loginwith('twitter')" large color="lightblue" fill raised><f7-preloader color="white" v-if="cuallogin=='twitter'"></f7-preloader><font-awesome-icon v-else  class="fa-2x" :icon="['fab','twitter']" /></f7-button></f7-col>
                  <f7-col><f7-button class="padtop" @click="loginwith('apple')" large color="black" fill raised><f7-preloader color="white" v-if="cuallogin=='apple'"></f7-preloader><font-awesome-icon v-else  class="fa-2x" :icon="['fab','apple']" /></f7-button></f7-col>
                  <f7-col><f7-button class="padtop" @click="loginwithpass = true" large color="gray" fill raised><f7-preloader color="white" v-if="cuallogin=='email'"></f7-preloader><font-awesome-icon v-else  class="fa-2x" :icon="['fas','at']" /></f7-button></f7-col>
                </f7-row>
                <p class="text-align-center gray no-margin-bottom">{{loading?$t('login.loading'):$t('lgn.psswlss')}}</p>
                <f7-row class="margin-top">
                  <f7-col><f7-button class="padtop" @click="loginwithlink()" large color="primary" fill raised><f7-preloader color="white" v-if="cuallogin=='email'"></f7-preloader><font-awesome-icon v-else  class="fa-2x" :icon="['fas','envelope']" /></f7-button></f7-col>
                  <f7-col><f7-button class="padtop" @click="loginwithphone()" large color="primary" fill raised><f7-preloader color="white" v-if="cuallogin=='phone'"></f7-preloader><font-awesome-icon v-else  class="fa-2x" :icon="['fas','mobile-alt']" /></f7-button></f7-col>
                </f7-row>
               </template>

        <template v-else>
          <f7-list media-list class="logininputs no-margin-top">
            <f7-list-input v-if="loginStep===1" :label="$t('lgn.entr')" :value="email"  @keyup.native.enter="avanzaLogin()" type="email" @input="email=$event.target.value">
               <font-awesome-icon icon="at" class="fa-2x confix" slot="media" />
            </f7-list-input>
             <template v-else-if="loginStep===2">
              <f7-list-item class="titlebold" :title="email" :header="$t('lgn.logas')">
                <f7-link   @click="loginStep=1" icon-material="arrow_back" slot="media"></f7-link>
              </f7-list-item>
              <f7-list-input :label="$t('lgn.enpass')"  @keyup.native.enter="ingresarConemailpass()" type="password" @input="password=$event.target.value">
                <div slot="media"></div>
              </f7-list-input>
            </template>
            <template v-else-if="loginStep===3">
              <f7-list-item class="titlebold" :title="email" :header="$t('lgn.crwh')">
                <f7-link   @click="loginStep=1" icon-material="arrow_back" slot="media"></f7-link>
              </f7-list-item>
              <f7-list-input :label="$t('lgn.cpasfor')"  @keyup.native.enter="creteAccount()" type="password" @input="password=$event.target.value">
                 <div slot="media"></div>
              </f7-list-input>
            </template>
          </f7-list>
           <f7-block>
             <f7-row>
               <f7-col class="forgotx">
                 <f7-button v-if="loginStep===1 && !veryfying" large @click="loginwithpass = false" color="black">{{$t('observation.cancel')}}</f7-button>
                 <f7-button @click="forgotMyPass()" large v-if="loginStep===2 && !loggingin">{{$t('lgn.gorfgt')}}</f7-button>
                 </f7-col>
              <f7-col>
                <f7-button v-if="loginStep===1" large  @click="avanzaLogin()" fill raised><f7-preloader color="white" v-if="veryfying"></f7-preloader><span v-else>{{$t('lgn.next')}}</span></f7-button>
                <f7-button v-else-if="loginStep===2" large @click="ingresarConemailpass()" fill raised><f7-preloader color="white" v-if="loggingin"></f7-preloader><span v-else>{{$t('lgn.lgn')}}</span></f7-button>
                <f7-button v-else-if="loginStep===3" large @click="creteAccount()" fill raised><f7-preloader color="white" v-if="creating"></f7-preloader><span v-else>{{$t('lgn.cretacc')}}</span></f7-button>
                </f7-col>
             </f7-row>
            </f7-block>
          </template>

            </template>
           
        
          </f7-block>
          <f7-list class="no-margin-bottom">
            <f7-block-footer>
            <f7-link @click="privacyPolicyOpened = true"><i class="fas fa-info-circle"></i></f7-link> maskcount.com &copy; 2020<br/><span class="pwdb">{{ $t('login.powdby') }} <f7-link class="bold" external href="https://www.regenstrief.org/" target="_blank">Regenstrief Institute</f7-link></span>
            </f7-block-footer>
            <img src="../static/img/loginback_2.jpg" class="hidemax"/>
            <img src="../static/img/loginback_3.jpg" class="hidemax"/>
          </f7-list>
          <f7-list class="no-margin-vertical">
   <f7-list-input
    type="select"
    defaultValue="EN"
    :placeholder="$t('profile.please')"
    :value="$i18n.locale"
    @input="setLocale($event.target.value)"
  >
   <font-awesome-icon  slot="media" class="fa-2x" icon="language" />
     <option :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{locale.name}}
      </option>
  </f7-list-input>
</f7-list>
        </f7-page>
         <!-- Popup -->
        <f7-popup id="my-popup" :opened="privacyPolicyOpened" @popup:closed="privacyPolicyOpened = false">
          <f7-view>
            <f7-page>
              <f7-navbar :title="$t('login.privacy')" :subtitle="$i18n.locale!='en'?'Automated translation. Read english version for accuracy.':null">
                <f7-nav-right>
                  <f7-link popup-close>{{$t('login.close')}}</f7-link>
                </f7-nav-right>
              </f7-navbar>
              <f7-block v-html="$t('login.xagrxeementxp')">
              </f7-block>
            </f7-page>
          </f7-view>
        </f7-popup>

        <f7-popup class="phoneverifier" :opened="phoneverifierOpened"  @popup:closed="phoneverifierOpened = false" >
      <phoneverifier v-if="phoneverifierOpened" @endphoneflow="phoneverifierOpened = false" @abortlogin="cuallogin = null" :loginattempt="true" ></phoneverifier>
    </f7-popup>
      </f7-view>
    </f7-login-screen>
</template>
<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales"
import localforage from 'localforage';
import phoneverifier from '../components/phoneverifier.vue';

export default {
    components: {phoneverifier},
  props:['loginOpened','loading','dev'],
    data() {
      return {
        locales: getSupportedLocales(),
        bg: 1,
        privacyPolicyOpened: false,
        cuallogin: null,
        phoneverifierOpened: false,
        loginStep:1,
        email:'',
        password:'',
        loginwithpass:false,
        veryfying: false,
        loggingin: false,
        creating: false
      }
    },
    watch:{
      loginOpened(){
         this.loginwithpass=false;
         this.veryfying=false;
         this.loggingin=false;
         this.creating=false;
      }
    },
    mounted() {
       setTimeout(() => this.animaback(), 4000);
    },
    methods:{
      loginwithphone(){
        this.cuallogin = 'phone'
        this.phoneverifierOpened = true;
      },
        creteAccount(){
          if(!this.email || !this.password){
          return this.$f7.dialog.alert('You must provide a password');
        }
        this.creating=true;
        this.$firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
        .catch((error)=> {
          if(error.code=='auth/email-already-in-use'){
            this.ingresarConemailpass();
          }
          else{
            this.creating=false;
            this.$f7.dialog.alert(error.message,error.code);
          }
        });
      },
      ingresarConemailpass(){
        if(!this.email || !this.password){
          return this.$f7.dialog.alert('You must provide a password');
        }
        this.loggingin=true;
        return this.$firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(()=>{
        }).catch((error)=> {
                this.loggingin=false;
                 this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
            });
      },
       forgotMyPass(){
          this.$f7.dialog.confirm(this.$t('lgn.passrelink')+this.email,  ()=> {
            this.$f7.dialog.preloader(this.$t('lgn.sendng'));
                this.$firebase.auth().sendPasswordResetEmail(this.email).then(()=> {
                  this.$f7.dialog.close();
                  this.$f7.dialog.alert(this.$t('lgn.ckfinbxp')+this.email);
                }).catch((error)=> {
                  this.$f7.dialog.close();
                  this.$f7.dialog.alert(error.message,error.code);
                  console.log(error)
                });
              });
      },
      emailIsValid (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      },
     avanzaLogin(){
        //chequear si este email existe
        this.email=this.email.trim();
        if(!this.email){
          return this.$f7.dialog.alert(this.$t('lgn.validem'));
        }
        if(!this.emailIsValid(this.email)){
          return this.$f7.dialog.alert(this.$t('lgn.invld'));
        }
        this.veryfying=true;
        this.checxifok();
      },
      checxifok(){
        let check = this.$firebase.functions().httpsCallable('check');
        check({email:this.email}).then((ladata)=> {
           this.veryfying=false;
          if(ladata.data){
            //usuario ya existia, hay que pedirle su password
            this.loginStep=2;
          }
          else{
            //usuario no existia, pedirle que cree su password
            this.loginStep=3;
          }
          //this.$localforage.setItem('prevUser', this.elusuario);
        }).catch((error)=>{
          console.log('error en fx',error);
          this.veryfying=false;
          this.$f7.dialog.alert(error.message,error.code);
          console.log(error)
        });
      },
      setLocale(lalocale){
        console.log('set new locale ',lalocale);
        this.$i18n.locale = lalocale;
        localforage.setItem('locale', lalocale);
      },
      animaback(){
        if(this.loginOpened){
           this.bg= this.bg==3 ? 1 : (this.bg+1);
           setTimeout(() => this.animaback(), 4000);
        }
      },
      loginwith(cualprovider){
        this.cuallogin = cualprovider
        this.$f7.dialog.preloader(this.$t('login.loading'));
        let provider
        if(cualprovider=='google'){
          provider = new this.$firebase.auth.GoogleAuthProvider();
           provider.setCustomParameters({
            prompt: 'select_account'
          });
        }
        else  if(cualprovider=='facebook'){
          provider = new this.$firebase.auth.FacebookAuthProvider();
        }
        else if(cualprovider=='twitter'){
          provider = new this.$firebase.auth.TwitterAuthProvider();
        }
        else if(cualprovider=='apple'){
          provider = new this.$firebase.auth.OAuthProvider('apple.com');
        }
        this.$firebase.auth().signInWithRedirect(provider);
      },
      loginwithlink(){
        this.$f7.dialog.prompt(this.$t('lgn.emsenlk'), (email)=> {
          if(!email){
            return this.$f7.dialog.alert(this.$t('lgn.validem'));
          }
            let actionCodeSettings = {
              url: 'https://'+location.hostname+'?lk=1',
              handleCodeInApp: true
            };
          this.$f7.dialog.preloader(this.$t('lgn.sendng'));
        return this.$firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings).then(()=> {

           localforage.setItem('emailForSignIn', email);
            this.$f7.dialog.close();
            this.$f7.dialog.alert(this.$t('lgn.ckfinbxp'));
          }).catch((error)=> {
            // Some error occurred, you can inspect the code: error.code
            this.$f7.dialog.close();
            console.log(error);
            this.$f7.dialog.alert(error.message, error.code);
          });
        });

 
      }
    }
}
</script>
<style>
.masklogo{
  width: 140px;
  border-radius: 30px;
}
.mylogin.page{
  background-repeat:no-repeat;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position:center;
  /* transition: background 0.5s linear; */
  transition: background-image 0.5s linear;

}
.mylogin.back1.page{
   background-image: url('../static/img/loginback_1.jpg');
}

.mylogin.back2.page{
   background-image: url('../static/img/loginback_2.jpg');
}

.mylogin.back3.page{
   background-image: url('../static/img/loginback_3.jpg');
}
.login-screen-content{
  background: unset;
}
.login-rwd{
  font-size: 18px;
}
.login-bccly{
  font-size: 16px;
}
.mylogin .pwdb{
  font-style: italic;
}
.mylogin .lgtitle{
  font-size: 36px;
}
.mylogin .mask{
  color: #1b727d;
}
.mylogin .count{
  color: #3FAFBC;
}
.mylogin .version{
  font-size:13px;
}
.hidemax{
  display: none!important;
}
.mylogin .padtop svg{
  margin-top: 9px;
}
.mylogin .gray{
  color: #696969;
}
.altox{
  height: 116px;
}
.logininputs input{
  padding-left: 8px!important;
    border-radius: 9px;
    border-color: #3FAFBC;
    border: 2px solid #3FAFBC;
}
.titlebold .item-title{
  font-weight: bold!important;
}
.logininputs .item-input-wrap input{
  background:#FFF;
}
.item-media .confix{
  margin: 0 auto!important;
}
.forgotx .button{
  font-size: 11px!important;
}
</style>